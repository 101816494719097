import { RouteLocationNormalized, RouteRecordRaw } from 'vue-router';

import { Breadcrumb } from 'src/types/breadcrumb';
import { EvaluationRoutesExplanationsFullscreen } from './EvaluationRoutes';
import { EvaluationRoutesForms } from 'src/router/EvaluationRoutes';
import { FollowUpRoutesExplanationsFullscreen } from './FollowUpRoutes';
import { FollowUpThreeMonthsMeta } from './../meta/follow-up/three-months';

const FollowUpThreeMonthsBreadcrumb: Breadcrumb = {
  label: FollowUpThreeMonthsMeta.title,
  icon: FollowUpThreeMonthsMeta.icon
};

export const FollowUpThreeMonthsRoutesForms: RouteRecordRaw = {
  path: '/',
  component: () => import('layouts/PacienteLayout.vue'),
  children: [
    ...(EvaluationRoutesForms.children?.map((routeRecord) => {
      const newProps = (route: RouteLocationNormalized) => ({
        // @ts-expect-error We know better than Typescript and know those routes will have the required properties
        ...routeRecord.props,
        pacientId: route.params.pacientId,
        flow: 'follow-up-3',
        breadcrumbs: [FollowUpThreeMonthsBreadcrumb],
        // @ts-expect-error We know better than Typescript and know those routes will have the required properties
        stateURL: FollowUpThreeMonthsMeta.key + routeRecord.props.stateURL
      });
      return {
        ...routeRecord,
        path: FollowUpThreeMonthsMeta.key + routeRecord.path,
        props: newProps
      } as RouteRecordRaw;
    }) ?? [])
  ],
  meta: {
    requiresAuth: true,
    requiresFollowUpCheck: true,
    followUpEventNumber: 1
  }
};

export const FollowUpThreeMonthsRoutesExplanationsFullscreen: RouteRecordRaw = {
  path: '/',
  component: () => import('src/layouts/LayoutFullScreen.vue'),
  children: [
    ...(EvaluationRoutesExplanationsFullscreen.children?.map((routeRecord) => {
      const newProps = (route: RouteLocationNormalized) => ({
        // @ts-expect-error We know better than Typescript and know those routes will have the required properties
        ...routeRecord.props,
        pacientId: route.params.pacientId,
        flow: 'follow-up-3',
        breadcrumbs: [FollowUpThreeMonthsBreadcrumb],
        // @ts-expect-error We know better than Typescript and know those routes will have the required properties
        stateURL: FollowUpThreeMonthsMeta.key + routeRecord.props.stateURL
      });
      return {
        ...routeRecord,
        path: FollowUpThreeMonthsMeta.key + routeRecord.path,
        props: newProps
      } as RouteRecordRaw;
    }) ?? []),
    ...(FollowUpRoutesExplanationsFullscreen.children?.map((routeRecord) => {
      const newProps = (route: RouteLocationNormalized) => ({
        // @ts-expect-error We know better than Typescript and know those routes will have the required properties
        ...routeRecord.props,
        pacientId: route.params.pacientId,
        flow: 'follow-up-3',
        breadcrumbs: [FollowUpThreeMonthsBreadcrumb],
        // @ts-expect-error We know better than Typescript and know those routes will have the required properties
        stateURL: FollowUpThreeMonthsMeta.key + routeRecord.props.stateURL
      });

      return {
        ...routeRecord,
        path: FollowUpThreeMonthsMeta.key + routeRecord.path,
        props: newProps
      } as RouteRecordRaw;
    }) ?? [])
  ],
  meta: {
    requiresAuth: true,
    requiresFollowUpCheck: true,
    followUpEventNumber: 1
  }
};

const routes: RouteRecordRaw[] = [
  FollowUpThreeMonthsRoutesForms,
  FollowUpThreeMonthsRoutesExplanationsFullscreen
];
export default routes;
