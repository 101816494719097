import { BasicRouteInfo } from './../meta/evaluation/EvaluationMeta';
export const RoutesMeta: Record<string, BasicRouteInfo> = {
  PROFILE_TERAPEUTA: {
    key: '/perfil-ter',
    icon: 'manage_accounts',
    index: 0.1,
    title: 'Perfil Terapeuta'
  },
  DASHBOARD: { key: 'dashboard', icon: '', index: 22, title: 'Dashboard' },
  RESULTS: { key: 'resultados', icon: '', index: 21, title: 'Resultados' },
  THREE_MONTHS: { key: 'tres-meses', icon: '', index: 23, title: 'Tres meses' },
  SIX_MONTHS: { key: 'seis-meses', icon: '', index: 24, title: 'Seis meses' },
  TWELVE_MONTHS: { key: 'doce-meses', icon: '', index: 25, title: 'Doce meses' }
};
