import { InitialEvaluationRoutes } from 'src/meta/initial-evaluation/InitialEvaluationMeta';
import { RelativeDatesOptions } from './InitialEvaluationMeta';
export const FIELDS = {
  title: { label: InitialEvaluationRoutes.DRUG_CONSUMPTION.title },
  'cual-considera-que-es-tu-principal-sustancia': {
    key: 'cual-considera-que-es-tu-principal-sustancia',
    label: '¿Cuál consideras que es tu principal sustancia de consumo?',
    subLabel: 'Solo puedes seleccionar uno.',
    options: {
      alcohol: {
        label: 'Alcohol',
        value: 'alcohol'
      },
      tabaco: {
        label: 'Tabaco',
        value: 'tabaco'
      },
      marihuana: {
        label: 'Marihuana',
        value: 'marihuana'
      },
      cocaína: {
        label: 'Cocaína',
        value: 'cocaína'
      },
      inhalables: {
        label: 'Inhalables',
        value: 'inhalables'
      },
      cristal: {
        label: 'Cristal',
        value: 'cristal'
      }
    },
    required: true
  },
  'cuales-sustancias-has-probado': {
    key: 'cuales-sustancias-has-probado',
    label:
      'Ahora, ¿Cuáles de estas sustancias has consumido por lo menos una vez en tu vida?',
    options: {
      alcohol: {
        label: 'Alcohol',
        value: 'alcohol'
      },
      tabaco: {
        label: 'Tabaco',
        value: 'tabaco'
      },
      marihuana: {
        label: 'Marihuana',
        value: 'marihuana'
      },
      cocaína: {
        label: 'Cocaína',
        value: 'cocaína'
      },
      inhalables: {
        label: 'Inhalables',
        value: 'inhalables'
      },
      cristal: {
        label: 'Cristal',
        value: 'cristal'
      }
    },
    required: true
  },
  'bebida-de-mayor-frecuencia': {
    key: 'bebida-de-mayor-frecuencia',
    label: '¿Qué tipo de bebida consumes con mayor frecuencia?',
    subLabel: 'Solo puedes seleccionar uno.',
    options: {
      pulque: {
        label: 'Pulque',
        value: 'pulque'
      },
      cerveza: {
        label: 'Cerveza',
        value: 'cerveza'
      },
      cooler: {
        label: 'Cooler',
        value: 'cooler'
      },
      vino: {
        label: 'Vino',
        value: 'vino'
      },
      marihuana: {
        label: 'Destilados',
        value: 'marihuana'
      }
    },
    required: true
  },
  'como-describirias-tu-consumo': {
    key: 'como-describirias-tu-consumo',
    label:
      'Durante el último año, ¿Cómo describirías tu consumo de alcohol o drogas?',
    options: {
      'No es problema': {
        label: 'No es problema',
        value: 'No es problema'
      },
      'Es un pequeño problema': {
        label: 'Es un pequeño problema',
        value: 'Es un pequeño problema'
      },
      'Es un problema menor': {
        label: 'Es un problema menor',
        value: 'Es un problema menor'
      },
      'Es un problema mayor': {
        label: 'Es un problema mayor',
        value: 'Es un problema mayor'
      },
      'Es un gran problema': {
        label: 'Es un gran problema',
        value: 'Es un gran problema'
      }
    },
    required: true
  },
  'has-recibido-algun-tratamiento': {
    key: 'has-recibido-algun-tratamiento',
    label:
      '¿Has recibido algún tratamiento o ayuda para resolver problemas de alcohol o drogas?',
    options: {
      si: {
        label: 'Sí',
        value: 'si'
      },
      no: {
        label: 'No',
        value: 'no'
      }
    },
    required: true
  },
  'tipo-tratamiento-que-recibiste': {
    key: 'tipo-tratamiento-que-recibiste',
    label: '¿Tipo de tratamiento o ayuda que recibiste?',
    options: {
      'Centro de desintoxicación': {
        label: 'Centro de desintoxicación',
        value: 'Centro de desintoxicación'
      },
      'Tratamiento de consultas externa': {
        label: 'Tratamiento de consultas externa',
        value: 'Tratamiento de consultas externa'
      },
      'Tratamiento de consultas interna': {
        label: 'Tratamiento de consultas interna',
        value: 'Tratamiento de consultas interna'
      },
      'Asesoría médica': {
        label: 'Asesoría médica',
        value: 'Asesoría médica'
      },
      'Asesoría psiquiátrica': {
        label: 'Asesoría psiquiátrica',
        value: 'Asesoría psiquiátrica'
      },
      'Grupos de auto-ayuda': {
        label: 'Grupos de auto-ayuda',
        value: 'Grupos de auto-ayuda'
      }
    },
    required: true
  },
  'fecha-del-tratamiento': {
    key: 'fecha-del-tratamiento',
    label: 'Fecha del tratamiento',
    options: RelativeDatesOptions,
    required: true
  },
  'problema-tratado': {
    key: 'problema-tratado',
    label: 'El problema tratado, ¿fue de alcohol o drogas?',
    options: {
      Alcohol: {
        label: 'Alcohol',
        value: 'Alcohol'
      },
      Drogas: {
        label: 'Drogas',
        value: 'Drogas'
      }
    },
    required: true
  },
  'mayor-periodo-de-abstinencia': {
    key: 'mayor-periodo-de-abstinencia',
    label:
      'Desde que el consumo de alcohol o drogas se ha convertido en un problema, ¿Cuál ha sido el mayor periodo de abstinencia?',
    subLabel:
      'La mayor cantidad de días que no has consumido, Escribe 0 si no ha ocurrido.',
    required: true
  },
  'fecha-del-periodo-de-abstinencia': {
    key: 'fecha-del-periodo-de-abstinencia',
    label: '¿Cuándo ocurrió este tiempo de abstinencia?',
    options: RelativeDatesOptions,
    required: true
  },
  'razon-periodo-de-abstinencia': {
    key: 'razon-periodo-de-abstinencia',
    label: '¿Por qué te abstuviste en esa ocasión?',
    required: true
  },
  fumas: {
    key: 'fumas',
    label: '¿Fumas?',
    options: {
      Nunca: {
        label: 'Nunca',
        value: 'Nunca'
      },
      Actualmente: {
        label: 'Actualmente',
        value: 'Actualmente'
      },
      'Exfumador (menos de un año)': {
        label: 'Exfumador (menos de un año)',
        value: 'Exfumador (menos de un año)'
      },
      'Exfumador (más de un año)': {
        label: 'Exfumador (más de un año)',
        value: 'Exfumador (más de un año)'
      }
    },
    required: true
  },
  'a-edad-de-iniciaste-a-fumar': {
    key: 'a-edad-de-iniciaste-a-fumar',
    label: '¿A qué edad iniciaste a fumar?',
    required: true
  },
  'fecha-desde-que-fumas': {
    key: 'fecha-desde-que-fumas',
    label: '¿Desde cuándo eres un fumador regular?',
    options: RelativeDatesOptions,
    required: true
  },
  'promedio-de-cigarros-diarios': {
    key: 'promedio-de-cigarros-diarios',
    label: '¿Cuál es el promedio de cigarros fumados diariamente?',
    required: true
  },
  'tus-conocidos-fuman': {
    key: 'tus-conocidos-fuman',
    label: '¿Tienes familiares o amigos que fumen?',
    options: {
      'Sí, pero sólo mis amigos': {
        label: 'Sí, pero sólo mis amigos',
        value: 'Sí, pero sólo mis amigos'
      },
      'Sí, pero sólo mi familia': {
        label: 'Sí, pero sólo mi familia',
        value: 'Sí, pero sólo mi familia'
      },
      'Sí, tanto mis amigos como mi familia': {
        label: 'Sí, tanto mis amigos como mi familia',
        value: 'Sí, tanto mis amigos como mi familia'
      },
      No: {
        label: 'No',
        value: 'No'
      }
    },
    required: true
  }
};
