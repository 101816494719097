import { ref, watch } from 'vue';

import { FIELDS } from 'src/meta/terapeuta-initial-registration/TerapeutaPersonalInfoMeta';
import { Icontext } from 'src/types/blitzar';
import { InstitucionViewKeyName } from 'src/types/Instituciones';
import { RegisterTerapeutaPersonalData } from 'src/types/initial-evaluation/register-personal-terapeuta';
import { useInstituciones } from 'src/composables/Sistema/UseInstituciones';

type ctx = Icontext<RegisterTerapeutaPersonalData>;
export const TerapeutaPersonalInfoFormSchema = async () => {
  const { institucionesKeyName } = await useInstituciones();
  const institucionesKeyNameFilter = ref<InstitucionViewKeyName[]>([]);
  watch(institucionesKeyName, () => {
    institucionesKeyNameFilter.value = institucionesKeyName.value;
  });
  return [
    {
      component: 'p',
      slot: 'Información Personal',
      componentClasses: 'text-weight-extrabold subtitle text-darkD subtitle'
    },
    {
      id: FIELDS.names.key,
      label: FIELDS.names.label,
      placeholder: FIELDS.names.placeholder,
      required: FIELDS.names.required,
      component: 'q-input',
      type: 'text'
    },
    {
      id: FIELDS.lastNames.key,
      label: FIELDS.lastNames.label,
      placeholder: FIELDS.lastNames.placeholder,
      required: FIELDS.lastNames.required,
      component: 'q-input',
      type: 'text'
    },
    {
      id: FIELDS['attachments.profile-pics@profile_picture'].key,
      label: FIELDS['attachments.profile-pics@profile_picture'].label,
      required: FIELDS['attachments.profile-pics@profile_picture'].required,
      component: 'profile-pic',
      events: {
        input: function input(value: File, context: ctx) {
          console.log(value);
          context.updateField({
            id: 'attachments.profile-pics@profile_picture',
            value
          });
        }
      },
      parseValue: (_val: File, context: ctx) => {
        if (
          context.formData.attachments_s3?.['profile-pics@profile_picture']?.url
        ) {
          const urlS3 = process.env.VUE_APP_S3_URL;
          const attData =
            context.formData.attachments_s3?.['profile-pics@profile_picture'];
          const urlProfilePic = attData.url;
          const { digest } = attData;
          return `${urlS3}${urlProfilePic}?digest=${digest}`;
        }
        return '/avatar.png';
      }
    },
    {
      id: FIELDS.institucion.key,
      label: FIELDS.institucion.label,
      placeholder: FIELDS.institucion.placeholder,
      required: FIELDS.institucion.required,
      dynamicProps: ['options'],
      component: 'q-select',
      options: () => institucionesKeyNameFilter.value,
      'option-label': 'value',
      filled: true,
      'use-input': true,
      'hide-selected': true,
      'fill-input': true,
      'input-debounce': '0',
      events: {
        filter: function filter(
          val: string,
          context: unknown,
          update: (callbackFn: () => void, afterFn?: () => void) => void
        ) {
          update(() => {
            const needle = val.toLocaleLowerCase();
            institucionesKeyNameFilter.value =
              institucionesKeyName.value.filter(
                (v) => v.value.toLocaleLowerCase().indexOf(needle) > -1
              );
          });
        }
      }
    },
    {
      id: FIELDS.puesto.key,
      label: FIELDS.puesto.label,
      placeholder: FIELDS.puesto.placeholder,
      required: FIELDS.puesto.required,
      component: 'q-input'
    },
    {
      id: FIELDS.codigoInvitacion.key,
      label: FIELDS.codigoInvitacion.label,
      required: FIELDS.codigoInvitacion.required,
      component: 'CodigoInvitacion',
      defaultValue: {}
    }
  ];
};
