import { FIELDS } from 'src/meta/initial-evaluation/SatisfaccionPersonalForm';
import { Icontext } from 'src/types/blitzar';
import { InitialEvaluationMetaTitleClasses } from 'src/meta/initial-evaluation/InitialEvaluationMeta';
import { SatisfaccionPersonalData } from 'src/types/initial-evaluation/evaluacion-personal';

type ctx = Icontext<Partial<SatisfaccionPersonalData>>;
export const SatisfaccionPersonalSchema = [
  {
    component: 'p',
    slot: FIELDS.title.label,
    componentClasses: InitialEvaluationMetaTitleClasses
  },
  {
    id: FIELDS[
      'que-tan-satisfecho-estas-con-tu-forma-estilo-de-vida-en-este-momento'
    ].key,
    label:
      FIELDS[
        'que-tan-satisfecho-estas-con-tu-forma-estilo-de-vida-en-este-momento'
      ].label,
    required:
      FIELDS[
        'que-tan-satisfecho-estas-con-tu-forma-estilo-de-vida-en-este-momento'
      ].required,
    options: Object.values(
      FIELDS[
        'que-tan-satisfecho-estas-con-tu-forma-estilo-de-vida-en-este-momento'
      ].options
    ),
    component: 'QOptionWrapper',
    type: 'radio'
  },
  {
    id: FIELDS['cuales-son-tus-metas-a-futuro'].key,
    label: FIELDS['cuales-son-tus-metas-a-futuro'].label,
    required: FIELDS['cuales-son-tus-metas-a-futuro'].required,
    component: 'q-input',
    type: 'text'
  }
];
