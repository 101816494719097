import { FIELDS } from 'src/meta/initial-evaluation/HistoriaDeDrogasMeta';
import { HistoriaDeDrogasData } from 'src/types/initial-evaluation/evaluacion-personal';
import { Icontext } from 'src/types/blitzar';
import { InitialEvaluationMetaTitleClasses } from 'src/meta/initial-evaluation/InitialEvaluationMeta';

type ctx = Icontext<Partial<HistoriaDeDrogasData>>;

export const HistoriaDeDrogasSchema = [
  {
    component: 'p',
    slot: FIELDS.title.label,
    componentClasses: InitialEvaluationMetaTitleClasses
  },
  {
    id: FIELDS['alguna-vez-has-probado-droga'].key,
    label: FIELDS['alguna-vez-has-probado-droga'].label,
    required: FIELDS['alguna-vez-has-probado-droga'].required,
    options: Object.values(FIELDS['alguna-vez-has-probado-droga'].options),
    component: 'QOptionWrapper',
    type: 'radio'
  },
  {
    id: FIELDS['has-probado-marihuana'].key,
    label: FIELDS['has-probado-marihuana'].label,
    options: Object.values(FIELDS['has-probado-marihuana'].options),
    component: 'QOptionWrapper',
    type: 'radio',
    dynamicProps: ['showCondition', 'required'],
    required: (val: string, { formData }: ctx) =>
      formData['alguna-vez-has-probado-droga'] === 'si',
    showCondition: (val: string, { formData }: ctx) =>
      formData['alguna-vez-has-probado-droga'] === 'si'
  },
  {
    id: FIELDS['has-probado-cocaína'].key,
    label: FIELDS['has-probado-cocaína'].label,
    options: Object.values(FIELDS['has-probado-cocaína'].options),
    component: 'QOptionWrapper',
    type: 'radio',

    dynamicProps: ['showCondition', 'required'],
    required: (val: string, { formData }: ctx) =>
      formData['alguna-vez-has-probado-droga'] === 'si',
    showCondition: (val: string, { formData }: ctx) =>
      formData['alguna-vez-has-probado-droga'] === 'si'
  },
  {
    id: FIELDS['has-probado-inhalables'].key,
    label: FIELDS['has-probado-inhalables'].label,
    subLabel: FIELDS['has-probado-inhalables'].subLabel,
    options: Object.values(FIELDS['has-probado-inhalables'].options),
    component: 'QOptionWrapper',
    type: 'radio',
    dynamicProps: ['showCondition', 'required'],
    required: (val: string, { formData }: ctx) =>
      formData['alguna-vez-has-probado-droga'] === 'si',
    showCondition: (val: string, { formData }: ctx) =>
      formData['alguna-vez-has-probado-droga'] === 'si'
  },
  {
    id: FIELDS['has-probado-anfetaminas'].key,
    label: FIELDS['has-probado-anfetaminas'].label,
    subLabel: FIELDS['has-probado-anfetaminas'].subLabel,
    options: Object.values(FIELDS['has-probado-anfetaminas'].options),
    component: 'QOptionWrapper',
    type: 'radio',
    dynamicProps: ['showCondition', 'required'],
    required: (val: string, { formData }: ctx) =>
      formData['alguna-vez-has-probado-droga'] === 'si',
    showCondition: (val: string, { formData }: ctx) =>
      formData['alguna-vez-has-probado-droga'] === 'si'
  },
  {
    id: FIELDS['has-probado-tranquilizantes'].key,
    label: FIELDS['has-probado-tranquilizantes'].label,
    options: Object.values(FIELDS['has-probado-tranquilizantes'].options),
    component: 'QOptionWrapper',
    type: 'radio',
    dynamicProps: ['showCondition', 'required'],
    required: (val: string, { formData }: ctx) =>
      formData['alguna-vez-has-probado-droga'] === 'si',
    showCondition: (val: string, { formData }: ctx) =>
      formData['alguna-vez-has-probado-droga'] === 'si'
  },
  {
    id: FIELDS['has-probado-heroína'].key,
    label: FIELDS['has-probado-heroína'].label,
    options: Object.values(FIELDS['has-probado-heroína'].options),
    component: 'QOptionWrapper',
    type: 'radio',
    dynamicProps: ['showCondition', 'required'],
    required: (val: string, { formData }: ctx) =>
      formData['alguna-vez-has-probado-droga'] === 'si',
    showCondition: (val: string, { formData }: ctx) =>
      formData['alguna-vez-has-probado-droga'] === 'si'
  },
  {
    id: FIELDS['que-edad-tenias-primera-vez-consumiste-alguna-estas-drogas']
      .key,
    label:
      FIELDS['que-edad-tenias-primera-vez-consumiste-alguna-estas-drogas']
        .label,
    component: 'q-input',
    type: 'number',
    min: 0,
    max: 150,
    dynamicProps: ['showCondition', 'required'],
    required: (val: number, { formData }: ctx) =>
      formData['alguna-vez-has-probado-droga'] === 'si',
    showCondition: (val: number, { formData }: ctx) =>
      formData['alguna-vez-has-probado-droga'] === 'si'
  },
  {
    id: FIELDS['cuantas-veces-en-tu-vida-has-probado-estas-drogas'].key,
    label: FIELDS['cuantas-veces-en-tu-vida-has-probado-estas-drogas'].label,
    options: Object.values(
      FIELDS['cuantas-veces-en-tu-vida-has-probado-estas-drogas'].options
    ),
    component: 'QOptionWrapper',
    type: 'radio',
    dynamicProps: ['showCondition', 'required'],
    required: (val: string, { formData }: ctx) =>
      formData['alguna-vez-has-probado-droga'] === 'si',
    showCondition: (val: string, { formData }: ctx) =>
      formData['alguna-vez-has-probado-droga'] === 'si'
  },
  {
    id: FIELDS['hace-cuanto-tiempo-probaste-por-primera-vez'].key,
    label: FIELDS['hace-cuanto-tiempo-probaste-por-primera-vez'].label,
    options: Object.values(
      FIELDS['hace-cuanto-tiempo-probaste-por-primera-vez'].options
    ),
    component: 'QOptionWrapper',
    type: 'radio',
    dynamicProps: ['showCondition', 'required'],
    required: (val: string, { formData }: ctx) =>
      formData['alguna-vez-has-probado-droga'] === 'si',
    showCondition: (val: string, { formData }: ctx) =>
      formData['alguna-vez-has-probado-droga'] === 'si'
  },
  {
    id: FIELDS['has-consumido-estos-ultimos-doce-meses'].key,
    label: FIELDS['has-consumido-estos-ultimos-doce-meses'].label,
    options: Object.values(
      FIELDS['has-consumido-estos-ultimos-doce-meses'].options
    ),

    component: 'QOptionWrapper',
    type: 'radio',
    dynamicProps: ['showCondition', 'required'],
    required: (val: string, { formData }: ctx) =>
      formData['alguna-vez-has-probado-droga'] === 'si',
    showCondition: (val: string, { formData }: ctx) =>
      formData['alguna-vez-has-probado-droga'] === 'si'
  },
  {
    id: FIELDS['has-consumido-el-ultimo-mes'].key,
    label: FIELDS['has-consumido-el-ultimo-mes'].label,

    options: Object.values(FIELDS['has-consumido-el-ultimo-mes'].options),

    component: 'QOptionWrapper',
    type: 'radio',
    dynamicProps: ['showCondition', 'required'],
    required: (val: string, { formData }: ctx) =>
      formData['has-consumido-estos-ultimos-doce-meses'] === 'si',
    showCondition: (val: string, { formData }: ctx) =>
      formData['has-consumido-estos-ultimos-doce-meses'] === 'si'
  },
  {
    id: FIELDS['has-intentado-dejar-o-consumir-menos'].key,
    label: FIELDS['has-intentado-dejar-o-consumir-menos'].label,
    options: Object.values(
      FIELDS['has-intentado-dejar-o-consumir-menos'].options
    ),
    component: 'QOptionWrapper',
    type: 'radio',

    dynamicProps: ['showCondition', 'required'],
    required: (val: string, { formData }: ctx) =>
      formData['alguna-vez-has-probado-droga'] === 'si',
    showCondition: (val: string, { formData }: ctx) =>
      formData['alguna-vez-has-probado-droga'] === 'si'
  },
  {
    id: FIELDS['fecha-de-cuando-intentaste'].key,
    label: FIELDS['fecha-de-cuando-intentaste'].label,
    options: FIELDS['fecha-de-cuando-intentaste'].options,
    component: 'SelectOrComponent',
    otherComponent: 'q-input',
    defaultValueForOther: '',
    type: 'date',
    dynamicProps: ['showCondition', 'required'],
    required: (val: string, { formData }: ctx) =>
      formData['has-intentado-dejar-o-consumir-menos'] === 'si',
    showCondition: (val: string, { formData }: ctx) =>
      formData['has-intentado-dejar-o-consumir-menos'] === 'si'
  },
  {
    id: FIELDS['porque-hiciste-el-intento'].key,
    label: FIELDS['porque-hiciste-el-intento'].label,
    component: 'q-input',
    dynamicProps: ['showCondition', 'required'],
    required: (val: string, { formData }: ctx) =>
      formData['has-intentado-dejar-o-consumir-menos'] === 'si',
    showCondition: (val: string, { formData }: ctx) =>
      formData['has-intentado-dejar-o-consumir-menos'] === 'si'
  },
  {
    id: FIELDS['todavia-estas-intentandolo'].key,
    label: FIELDS['todavia-estas-intentandolo'].label,
    options: Object.values(FIELDS['todavia-estas-intentandolo'].options),
    component: 'QOptionWrapper',
    type: 'radio',
    dynamicProps: ['showCondition', 'required'],
    required: (val: string, { formData }: ctx) =>
      formData['has-intentado-dejar-o-consumir-menos'] === 'si',
    showCondition: (val: string, { formData }: ctx) =>
      formData['has-intentado-dejar-o-consumir-menos'] === 'si'
  },
  {
    id: FIELDS['en-que-plazo-te-gustaria-intentar-consumir-menos'].key,
      label: FIELDS['en-que-plazo-te-gustaria-intentar-consumir-menos'].label,
      options: Object.values(
        FIELDS['en-que-plazo-te-gustaria-intentar-consumir-menos'].options
      ),
    component: 'QOptionWrapper',
    type: 'radio',
    dynamicProps: ['showCondition', 'required'],
    required: (val: string, { formData }: ctx) =>
      formData['alguna-vez-has-probado-droga'] === 'si',
    showCondition: (val: string, { formData }: ctx) =>
      formData['alguna-vez-has-probado-droga'] === 'si'
  },
  {
    id: FIELDS['que-tan-peligroso-crees-consumir-drogas-que-has-probado'].key,
      label:
        FIELDS['que-tan-peligroso-crees-consumir-drogas-que-has-probado'].label,
      options: Object.values(
        FIELDS['que-tan-peligroso-crees-consumir-drogas-que-has-probado']
          .options
      ),
    component: 'QOptionWrapper',
    type: 'radio',
    dynamicProps: ['showCondition', 'required'],
    required: (val: string, { formData }: ctx) =>
      formData['alguna-vez-has-probado-droga'] === 'si',
    showCondition: (val: string, { formData }: ctx) =>
      formData['alguna-vez-has-probado-droga'] === 'si'
  },
  {
    id: FIELDS['que-tan-peligroso-crees-que-consumir-alcohol-muy-seguido']
    .key,
  label:
    FIELDS['que-tan-peligroso-crees-que-consumir-alcohol-muy-seguido']
      .label,
  options: Object.values(
    FIELDS['que-tan-peligroso-crees-que-consumir-alcohol-muy-seguido']
      .options
  ),
    component: 'QOptionWrapper',
    type: 'radio',
    required: true
  },
  {
    id: FIELDS['que-opina-tu-mama'].key,
      label: FIELDS['que-opina-tu-mama'].label,
      options: Object.values(FIELDS['que-opina-tu-mama'].options),
    component: 'QOptionWrapper',
    type: 'radio',
    required: true
  },
  {
    id: FIELDS['que-opina-tu-papa'].key,
      label: FIELDS['que-opina-tu-papa'].label,
      required: FIELDS['que-opina-tu-papa'].required,
      options: Object.values(FIELDS['que-opina-tu-papa'].options),
    component: 'QOptionWrapper',
    type: 'radio',
  },
  {
    id: FIELDS['opinan-tus-maestros-o-patrones'].key,
      label: FIELDS['opinan-tus-maestros-o-patrones'].label,
      required: FIELDS['opinan-tus-maestros-o-patrones'].required,
      options: Object.values(FIELDS['opinan-tus-maestros-o-patrones'].options),
    component: 'QOptionWrapper',
    type: 'radio',
  },
  {
    id: FIELDS['opina-tu-mejor-amigo'].key,
      label: FIELDS['opina-tu-mejor-amigo'].label,
      required: FIELDS['opina-tu-mejor-amigo'].required,
      options: Object.values(FIELDS['opina-tu-mejor-amigo'].options),
    component: 'QOptionWrapper',
    type: 'radio',
  },
  {
    id: FIELDS['que-opinan-la-mayoria-de-jovenes'].key,
      label: FIELDS['que-opinan-la-mayoria-de-jovenes'].label,
      required: FIELDS['que-opinan-la-mayoria-de-jovenes'].required,
      options: Object.values(FIELDS['que-opinan-la-mayoria-de-jovenes'].options),
    component: 'QOptionWrapper',
    type: 'radio',
  },
  {id: FIELDS['ha-usado-alguna-droga-ilegal-papa'].key,
  label: FIELDS['ha-usado-alguna-droga-ilegal-papa'].label,
  required: FIELDS['ha-usado-alguna-droga-ilegal-papa'].required,
  options: Object.values(
    FIELDS['ha-usado-alguna-droga-ilegal-papa'].options
  ),
    component: 'QOptionWrapper',
    type: 'radio',
  },
  {
    id: FIELDS['ha-usado-alguna-droga-ilegal-mama'].key,
      label: FIELDS['ha-usado-alguna-droga-ilegal-mama'].label,
      required: FIELDS['ha-usado-alguna-droga-ilegal-mama'].required,
      options: Object.values(
        FIELDS['ha-usado-alguna-droga-ilegal-mama'].options
      ),
    component: 'QOptionWrapper',
    type: 'radio',
  },
  {
    id: FIELDS['ha-usado-alguna-droga-ilegal-hermanos'].key,
      label: FIELDS['ha-usado-alguna-droga-ilegal-hermanos'].label,
      required: FIELDS['ha-usado-alguna-droga-ilegal-hermanos'].required,
      options: Object.values(
        FIELDS['ha-usado-alguna-droga-ilegal-hermanos'].options
      ),

    component: 'QOptionWrapper',
    type: 'radio',
  },
  {
    id: FIELDS['ha-usado-alguna-droga-ilegal-amigo'].key,
      label: FIELDS['ha-usado-alguna-droga-ilegal-amigo'].label,
      required: FIELDS['ha-usado-alguna-droga-ilegal-amigo'].required,
      options: Object.values(
        FIELDS['ha-usado-alguna-droga-ilegal-amigo'].options
      ),
    component: 'QOptionWrapper',
    type: 'radio',
  },
  {
    id: FIELDS['ha-usado-alguna-droga-ilegal-familiar'].key,
      label: FIELDS['ha-usado-alguna-droga-ilegal-familiar'].label,
      required: FIELDS['ha-usado-alguna-droga-ilegal-familiar'].required,
      options: Object.values(
        FIELDS['ha-usado-alguna-droga-ilegal-familiar'].options
      ),
    component: 'QOptionWrapper',
    type: 'radio',
  },
  {
    id: FIELDS['ha-tenido-algun-problema-por-consumo-de-alcohol-papa'].key,
      label:
        FIELDS['ha-tenido-algun-problema-por-consumo-de-alcohol-papa'].label,
      required:
        FIELDS['ha-tenido-algun-problema-por-consumo-de-alcohol-papa'].required,
      options: Object.values(
        FIELDS['ha-tenido-algun-problema-por-consumo-de-alcohol-papa'].options
      ),
    component: 'QOptionWrapper',
    type: 'radio',
  },
  {
    id: FIELDS['ha-tenido-algun-problema-por-consumo-de-alcohol-mama'].key,
      label:
        FIELDS['ha-tenido-algun-problema-por-consumo-de-alcohol-mama'].label,
      required:
        FIELDS['ha-tenido-algun-problema-por-consumo-de-alcohol-mama'].required,
      options: Object.values(
        FIELDS['ha-tenido-algun-problema-por-consumo-de-alcohol-mama'].options
      ),
    component: 'QOptionWrapper',
    type: 'radio',
  },
  {
    id: FIELDS['ha-tenido-algun-problema-por-consumo-de-alcohol-hermanos']
        .key,
      label:
        FIELDS['ha-tenido-algun-problema-por-consumo-de-alcohol-hermanos']
          .label,
      required:
        FIELDS['ha-tenido-algun-problema-por-consumo-de-alcohol-hermanos']
          .required,
      options: Object.values(
        FIELDS['ha-tenido-algun-problema-por-consumo-de-alcohol-hermanos']
          .options
      ),
    component: 'QOptionWrapper',
    type: 'radio',
  },
  {
    id: FIELDS['ha-tenido-algun-problema-por-consumo-de-alcohol-amigo'].key,
      label:
        FIELDS['ha-tenido-algun-problema-por-consumo-de-alcohol-amigo'].label,
      required:
        FIELDS['ha-tenido-algun-problema-por-consumo-de-alcohol-amigo']
          .required,
      options: Object.values(
        FIELDS['ha-tenido-algun-problema-por-consumo-de-alcohol-amigo'].options
      ),
    component: 'QOptionWrapper',
    type: 'radio',
  },
  {
    id: FIELDS['ha-tenido-algun-problema-por-consumo-de-alcohol-familiar']
        .key,
      label:
        FIELDS['ha-tenido-algun-problema-por-consumo-de-alcohol-familiar']
          .label,
      required:
        FIELDS['ha-tenido-algun-problema-por-consumo-de-alcohol-familiar']
          .required,
      options: Object.values(
        FIELDS['ha-tenido-algun-problema-por-consumo-de-alcohol-familiar']
          .options
      ),
    component: 'QOptionWrapper',
    type: 'radio',
  },

  /// -----------------------------------------------
  // [
  //   {
  //     id: FIELDS['alguna-vez-has-probado-droga'].key,
  //     label: FIELDS['alguna-vez-has-probado-droga'].label,
  //     required: FIELDS['alguna-vez-has-probado-droga'].required,
  //     options: Object.values(FIELDS['alguna-vez-has-probado-droga'].options)
  //   },
  //   {
  //     id: FIELDS['has-probado-marihuana'].key,
  //     label: FIELDS['has-probado-marihuana'].label,
  //     required: FIELDS['has-probado-marihuana'].required,
  //     options: Object.values(FIELDS['has-probado-marihuana'].options)
  //   },
  //   {
  //     id: FIELDS['has-probado-cocaína'].key,
  //     label: FIELDS['has-probado-cocaína'].label,
  //     required: FIELDS['has-probado-cocaína'].required,
  //     options: Object.values(FIELDS['has-probado-cocaína'].options)
  //   },
  //   {
  //     id: FIELDS['has-probado-inhalables'].key,
  //     label: FIELDS['has-probado-inhalables'].label,
  //     required: FIELDS['has-probado-inhalables'].required,
  //     subLabel: FIELDS['has-probado-inhalables'].subLabel,
  //     options: Object.values(FIELDS['has-probado-inhalables'].options)
  //   },
  //   {
  //     id: FIELDS['has-probado-anfetaminas'].key,
  //     label: FIELDS['has-probado-anfetaminas'].label,
  //     required: FIELDS['has-probado-anfetaminas'].required,
  //     subLabel: FIELDS['has-probado-anfetaminas'].subLabel,
  //     options: Object.values(FIELDS['has-probado-anfetaminas'].options)
  //   },
  //   {
  //     id: FIELDS['has-probado-tranquilizantes'].key,
  //     label: FIELDS['has-probado-tranquilizantes'].label,
  //     required: FIELDS['has-probado-tranquilizantes'].required,
  //     options: Object.values(FIELDS['has-probado-tranquilizantes'].options)
  //   },
  //   {
  //     id: FIELDS['has-probado-heroína'].key,
  //     label: FIELDS['has-probado-heroína'].label,
  //     required: FIELDS['has-probado-heroína'].required,
  //     options: Object.values(FIELDS['has-probado-heroína'].options)
  //   },
  //   {
  //     id: FIELDS['que-edad-tenias-primera-vez-consumiste-alguna-estas-drogas']
  //       .key,
  //     label:
  //       FIELDS['que-edad-tenias-primera-vez-consumiste-alguna-estas-drogas']
  //         .label,
  //     required:
  //       FIELDS['que-edad-tenias-primera-vez-consumiste-alguna-estas-drogas']
  //         .required
  //   },
  //   {
  //     id: FIELDS['cuantas-veces-en-tu-vida-has-probado-estas-drogas'].key,
  //     label: FIELDS['cuantas-veces-en-tu-vida-has-probado-estas-drogas'].label,
  //     required:
  //       FIELDS['cuantas-veces-en-tu-vida-has-probado-estas-drogas'].required,
  //     options: Object.values(
  //       FIELDS['cuantas-veces-en-tu-vida-has-probado-estas-drogas'].options
  //     )
  //   },
  //   {
  //     id: FIELDS['hace-cuanto-tiempo-probaste-por-primera-vez'].key,
  //     label: FIELDS['hace-cuanto-tiempo-probaste-por-primera-vez'].label,
  //     required: FIELDS['hace-cuanto-tiempo-probaste-por-primera-vez'].required,
  //     options: Object.values(
  //       FIELDS['hace-cuanto-tiempo-probaste-por-primera-vez'].options
  //     )
  //   },
  //   {
  //     id: FIELDS['has-consumido-estos-ultimos-doce-meses'].key,
  //     label: FIELDS['has-consumido-estos-ultimos-doce-meses'].label,
  //     required: FIELDS['has-consumido-estos-ultimos-doce-meses'].required,
  //     options: Object.values(
  //       FIELDS['has-consumido-estos-ultimos-doce-meses'].options
  //     )
  //   },
  //   {
  //     id: FIELDS['has-consumido-el-ultimo-mes'].key,
  //     label: FIELDS['has-consumido-el-ultimo-mes'].label,
  //     required: FIELDS['has-consumido-el-ultimo-mes'].required,
  //     options: Object.values(FIELDS['has-consumido-el-ultimo-mes'].options)
  //   },
  //   {
  //     id: FIELDS['has-intentado-dejar-o-consumir-menos'].key,
  //     label: FIELDS['has-intentado-dejar-o-consumir-menos'].label,
  //     required: FIELDS['has-intentado-dejar-o-consumir-menos'].required,
  //     options: Object.values(
  //       FIELDS['has-intentado-dejar-o-consumir-menos'].options
  //     )
  //   },
  //   {
  //     id: FIELDS['fecha-de-cuando-intentaste'].key,
  //     label: FIELDS['fecha-de-cuando-intentaste'].label,
  //     required: FIELDS['fecha-de-cuando-intentaste'].required
  //   },
  //   {
  //     id: FIELDS['porque-hiciste-el-intento'].key,
  //     label: FIELDS['porque-hiciste-el-intento'].label,
  //     required: FIELDS['porque-hiciste-el-intento'].required
  //   },
  //   {
  //     id: FIELDS['todavia-estas-intentandolo'].key,
  //     label: FIELDS['todavia-estas-intentandolo'].label,
  //     required: FIELDS['todavia-estas-intentandolo'].required,
  //     options: Object.values(FIELDS['todavia-estas-intentandolo'].options)
  //   },
  //   {
  //     id: FIELDS['en-que-plazo-te-gustaria-intentar-consumir-menos'].key,
  //     label: FIELDS['en-que-plazo-te-gustaria-intentar-consumir-menos'].label,
  //     required:
  //       FIELDS['en-que-plazo-te-gustaria-intentar-consumir-menos'].required,
  //     options: Object.values(
  //       FIELDS['en-que-plazo-te-gustaria-intentar-consumir-menos'].options
  //     )
  //   },
  //   {
  //     id: FIELDS['que-tan-peligroso-crees-consumir-drogas-que-has-probado'].key,
  //     label:
  //       FIELDS['que-tan-peligroso-crees-consumir-drogas-que-has-probado'].label,
  //     required:
  //       FIELDS['que-tan-peligroso-crees-consumir-drogas-que-has-probado']
  //         .required,
  //     options: Object.values(
  //       FIELDS['que-tan-peligroso-crees-consumir-drogas-que-has-probado']
  //         .options
  //     )
  //   },
  //   {
  //     id: FIELDS['que-tan-peligroso-crees-que-consumir-alcohol-muy-seguido']
  //       .key,
  //     label:
  //       FIELDS['que-tan-peligroso-crees-que-consumir-alcohol-muy-seguido']
  //         .label,
  //     required:
  //       FIELDS['que-tan-peligroso-crees-que-consumir-alcohol-muy-seguido']
  //         .required,
  //     options: Object.values(
  //       FIELDS['que-tan-peligroso-crees-que-consumir-alcohol-muy-seguido']
  //         .options
  //     )
  //   },
  //   {
  //     id: FIELDS['que-opina-tu-mama'].key,
  //     label: FIELDS['que-opina-tu-mama'].label,
  //     required: FIELDS['que-opina-tu-mama'].required,
  //     options: Object.values(FIELDS['que-opina-tu-mama'].options)
  //   },
  //   {
  //     id: FIELDS['que-opina-tu-papa'].key,
  //     label: FIELDS['que-opina-tu-papa'].label,
  //     required: FIELDS['que-opina-tu-papa'].required,
  //     options: Object.values(FIELDS['que-opina-tu-papa'].options)
  //   },
  //   {
  //     id: FIELDS['opinan-tus-maestros-o-patrones'].key,
  //     label: FIELDS['opinan-tus-maestros-o-patrones'].label,
  //     required: FIELDS['opinan-tus-maestros-o-patrones'].required,
  //     options: Object.values(FIELDS['opinan-tus-maestros-o-patrones'].options)
  //   },
  //   {
  //     id: FIELDS['opina-tu-mejor-amigo'].key,
  //     label: FIELDS['opina-tu-mejor-amigo'].label,
  //     required: FIELDS['opina-tu-mejor-amigo'].required,
  //     options: Object.values(FIELDS['opina-tu-mejor-amigo'].options)
  //   },
  //   {
  //     id: FIELDS['que-opinan-la-mayoria-de-jovenes'].key,
  //     label: FIELDS['que-opinan-la-mayoria-de-jovenes'].label,
  //     required: FIELDS['que-opinan-la-mayoria-de-jovenes'].required,
  //     options: Object.values(FIELDS['que-opinan-la-mayoria-de-jovenes'].options)
  //   },
  //   {
  //     id: FIELDS['ha-usado-alguna-droga-ilegal-papa'].key,
  //     label: FIELDS['ha-usado-alguna-droga-ilegal-papa'].label,
  //     required: FIELDS['ha-usado-alguna-droga-ilegal-papa'].required,
  //     options: Object.values(
  //       FIELDS['ha-usado-alguna-droga-ilegal-papa'].options
  //     )
  //   },
  //   {
  //     id: FIELDS['ha-usado-alguna-droga-ilegal-mama'].key,
  //     label: FIELDS['ha-usado-alguna-droga-ilegal-mama'].label,
  //     required: FIELDS['ha-usado-alguna-droga-ilegal-mama'].required,
  //     options: Object.values(
  //       FIELDS['ha-usado-alguna-droga-ilegal-mama'].options
  //     )
  //   },
  //   {
  //     id: FIELDS['ha-usado-alguna-droga-ilegal-hermanos'].key,
  //     label: FIELDS['ha-usado-alguna-droga-ilegal-hermanos'].label,
  //     required: FIELDS['ha-usado-alguna-droga-ilegal-hermanos'].required,
  //     options: Object.values(
  //       FIELDS['ha-usado-alguna-droga-ilegal-hermanos'].options
  //     )
  //   },
  //   {
  //     id: FIELDS['ha-usado-alguna-droga-ilegal-amigo'].key,
  //     label: FIELDS['ha-usado-alguna-droga-ilegal-amigo'].label,
  //     required: FIELDS['ha-usado-alguna-droga-ilegal-amigo'].required,
  //     options: Object.values(
  //       FIELDS['ha-usado-alguna-droga-ilegal-amigo'].options
  //     )
  //   },
  //   {
  //     id: FIELDS['ha-usado-alguna-droga-ilegal-familiar'].key,
  //     label: FIELDS['ha-usado-alguna-droga-ilegal-familiar'].label,
  //     required: FIELDS['ha-usado-alguna-droga-ilegal-familiar'].required,
  //     options: Object.values(
  //       FIELDS['ha-usado-alguna-droga-ilegal-familiar'].options
  //     )
  //   },
  //   {
  //     id: FIELDS['ha-tenido-algun-problema-por-consumo-de-alcohol-papa'].key,
  //     label:
  //       FIELDS['ha-tenido-algun-problema-por-consumo-de-alcohol-papa'].label,
  //     required:
  //       FIELDS['ha-tenido-algun-problema-por-consumo-de-alcohol-papa'].required,
  //     options: Object.values(
  //       FIELDS['ha-tenido-algun-problema-por-consumo-de-alcohol-papa'].options
  //     )
  //   },
  //   {
  //     id: FIELDS['ha-tenido-algun-problema-por-consumo-de-alcohol-mama'].key,
  //     label:
  //       FIELDS['ha-tenido-algun-problema-por-consumo-de-alcohol-mama'].label,
  //     required:
  //       FIELDS['ha-tenido-algun-problema-por-consumo-de-alcohol-mama'].required,
  //     options: Object.values(
  //       FIELDS['ha-tenido-algun-problema-por-consumo-de-alcohol-mama'].options
  //     )
  //   },
  //   {
  //     id: FIELDS['ha-tenido-algun-problema-por-consumo-de-alcohol-hermanos']
  //       .key,
  //     label:
  //       FIELDS['ha-tenido-algun-problema-por-consumo-de-alcohol-hermanos']
  //         .label,
  //     required:
  //       FIELDS['ha-tenido-algun-problema-por-consumo-de-alcohol-hermanos']
  //         .required,
  //     options: Object.values(
  //       FIELDS['ha-tenido-algun-problema-por-consumo-de-alcohol-hermanos']
  //         .options
  //     )
  //   },
  //   {
  //     id: FIELDS['ha-tenido-algun-problema-por-consumo-de-alcohol-amigo'].key,
  //     label:
  //       FIELDS['ha-tenido-algun-problema-por-consumo-de-alcohol-amigo'].label,
  //     required:
  //       FIELDS['ha-tenido-algun-problema-por-consumo-de-alcohol-amigo']
  //         .required,
  //     options: Object.values(
  //       FIELDS['ha-tenido-algun-problema-por-consumo-de-alcohol-amigo'].options
  //     )
  //   },
  //   {
  //     id: FIELDS['ha-tenido-algun-problema-por-consumo-de-alcohol-familiar']
  //       .key,
  //     label:
  //       FIELDS['ha-tenido-algun-problema-por-consumo-de-alcohol-familiar']
  //         .label,
  //     required:
  //       FIELDS['ha-tenido-algun-problema-por-consumo-de-alcohol-familiar']
  //         .required,
  //     options: Object.values(
  //       FIELDS['ha-tenido-algun-problema-por-consumo-de-alcohol-familiar']
  //         .options
  //     )
  //   }
  // ]
];
