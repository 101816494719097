import { AreaFamiliarData } from 'src/types/initial-evaluation/evaluacion-personal';
import { FIELDS } from 'src/meta/initial-evaluation/AreaFamiliarMeta';
import { Icontext } from 'src/types/blitzar';
import { InitialEvaluationMetaTitleClasses } from 'src/meta/initial-evaluation/InitialEvaluationMeta';

type ctx = Icontext<Partial<AreaFamiliarData>>;
export const AreaFamiliarSchema = [
  {
    component: 'p',
    slot: FIELDS.title.label,
    componentClasses: InitialEvaluationMetaTitleClasses
  },
  {
    id: FIELDS['familiares-o-amigos-te-presionan-para-consumir'].key,
    label: FIELDS['familiares-o-amigos-te-presionan-para-consumir'].label,
    required: FIELDS['familiares-o-amigos-te-presionan-para-consumir'].required,
    options: Object.values(
      FIELDS['familiares-o-amigos-te-presionan-para-consumir'].options
    ),
    component: 'QOptionWrapper',
    type: 'radio'
  },
  {
    id: FIELDS['quienes-te-ayudarian-a-cambiar-tu-consumo'].key,
    label: FIELDS['quienes-te-ayudarian-a-cambiar-tu-consumo'].label,
    required: FIELDS['quienes-te-ayudarian-a-cambiar-tu-consumo'].required,
    subLabel: FIELDS['quienes-te-ayudarian-a-cambiar-tu-consumo'].subLabel,
    options: Object.values(
      FIELDS['quienes-te-ayudarian-a-cambiar-tu-consumo'].options
    ),
    component: 'QOptionWrapper',
    type: 'checkbox',
    defaultValue: () => []
  },
  {
    id: FIELDS['has-tenido-algun-conflicto-familiar-grave'].key,
    label: FIELDS['has-tenido-algun-conflicto-familiar-grave'].label,
    required: FIELDS['has-tenido-algun-conflicto-familiar-grave'].required,
    options: Object.values(
      FIELDS['has-tenido-algun-conflicto-familiar-grave'].options
    ),
    component: 'QOptionWrapper',
    type: 'radio'
  },
  {
    id: FIELDS['es-comun-que-peleen-padres-o-adultos-en-tu-casa'].key,
    label: FIELDS['es-comun-que-peleen-padres-o-adultos-en-tu-casa'].label,
    required:
      FIELDS['es-comun-que-peleen-padres-o-adultos-en-tu-casa'].required,
    options: Object.values(
      FIELDS['es-comun-que-peleen-padres-o-adultos-en-tu-casa'].options
    ),
    component: 'QOptionWrapper',
    type: 'radio'
  },
  {
    id: FIELDS['cuando-han-peleado-adultos-alguno-habia-bebido-alcohol'].key,
    label:
      FIELDS['cuando-han-peleado-adultos-alguno-habia-bebido-alcohol'].label,
    required:
      FIELDS['cuando-han-peleado-adultos-alguno-habia-bebido-alcohol'].required,
    options: Object.values(
      FIELDS['cuando-han-peleado-adultos-alguno-habia-bebido-alcohol'].options
    ),
    component: 'QOptionWrapper',
    type: 'radio'
  },
  {
    id: FIELDS['como-describes-la-relacion-con-tus-padres'].key,
    label: FIELDS['como-describes-la-relacion-con-tus-padres'].label,
    required: FIELDS['como-describes-la-relacion-con-tus-padres'].required,
    options: Object.values(
      FIELDS['como-describes-la-relacion-con-tus-padres'].options
    ),
    component: 'QOptionWrapper',
    type: 'radio'
  },
  {
    id: FIELDS['como-describes-la-relacion-con-tus-padres-hermanos'].key,
    label: FIELDS['como-describes-la-relacion-con-tus-padres-hermanos'].label,
    required:
      FIELDS['como-describes-la-relacion-con-tus-padres-hermanos'].required,
    options: Object.values(
      FIELDS['como-describes-la-relacion-con-tus-padres-hermanos'].options
    ),
    component: 'QOptionWrapper',
    type: 'radio'
  },
  {
    id: FIELDS[
      'se-niegan-tus-padres-o-tutores-a-hablarte-cuando-se-enfadan-contigo'
    ].key,
    label:
      FIELDS[
        'se-niegan-tus-padres-o-tutores-a-hablarte-cuando-se-enfadan-contigo'
      ].label,
    required:
      FIELDS[
        'se-niegan-tus-padres-o-tutores-a-hablarte-cuando-se-enfadan-contigo'
      ].required,
    options: Object.values(
      FIELDS[
        'se-niegan-tus-padres-o-tutores-a-hablarte-cuando-se-enfadan-contigo'
      ].options
    ),
    component: 'QOptionWrapper',
    type: 'radio'
  },
  {
    id: FIELDS['tus-padres-o-tutore-saben-donde-estas-y-lo-que-estas-haciendo']
      .key,
    label:
      FIELDS['tus-padres-o-tutore-saben-donde-estas-y-lo-que-estas-haciendo']
        .label,
    required:
      FIELDS['tus-padres-o-tutore-saben-donde-estas-y-lo-que-estas-haciendo']
        .required,
    options: Object.values(
      FIELDS['tus-padres-o-tutore-saben-donde-estas-y-lo-que-estas-haciendo']
        .options
    ),
    component: 'QOptionWrapper',
    type: 'radio'
  },
  {
    id: FIELDS[
      'participas-en-muchas-actividades-en-compañia-de-tus-padre-o-tutores'
    ].key,
    label:
      FIELDS[
        'participas-en-muchas-actividades-en-compañia-de-tus-padre-o-tutores'
      ].label,
    required:
      FIELDS[
        'participas-en-muchas-actividades-en-compañia-de-tus-padre-o-tutores'
      ].required,
    options: Object.values(
      FIELDS[
        'participas-en-muchas-actividades-en-compañia-de-tus-padre-o-tutores'
      ].options
    ),
    component: 'QOptionWrapper',
    type: 'radio'
  },
  {
    id: FIELDS[
      'la-mayoria-de-las-veces-te-prestan-atencion-tus-padres-o-tutores'
    ].key,
    label:
      FIELDS['la-mayoria-de-las-veces-te-prestan-atencion-tus-padres-o-tutores']
        .label,
    required:
      FIELDS['la-mayoria-de-las-veces-te-prestan-atencion-tus-padres-o-tutores']
        .required,
    options: Object.values(
      FIELDS['la-mayoria-de-las-veces-te-prestan-atencion-tus-padres-o-tutores']
        .options
    ),
    component: 'QOptionWrapper',
    type: 'radio'
  },
  {
    id: FIELDS['saben-tus-padres-o-tutores-como-piensas-o-te-sientes'].key,
    label: FIELDS['saben-tus-padres-o-tutores-como-piensas-o-te-sientes'].label,
    required:
      FIELDS['saben-tus-padres-o-tutores-como-piensas-o-te-sientes'].required,
    options: Object.values(
      FIELDS['saben-tus-padres-o-tutores-como-piensas-o-te-sientes'].options
    ),
    component: 'QOptionWrapper',
    type: 'radio'
  },
  {
    id: FIELDS['discutes-gritando-o-levantando-la-voz-frecuente'].key,
    label: FIELDS['discutes-gritando-o-levantando-la-voz-frecuente'].label,
    required:
      FIELDS['discutes-gritando-o-levantando-la-voz-frecuente'].required,
    options: Object.values(
      FIELDS['discutes-gritando-o-levantando-la-voz-frecuente'].options
    ),
    component: 'QOptionWrapper',
    type: 'radio'
  },
  {
    id: FIELDS['le-gusta-a-tus-padre-o-tutore-estar-y-hablar-contigo'].key,
    label: FIELDS['le-gusta-a-tus-padre-o-tutore-estar-y-hablar-contigo'].label,
    required:
      FIELDS['le-gusta-a-tus-padre-o-tutore-estar-y-hablar-contigo'].required,
    options: Object.values(
      FIELDS['le-gusta-a-tus-padre-o-tutore-estar-y-hablar-contigo'].options
    ),
    component: 'QOptionWrapper',
    type: 'radio'
  },
  {
    id: FIELDS['tinen-tus-padres-o-tutores-una-idea-de-lo-que-te-interesa'].key,
    label:
      FIELDS['tinen-tus-padres-o-tutores-una-idea-de-lo-que-te-interesa'].label,
    required:
      FIELDS['tinen-tus-padres-o-tutores-una-idea-de-lo-que-te-interesa']
        .required,
    options: Object.values(
      FIELDS['tinen-tus-padres-o-tutores-una-idea-de-lo-que-te-interesa']
        .options
    ),
    component: 'QOptionWrapper',
    type: 'radio'
  },
  {
    id: FIELDS['tus-padres-estan-de-acuerdo-en-la-forma-que-te-educan'].key,
    label:
      FIELDS['tus-padres-estan-de-acuerdo-en-la-forma-que-te-educan'].label,
    required:
      FIELDS['tus-padres-estan-de-acuerdo-en-la-forma-que-te-educan'].required,
    options: Object.values(
      FIELDS['tus-padres-estan-de-acuerdo-en-la-forma-que-te-educan'].options
    ),
    component: 'QOptionWrapper',
    type: 'radio'
  },
  {
    id: FIELDS['tus-padre-o-tutores-te-asignan-una-cantidad-fija-de-dinero']
      .key,
    label:
      FIELDS['tus-padre-o-tutores-te-asignan-una-cantidad-fija-de-dinero']
        .label,
    required:
      FIELDS['tus-padre-o-tutores-te-asignan-una-cantidad-fija-de-dinero']
        .required,
    options: Object.values(
      FIELDS['tus-padre-o-tutores-te-asignan-una-cantidad-fija-de-dinero']
        .options
    ),
    component: 'QOptionWrapper',
    type: 'radio'
  },
  {
    id: FIELDS['cuanto-dinero-te-asignan'].key,
    label: FIELDS['cuanto-dinero-te-asignan'].label,
    component: 'q-input',
    type: 'number',
    dynamicProps: ['showCondition', 'required'],
    required: (val: number, { formData }: ctx) =>
      formData['tus-padre-o-tutores-te-asignan-una-cantidad-fija-de-dinero'] ===
      'si',
    showCondition: (val: number, { formData }: ctx) =>
      formData['tus-padre-o-tutores-te-asignan-una-cantidad-fija-de-dinero'] ===
      'si'
  },
  {
    id: FIELDS['cuanto-dinero-utilizas-a-la-semana-en-alcohol-o-drogas'].key,
    label:
      FIELDS['cuanto-dinero-utilizas-a-la-semana-en-alcohol-o-drogas'].label,
    required:
      FIELDS['cuanto-dinero-utilizas-a-la-semana-en-alcohol-o-drogas'].required,
    component: 'q-input',
    type: 'number'
  },
  {
    id: FIELDS['tus-padres-o-tutores-saben-que-tomas-alcohol-o-consumes-drogas']
      .key,
    label:
      FIELDS['tus-padres-o-tutores-saben-que-tomas-alcohol-o-consumes-drogas']
        .label,
    required:
      FIELDS['tus-padres-o-tutores-saben-que-tomas-alcohol-o-consumes-drogas']
        .required,
    options: Object.values(
      FIELDS['tus-padres-o-tutores-saben-que-tomas-alcohol-o-consumes-drogas']
        .options
    ),
    component: 'QOptionWrapper',
    type: 'radio'
  },
  {
    id: FIELDS[
      'te-han-puesto-reglas-muy-claras-sobre-el-consumo-de-alcohol-o-drogas'
    ].key,
    label:
      FIELDS[
        'te-han-puesto-reglas-muy-claras-sobre-el-consumo-de-alcohol-o-drogas'
      ].label,
    options: Object.values(
      FIELDS[
        'te-han-puesto-reglas-muy-claras-sobre-el-consumo-de-alcohol-o-drogas'
      ].options
    ),
    component: 'QOptionWrapper',
    type: 'radio',
    dynamicProps: ['showCondition', 'required'],
    required: (val: string, { formData }: ctx) =>
      formData[
        'tus-padres-o-tutores-saben-que-tomas-alcohol-o-consumes-drogas'
      ] === 'si',
    showCondition: (val: string, { formData }: ctx) =>
      formData[
        'tus-padres-o-tutores-saben-que-tomas-alcohol-o-consumes-drogas'
      ] === 'si'
  },
  {
    id: FIELDS['es-importante-por-ti-cumplir-esas-reglas'].key,
    label: FIELDS['es-importante-por-ti-cumplir-esas-reglas'].label,
    options: Object.values(
      FIELDS['es-importante-por-ti-cumplir-esas-reglas'].options
    ),
    component: 'QOptionWrapper',
    type: 'radio',
    dynamicProps: ['showCondition', 'required'],
    required: (val: string, { formData }: ctx) =>
      formData[
        'te-han-puesto-reglas-muy-claras-sobre-el-consumo-de-alcohol-o-drogas'
      ] === 'si',
    showCondition: (val: string, { formData }: ctx) =>
      formData[
        'te-han-puesto-reglas-muy-claras-sobre-el-consumo-de-alcohol-o-drogas'
      ] === 'si'
  }
];
