import { IsessionsData, Sesion, sesionStates } from 'src/types/PIBA/Sesion';

import { AnswerManager } from './useAnswerManager';
import { FlowState } from 'src/composables/FlowState';
import { date } from 'quasar';
import { useAnswerManager } from 'src/composables/useAnswerManager';

interface scheduleEventArgs {
  dateSelected?: Date;
  sesionNumber: number;
  update?: boolean;
  /** To disable updating the flowState when the therapist is scheduling the sessions
   * ahead of time.
   */
  updateFlowState?: boolean;
}
export abstract class FlowEvent {
  flowState: FlowState;
  db: PouchDB.Database;
  public CreateEvent: typeof Sesion;
  sessionsData: IsessionsData[];
  couchDBQueryDocName: string;
  waitUntilTimeIsReached: string;
  // answerDocManager: AnswerManager;

  getSesionInfo(sesionNumber: number) {
    const sessionData = this.sessionsData.find(
      (session) => session.number === sesionNumber
    );
    if (!sessionData) {
      throw Error('Trying to fetch unexistent session data');
    }
    return sessionData;
  }

  constructor(
    flowState: FlowState,
    createEventCls: typeof Sesion,
    sessionsData: IsessionsData[],
    couchDBQueryDocName: string,
    waitUntilTimeIsReached: string
    // docIdPrefix: string,
  ) {
    this.flowState = flowState;
    this.db = this.flowState.db;
    this.CreateEvent = createEventCls;
    this.sessionsData = sessionsData;
    this.couchDBQueryDocName = couchDBQueryDocName;
    this.waitUntilTimeIsReached = waitUntilTimeIsReached;
    // this.answerDocManager =  useAnswerManager(this.db, `${docIdPrefix}.`, handleDocLoad);
  }
  /**
   * This function needs to use the query doc specified at `couchDBQueryDocName` because the events name are in the format of
   * `E_2023-01-22T02:03:54.712Z_3.33`
   * @example queryDoc
   * ```
   * {
   *  "_id": "_design/sesiones",
   *  "_rev": "1-2973384f41061692c6bca0d6df06c143",
   *  "views": {
   *        "sesiones": {
   *          "map": "function (doc) {\n                    if (doc.eventType && doc.eventType === 's') {\n                        emit(doc.sesionNumber, doc.done);\n                    }\n                }"
   *        }
   *      }
   *  }
   * ```
   * @param sesionNumber The session number we want to fetch
   * @returns
   */
  async getEvent(sesionNumber: number) {
    // const currentState = this.flowState.state
    // const sessionState = this.flowState._build_state_index(sesionNumber);
    try {
      const sesionQuery = await this.db.query<Sesion>(
        this.couchDBQueryDocName,
        {
          key: sesionNumber,
          include_docs: true
        }
      );
      if (sesionQuery.rows.length < 1 || !sesionQuery.rows[0].doc) {
        return undefined;
      }
      return sesionQuery.rows[0].doc;
    } catch {}
    return undefined;
    // return await this.db.get<Sesion>(sessionState.url);
  }
  async getEventStates(): Promise<IsessionsData[]> {
    return [];
  }
  /** This method should be reimplemented on each subclass, the default is the weekly sessions behavior
   * where we substract 2 to remove the first and last urls and then substract another because
   * ConsejoBreve sessions are 0-based
   */
  hasFinishedAllEvents(eventNumber: number) {
    console.log(eventNumber, this.flowState.flowStateURLs.length);
    // debugger;
    return eventNumber >= this.flowState.flowStateURLs.length - 2;
  }
  async scheduleEvent({
    dateSelected,
    sesionNumber,
    update = false,
    updateFlowState = true
  }: scheduleEventArgs) {
    // await this.updateFlowStateSesiones(db);
    if (this.hasFinishedAllEvents(sesionNumber)) {
      console.log('Has finalizado los eventos disponibles.');
      return undefined;
    }
    const dateSesion =
      dateSelected?.toISOString() ||
      date.addToDate(new Date(), { days: 7 }).toISOString();
    const sesionEvent = new this.CreateEvent({
      date: dateSesion,
      sesionNumber
    });
    console.log(sesionEvent);
    try {
      const sesionDoc = await this.getEvent(sesionNumber);
      if (sesionDoc == null) {
        // First time scheduling this session
        await this.db.put(sesionEvent);
        if (updateFlowState) {
          await this.flowState.updateFlowState(this.flowState.state.next);
        }
        return sesionEvent;
      }
      if (!update) {
        console.log('update :>> ', update);
        console.log('Sesion already scheduled, not updating', sesionDoc);
        return sesionDoc;
      } else if (sesionDoc) {
        await this.db.remove(sesionDoc);
        console.log('Re-creating sesion event with new Date');
      }
      await this.db.put(sesionEvent);
      return sesionEvent;
    } catch {
      // First time scheduling this session
      await this.db.put(sesionEvent);
      if (updateFlowState) {
        await this.flowState.updateFlowState(this.flowState.state.next);
      }
      return sesionEvent;
    }
  }
  async sesionesData(): Promise<IsessionsData[]> {
    const currentState = this.flowState.state;
    let nextSession: Sesion | undefined;
    const nextSessionIndex = currentState._stateIndex;
    const sesionesData = Promise.all(
      this.sessionsData.map(async (sesion, index) => {
        if (nextSessionIndex > this.flowState.flowStateURLs.length - 2) {
          return {
            ...sesion,
            status: sesionStates.REVIEW
          };
        }
        if (nextSessionIndex === index + 1) {
          try {
            nextSession = await this.getEvent(currentState.value);
          } catch {
            // console.log('The patient has not been scheduled');
          }
          if (nextSession == null) {
            return {
              ...sesion,
              status: sesionStates.PENDING
            };
          }
          return {
            ...sesion,
            status:
              date.getDateDiff(
                new Date(nextSession.date),
                new Date(),
                'minutes'
              ) < 0
                ? sesionStates.TODO
                : sesionStates.SCHEDULED
          };
        }

        return {
          ...sesion,
          status:
            nextSessionIndex > index + 1
              ? sesionStates.REVIEW
              : sesionStates.PENDING
        };
      })
    );
    return sesionesData;
  }
  get progress() {
    const currentSessionNumber = this.flowState.state.value;
    // We substract one from the URLs length to ignore the last URL that is the `/dashboard` url
    const totalSessions = this.flowState.flowStateURLs.length - 1;
    return (currentSessionNumber / totalSessions) * 100;
  }
  async verifyCanProceedEvent(eventNumber: number) {
    // We would check if the User has already finished the initial interview and
    // can proceed to the URL `toPath` otherwise we would return an object
    // containing the next URL the user must visit
    // e.g. `{path: "/evaluacion/historia-de-drogas"}`
    // if the user already completed the initial interview we would return `undefined`
    try {
      const event = await this.getEvent(eventNumber);
      if (event == null) {
        return { path: this.waitUntilTimeIsReached };
      }
      const diff = date.getDateDiff(
        new Date(event?.date),
        new Date(),
        'minutes'
      );
      if (diff <= 0) {
        console.log('Puedes entrar');
        return undefined;
      }
      console.log('Tienes que esperar');
    } catch {
      console.log('The event is not schedule, you need to wait');
    }
    return { path: this.waitUntilTimeIsReached };
  }
}
