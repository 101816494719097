import { ComputedRef, Ref, computed } from 'vue';
import { Evento, ISesion } from './Calendario';

import { CouchDbRow } from './couchdb-response';
import { Paciente } from './Pacientes';
import { citaStates } from './StatusCitaTypes';
import { date } from 'quasar';
import { getUserIdFromEventID } from './Citas';
/* eslint-disable max-classes-per-file */
import { toIsoString } from 'src/types/PIBA/Calendario';

export interface IsessionsData {
  status?: sesionStates;
  /**
   * Session name
   */
  name: string;
  /**
   * Session number
   */
  number: number;
  /**
   * Color to use in the calendar cards
   */
  color: string;
  isFollowUp: boolean;
}
export const consejoBreveSessionsData: IsessionsData[] = [
  {
    name: 'Consejo Breve',
    number: 0,
    color: '#428BC1',
    isFollowUp: false
  }
];

export const sessionsData: IsessionsData[] = [
  {
    name: 'Consejo Breve',
    number: 0,
    color: '#428BC1',
    isFollowUp: false
  },
  {
    name: 'Aprendiendo a Comunicarme',
    number: 1,
    color: '#FA9E9F',
    isFollowUp: false
  },
  {
    name: 'Balanza de Cambio',
    number: 2,
    color: '#1E9485',
    isFollowUp: false
  },
  {
    name: 'Montaña de Cambio',
    number: 3,
    color: '#428BC1',
    isFollowUp: false
  },
  {
    name: 'Plan de Acción',
    number: 4,
    color: '#FFBC88',
    isFollowUp: false
  },
  {
    name: '¿Cuáles son tus metas de vida?',
    number: 5,
    color: '#B15885',
    isFollowUp: false
  },
  {
    name: 'Cruzando la Meta',
    number: 6,
    color: '#17B5AB',
    isFollowUp: false
  }
];

export function getSesionInfo(sesionNumber: number) {
  return sessionsData.find((session) => session.number === sesionNumber);
}

export class Sesion extends Evento implements ISesion {
  eventType: ISesion['eventType'] = 's';

  constructor(evento: Partial<ISesion>) {
    super(evento.date);
    if (evento._id) {
      Object.assign(this, evento);
    } else if (evento.date && evento.sesionNumber != null) {
      this.sesionNumber = evento.sesionNumber;
      this.done = false;
    } else {
      throw new Error(
        'The constructor parameter has missing required properties'
      );
    }
  }

  sesionNumber: ISesion['sesionNumber'] = 1;

  done: ISesion['done'] = false;

  get calendarEventSummary(): string {
    return `Sesión ${this.sesionNumber}: ${
      getSesionInfo(this.sesionNumber)?.name || ''
    }`;
  }

  // eslint-disable-next-line class-methods-use-this
  get calendarEventCardDescription(): string {
    return this.done ? 'Sesión Realizada' : 'Sesión Programada';
  }

  get color() {
    return getSesionInfo(this.sesionNumber)?.color || '#1E9485';
  }
}

export interface sessionSeguimientoInt extends IsessionsData {
  /**
   * Number of months that the session should be scheduled after the last one.
   * Normally when a session is completed the next has to be scheduled so we add
   * the quantity of months to the current date to schedule the following session.
   */
  months: number;
  startURL: string;
}

export const sessionsSeguimientoData: sessionSeguimientoInt[] = [
  {
    name: 'Mis primeros tres meses',
    number: 1,
    color: '#FA9E9F',
    months: 3,
    isFollowUp: true,
    startURL: '/tres-meses'
  },
  {
    name: 'Seis meses después',
    number: 2,
    color: '#1E9485',
    months: 3,
    isFollowUp: true,
    startURL: '/seis-meses'
  },
  {
    name: 'A un año del cambio',
    number: 3,
    color: '#428BC1',
    months: 6,
    isFollowUp: true,
    startURL: '/doce-meses'
  }
];

export function getSesionSeguimientoInfo(sesionNumber: number) {
  return sessionsSeguimientoData.find(
    (session) => session.number === sesionNumber
  );
}

export class SesionSeguimiento extends Sesion implements ISesion {
  eventType: ISesion['eventType'] = 'ss';

  get calendarEventSummary() {
    return `Sesión seguimiento ${this.sesionNumber}: ${
      getSesionSeguimientoInfo(this.sesionNumber)?.name || ''
    }`;
  }

  get color() {
    return getSesionSeguimientoInfo(this.sesionNumber)?.color || '#1E9485';
  }
}

export type pacienteRowRef =
  | ComputedRef<CouchDbRow<Paciente, string>[] | undefined>
  | Ref<CouchDbRow<Paciente, string>[] | undefined>;
export interface AbsSesionOfUser {
  /**
   * The id is composed of the prefix `userId_E_`, the date of the event in ISO format
   * and a random number to handle multiple events without time in the same day
   *
   * @example '7e843dc167b0478a86d6be834d1378d9_E_2021-05-12T20:28:42.684Z_5.33'
   */
  _id: Sesion['_id'];

  userId: string;

  pacientes?: pacienteRowRef;

  state: citaStates;

  setPacientes: (pacientes: pacienteRowRef) => void;

  PacientInfo: ComputedRef<Paciente | undefined>;
}

export type SesionOfUserType = Sesion & AbsSesionOfUser;

export function getStateFromSesion(sesion: ISesion) {
  if (sesion.done) {
    return citaStates.DONE;
  }
  const dateSesion = new Date(sesion.date);
  const currDate = toIsoString(new Date()).slice(0, 10);
  const diff = date.getDateDiff(dateSesion, new Date(currDate), 'minutes');
  if (diff <= 0) {
    return citaStates.DELAYED;
  }
  return citaStates.SCHEDULED;
}
export class SesionOfUser extends Sesion implements AbsSesionOfUser {
  pacientes: AbsSesionOfUser['pacientes'];

  get userId() {
    return getUserIdFromEventID(this._id);
  }

  setPacientes(pacientes: pacienteRowRef) {
    this.pacientes = pacientes;
  }

  get state() {
    return getStateFromSesion(this);
  }

  PacientInfo = computed(() => {
    if (this.pacientes?.value && this.pacientes.value?.length > 0) {
      return this.pacientes.value.find(
        (paciente) => getUserIdFromEventID(paciente.id) === this.userId
      )?.value;
      // return this.pacientes.value[0].value;
    }
    return undefined;
  });
}

export class SesionSeguimientoOfUser
  extends SesionSeguimiento
  implements AbsSesionOfUser
{
  PacientInfo = computed(() => {
    if (this.pacientes?.value && this.pacientes.value?.length > 0) {
      return this.pacientes.value[0].value;
    }
    return undefined;
  });

  pacientes: AbsSesionOfUser['pacientes'];

  setPacientes(pacientes: pacienteRowRef) {
    this.pacientes = pacientes;
  }

  get state() {
    return getStateFromSesion(this);
  }

  get userId() {
    return getUserIdFromEventID(this._id);
  }
}

export enum sesionStates {
  PENDING,
  SCHEDULED,
  DONE,
  TODO,
  REVIEW,
  LATE
}
