import { FlowEvent } from 'src/composables/FlowSession';
import { FlowState } from 'src/composables/FlowState';
import { FollowUpSessionURLs } from 'src/meta/sessions/FollowUpEvaluationMeta';
import { SesionSeguimiento } from 'src/types/PIBA/Sesion';
import { sessionsSeguimientoData } from 'src/types/PIBA/Sesion';

class FlowStateFollowUp extends FlowState {
  constructor(db: PouchDB.Database) {
    super('flowStateFollowUpEvaluation', FollowUpSessionURLs, db);
  }
  async _create_flow_state_doc() {
    const flowState = {
      _id: this.flowStateDocId,
      key: this.flowStateURLs[0],
      value: 0
    };
    return this.db.put(flowState);
  }
}

export const FollowUpSessionIndexName = 'seguimientos';

export class FlowSessionFollowUp extends FlowEvent {
  constructor(db: PouchDB.Database) {
    const flowState = new FlowStateFollowUp(db);
    super(
      flowState,
      SesionSeguimiento,
      sessionsSeguimientoData,
      FollowUpSessionIndexName,
      '/seguimiento'
    );
  }
  hasFinishedAllEvents(eventNumber: number) {
    return eventNumber > this.flowState.flowStateURLs.length - 2;
  }
}

export async function useFlowSessionFollowUp(db: PouchDB.Database) {
  const flowSessionFollowUp = new FlowSessionFollowUp(db);
  await flowSessionFollowUp.flowState.load_current_state();
  return flowSessionFollowUp;
}
