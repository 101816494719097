import { api } from 'src/boot/axios';
import to from 'await-to-js';

export function useS3Object() {
  async function getS3Object(url: string) {
    const s3Server = `${process.env.VUE_APP_AUTH_SERVER}/attachments`;
    // To get the attachment you need to be authenticated so we use the axios api instace
    // https://stackoverflow.com/a/52154751/13737199
    // To check if the browser is caching the attachments open a new tab if you reload the
    // request are made to the server again https://stackoverflow.com/a/65651099
    const [err, response] = await to(
      api.get<Blob>(`${s3Server}${url}`, { responseType: 'blob' })
    );
    if (response != null) {
     
      return {
        // url: `${process.env.VUE_APP_S3_URL}${doc.attachments_s3[attachmentName].url}`,
        // url: URL.createObjectURL(blob),
        url: URL.createObjectURL(response.data),
        buffer: response.data
      };
    }
    console.error(`The attachment could not be retreived: ${url}`, err);
    return { url: '/placeholder-1.png', buffer: undefined };
  }
  return { getS3Object };
}
