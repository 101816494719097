export const FIELDS = {
  title: {
    label: 'Información Personal'
  },
  names: {
    key: 'names',
    label: 'Nombre/s',
    placeholder: 'Nombres/s',
    required: true,
    hint: undefined
  },
  lastNames: {
    key: 'lastNames',
    label: 'Apellido/s',
    placeholder: 'Apellido/s',
    required: true,
    hint: undefined
  },
  'attachments.profile-pics@profile_picture': {
    key: 'attachments.profile-pics@profile_picture',
    label: 'Foto de perfil',
    required: true
  },
  terapeutaElection: {
    key: 'terapeutaElection',
    label: 'Elección de terapeuta',
    required: true,
    hint: undefined
  },
  birthDate: {
    key: 'birthDate',
    label: 'Fecha de nacimiento',
    required: true
  },
  sex: {
    key: 'sex',
    label: 'Sexo',
    required: true
  },
  academicInfo: {
    key: 'academicInfo',
    label: 'Información académica',
    required: true,
    hint: undefined
  },
  addressInfo: {
    key: 'addressInfo',
    label: 'Información Domiciliaria',
    required: true,
    hint: undefined
  }
};
