import { ConsumoDeDrogasAlcoholData } from 'src/types/initial-evaluation/evaluacion-personal';
import { FIELDS } from 'src/meta/initial-evaluation/HistoriaDeAlcoholMeta';
import { Icontext } from 'src/types/blitzar';
import { InitialEvaluationMetaTitleClasses } from 'src/meta/initial-evaluation/InitialEvaluationMeta';

type ctx = Icontext<Partial<ConsumoDeDrogasAlcoholData>>;
export const ConsumoDeDrogasAlcoholSchema = [
  {
    component: 'p',
    slot: FIELDS.title.label,
    componentClasses: InitialEvaluationMetaTitleClasses
  },
  {
    id: FIELDS['has-tomado-una-cerveza-o-cuba'].key,
    label: FIELDS['has-tomado-una-cerveza-o-cuba'].label,
    required: FIELDS['has-tomado-una-cerveza-o-cuba'].required,
    options: Object.values(FIELDS['has-tomado-una-cerveza-o-cuba'].options),
    component: 'QOptionWrapper',
    type: 'radio'
  },
  {
    id: FIELDS['has-tomado-vino'].key,
    label: FIELDS['has-tomado-vino'].label,
    required: FIELDS['has-tomado-vino'].required,
    options: Object.values(FIELDS['has-tomado-vino'].options),
    component: 'QOptionWrapper',
    type: 'radio'
  },
  {
    id: FIELDS['has-tomado-coolers'].key,
    label: FIELDS['has-tomado-coolers'].label,
    required: FIELDS['has-tomado-coolers'].required,
    options: Object.values(FIELDS['has-tomado-coolers'].options),
    component: 'QOptionWrapper',
    type: 'radio'
  },
  {
    id: FIELDS['has-tomado-brandy'].key,
    label: FIELDS['has-tomado-brandy'].label,
    required: FIELDS['has-tomado-brandy'].required,
    options: Object.values(FIELDS['has-tomado-brandy'].options),
    component: 'QOptionWrapper',
    type: 'radio'
  },
  {
    id: FIELDS['has-tomado-bebidas-preparadas'].key,
    label: FIELDS['has-tomado-bebidas-preparadas'].label,
    required: FIELDS['has-tomado-bebidas-preparadas'].required,
    options: Object.values(FIELDS['has-tomado-bebidas-preparadas'].options),
    component: 'QOptionWrapper',
    type: 'radio'
  },
  {
    id: FIELDS['has-tomado-pulque'].key,
    label: FIELDS['has-tomado-pulque'].label,
    required: FIELDS['has-tomado-pulque'].required,
    options: Object.values(FIELDS['has-tomado-pulque'].options),
    component: 'QOptionWrapper',
    type: 'radio'
  },
  {
    id: FIELDS['has-tomado-aguardiente'].key,
    label: FIELDS['has-tomado-aguardiente'].label,
    required: FIELDS['has-tomado-aguardiente'].required,
    options: Object.values(FIELDS['has-tomado-aguardiente'].options),
    component: 'QOptionWrapper',
    type: 'radio'
  },
  {
    id: FIELDS['que-edad-tenias-la-primera-vez-tomaste-una-copa-completa'].key,
    label:
      FIELDS['que-edad-tenias-la-primera-vez-tomaste-una-copa-completa'].label,
    required:
      FIELDS['que-edad-tenias-la-primera-vez-tomaste-una-copa-completa']
        .required,
    component: 'q-input',
    type: 'number',
    min: 0,
    max: 150
  },
  {
    id: FIELDS['cuantas-veces-tomaste-en-el-ultimo-año'].key,
    label: FIELDS['cuantas-veces-tomaste-en-el-ultimo-año'].label,
    required: FIELDS['cuantas-veces-tomaste-en-el-ultimo-año'].required,
    subLabel: FIELDS['cuantas-veces-tomaste-en-el-ultimo-año'].subLabel,
    options: Object.values(
      FIELDS['cuantas-veces-tomaste-en-el-ultimo-año'].options
    ),
    component: 'QOptionWrapper',
    type: 'radio'
  },
  {
    id: FIELDS['cuantas-veces-tomaste-en-el-ultimo-mes-una-o-mas-copas'].key,
    label:
      FIELDS['cuantas-veces-tomaste-en-el-ultimo-mes-una-o-mas-copas'].label,
    required:
      FIELDS['cuantas-veces-tomaste-en-el-ultimo-mes-una-o-mas-copas'].required,
    subLabel:
      FIELDS['cuantas-veces-tomaste-en-el-ultimo-mes-una-o-mas-copas'].subLabel,
    options: Object.values(
      FIELDS['cuantas-veces-tomaste-en-el-ultimo-mes-una-o-mas-copas'].options
    ),
    component: 'QOptionWrapper',
    type: 'radio'
  },
  {
    id: FIELDS['cuantas-veces-tomaste-en-el-ultimo-mes-cinco-o-mas-copas'].key,
    label:
      FIELDS['cuantas-veces-tomaste-en-el-ultimo-mes-cinco-o-mas-copas'].label,
    required:
      FIELDS['cuantas-veces-tomaste-en-el-ultimo-mes-cinco-o-mas-copas']
        .required,
    subLabel:
      FIELDS['cuantas-veces-tomaste-en-el-ultimo-mes-cinco-o-mas-copas']
        .subLabel,
    options: Object.values(
      FIELDS['cuantas-veces-tomaste-en-el-ultimo-mes-cinco-o-mas-copas'].options
    ),
    component: 'QOptionWrapper',
    type: 'radio'
  },
  {
    id: FIELDS['cuantas-veces-te-emborrachaste-en-el-ultimo-mes'].key,
    label: FIELDS['cuantas-veces-te-emborrachaste-en-el-ultimo-mes'].label,
    required:
      FIELDS['cuantas-veces-te-emborrachaste-en-el-ultimo-mes'].required,
    options: Object.values(
      FIELDS['cuantas-veces-te-emborrachaste-en-el-ultimo-mes'].options
    ),
    component: 'QOptionWrapper',
    type: 'radio'
  },
  {
    id: FIELDS['has-intentado-tomar-menos-o-dejar-de-tomar'].key,
    label: FIELDS['has-intentado-tomar-menos-o-dejar-de-tomar'].label,
    required: FIELDS['has-intentado-tomar-menos-o-dejar-de-tomar'].required,
    options: Object.values(
      FIELDS['has-intentado-tomar-menos-o-dejar-de-tomar'].options
    ),
    component: 'QOptionWrapper',
    type: 'radio',
    componentClasses: 'scroll-id-has-intentado-tomar-menos-o-dejar-de-tomar',
    events: {
      input: (value: string, context: ctx) => {
        if (value === 'si') {
          // TODO: Can this be extracted to a hook helper?
          // That allows it to be reproducted in a lot of places
          // Right now I think there are only a few questions
          // that require to be scrolled
          const elements = document.getElementsByClassName(
            'scroll-id-has-intentado-tomar-menos-o-dejar-de-tomar'
          );
          setTimeout(() => {
            // wait until vue renders the appearing questions
            elements[0].scrollIntoView({
              behavior: 'smooth',
              block: 'center',
              inline: 'nearest'
            });
          }, 250);
        }
      }
    }
  },
  {
    id: FIELDS['desde-cuando-iniciaste-a-cambiar-tu-consumo'].key,
    label: FIELDS['desde-cuando-iniciaste-a-cambiar-tu-consumo'].label,
    options: FIELDS['desde-cuando-iniciaste-a-cambiar-tu-consumo'].options,
    component: 'SelectOrComponent',
    otherComponent: 'q-input',
    defaultValueForOther: '',
    type: 'date',
    dynamicProps: ['showCondition', 'required'],
    required: (
      val: string,
      { formData }: { formData: ConsumoDeDrogasAlcoholData }
    ) => formData['has-intentado-tomar-menos-o-dejar-de-tomar'] === 'si',
    showCondition: (
      val: string,
      { formData }: { formData: ConsumoDeDrogasAlcoholData }
    ) => formData['has-intentado-tomar-menos-o-dejar-de-tomar'] === 'si'
  },
  {
    id: FIELDS['porque-hiciste-el-cambio'].key,
    label: FIELDS['porque-hiciste-el-cambio'].label,
    component: 'q-input',
    dynamicProps: ['showCondition', 'required'],
    required: (
      val: string,
      { formData }: { formData: ConsumoDeDrogasAlcoholData }
    ) => formData['has-intentado-tomar-menos-o-dejar-de-tomar'] === 'si',
    showCondition: (
      val: string,
      { formData }: { formData: ConsumoDeDrogasAlcoholData }
    ) => formData['has-intentado-tomar-menos-o-dejar-de-tomar'] === 'si'
  },
  {
    id: FIELDS['todavia-te-encuentras-realizando-este-cambio'].key,
    label: FIELDS['todavia-te-encuentras-realizando-este-cambio'].label,
    options: Object.values(
      FIELDS['todavia-te-encuentras-realizando-este-cambio'].options
    ),
    component: 'QOptionWrapper',
    type: 'radio',
    dynamicProps: ['showCondition', 'required'],
    required: (
      val: string,
      { formData }: { formData: ConsumoDeDrogasAlcoholData }
    ) => formData['has-intentado-tomar-menos-o-dejar-de-tomar'] === 'si',
    showCondition: (
      val: string,
      { formData }: { formData: ConsumoDeDrogasAlcoholData }
    ) => formData['has-intentado-tomar-menos-o-dejar-de-tomar'] === 'si'
  },
  {
    id: FIELDS['en-que-plazo-te-gustaria-intentar-tomar-menos'].key,
    label: FIELDS['en-que-plazo-te-gustaria-intentar-tomar-menos'].label,
    required: FIELDS['en-que-plazo-te-gustaria-intentar-tomar-menos'].required,
    options: Object.values(
      FIELDS['en-que-plazo-te-gustaria-intentar-tomar-menos'].options
    ),
    component: 'QOptionWrapper',
    type: 'radio'
  }
];
