import { BasicRouteInfo } from './../evaluation/EvaluationMeta';
import { EvaluationRoutes } from 'src/meta/evaluation/EvaluationMeta';

export const EvaluacionInicialMeta = {
  key: 'evaluacion/',
  title: 'Evaluación Inicial',
  icon: 'travel_explore'
};

export const InitialEvaluationMetaTitleClasses =
  'text-weight-extrabold q-pb-md text-secondary title';

/**
 * Using a poor man dictionary comprehension we prepend to every key `/evaluacion`
 */
export const EvaluationRoutesLocal: typeof EvaluationRoutes =
  Object.fromEntries(
    Object.entries(EvaluationRoutes).map(([key, route]) => {
      return [
        key,
        {
          ...route,
          key: EvaluacionInicialMeta.key + route.key
        }
      ];
    })
  );

export const InitialEvaluationRoutesUnordered: Record<string, BasicRouteInfo> =
  {
    ...EvaluationRoutesLocal,
    WELCOME_EVALUATION: {
      key: 'evaluacion/bienvenido-a-la-evaluacion',
      icon: '',
      index: 1,
      title: 'Bienvenido a la Evaluación'
    },
    PERSONAL_INFO: {
      key: 'evaluacion/personal',
      icon: 'manage_accounts',
      index: 0.1,
      title: 'Información Personal'
    },
    GENERAL_INFO: {
      key: 'evaluacion/datos-generales',
      icon: 'list',
      index: 1.1,
      title: 'Datos Generales'
    },
    END_EVALUATION: {
      key: 'fin-evaluacion',
      icon: 'list',
      index: 20,
      title: 'Fin Evaluación'
    },
    WELCOME_PACIENTS: {
      key: 'bienvenida/adolescentes',
      icon: '',
      index: 20.1,
      title: 'Bienvenida Adolescentes'
    },
    PROGRAM_ASSIGNATION: {
      key: 'evaluacion/asignacion-programa',
      icon: '',
      index: 21.1,
      title: 'Asignación de Programa'
    },
    INTRODUCTION_PIBA: {
      key: 'piba/introduccion',
      icon: '',
      index: 21.2,
      title: 'Introducción PIBA'
    },
    DASHBOARD: { key: 'dashboard', icon: '', index: 22, title: 'Dashboard' }
  };

export const InitialEvaluationRoutes = Object.fromEntries(
  Object.entries(InitialEvaluationRoutesUnordered).sort(
    ([, route], [, route2]) => {
      return route.index - route2.index;
    }
  )
);

export const InitialEvaluationURLs = (
  Object.keys(InitialEvaluationRoutes) as Array<
    keyof typeof InitialEvaluationRoutes
  >
).map((key) => `/${InitialEvaluationRoutes[key].key}`);

export const RelativeDatesOptions = [
  'hace 1 dia',
  'hace 3 dias',
  'hace 5 dias',
  'hace 1 semana',
  'hace 2 semanas',
  'hace 3 semanas',
  'hace 1 mes',
  'hace 3 meses',
  'hace 6 meses',
  'hace 9 meses',
  'hace 1 año'
];
