import {
  AxiosError,
  AxiosResponse,
  api,
  logAxiosErrors,
  removeAuthHeaders,
  updateAuthHeaders,
} from 'src/boot/axios';
import {
  superloginAuthResponse,
  superloginRegisterErrors,
} from 'src/types/sl-nx/auth';

import { Notify } from 'quasar';
import PouchDB from 'pouchdb';
import { PouchDBFailedLoginError } from 'src/types/pouchdb/pouchtypes';
import { RegisterState } from 'src/types/auth/register';
import { UserAuth } from 'src/types/user/main';
import { defineStore } from 'pinia';
import { getAttachment } from 'src/components/Attachments';
import to from 'await-to-js';
import { useDbStore } from 'stores/db';

function notifyLoginFail(message = 'Ocurrio un error iniciando sesión') {
  Notify.create({ type: 'negative', message });
}
function notifyLogin(message: string) {
  Notify.create({ type: 'positive', message });
}

export const useUserStore = defineStore('user', {
  state: () => ({
    counter: 0,
    auth: undefined as superloginAuthResponse | undefined,
    profilePicture: null as Blob | Buffer | null,
    profilePictureURL: '/placeholder-1.png',
  }),

  getters: {
    doubleCount(state) {
      return state.counter * 2;
    },
    isAuthenticated(state) {
      return !!(state.auth && state.auth.expires - Date.now() > 0);
    },
    isTerapeuta(state) {
      return !!(state.auth && state.auth.roles.includes('terapeuta'));
    },
    isTerapeutaActive(state) {
      // return true;
      return !!(state.auth && state.auth.roles.includes('terapeutaActive'));
    },
    isAdministrador(state) {
      // return true;
      return !!(state.auth && state.auth.roles.includes('administrador'));
    },
    isAdministradorInstitucion(state) {
      return !!(
        state.auth &&
        state.auth.roles.filter((role) => role.includes('admin:')).length > 0
      );
    },

    administradorInstitucionKey(state) {
      return state.auth?.roles
        .find((role) => role.includes('admin:'))
        ?.replace('admin:', '');
    },
    isPaciente(state) {
      return !!(state.auth && state.auth.roles.includes('paciente'));
    },
    personalDbName(state) {
      const userDBArr = state.auth?.userDBs.piba.split('/');
      if (userDBArr && userDBArr.length > 0) {
        return userDBArr[userDBArr?.length - 1];
      }
      return '';
    },
  },

  actions: {
    increment() {
      this.counter++;
    },
    async logout() {
      // this.setPrograma(undefined);
      if (this.isAuthenticated) {
        await api.post('/auth/logout');
        const dbStore = useDbStore();
        // closeDatabases();
        this.auth = undefined;
        removeAuthHeaders();
        dbStore.closeDatabases();
        this.profilePicture = null;
        this.profilePictureURL = '/placeholder-1.png';
      }
    },
    async login(userAuth: UserAuth) {
      const [err, auth] = await to<
        AxiosResponse<superloginAuthResponse>,
        AxiosError<{ message: string }>
      >(
        api.post('/auth/login', {
          ...userAuth,
          email: userAuth.email.toLocaleLowerCase(),
        })
      );
      if (err) {
        logAxiosErrors(err);
        if (err.response && [401, 400].includes(err.response.status)) {
          console.log(err.response.data.message);
          if (
            err.response.data.message === 'You must confirm your email address.'
          ) {
            notifyLoginFail('Debes confirmar tu correo electrónico');
            return;
          }
          if (err.response.data.message === 'Invalid username or password') {
            notifyLoginFail(
              'El correo electrónico o la contraseña son incorrectos'
            );
            return;
          }
          throw err;
        }
        notifyLoginFail();
        throw err;
      }
      if (auth == null) {
        notifyLoginFail();
        throw new PouchDBFailedLoginError(
          'No auth information returned from server.'
        );
      }
      // this.auth = auth.data;
      // eslint-disable-next-line prefer-object-spread
      // this.auth = Object.assign({}, this.auth, auth.data);
      // Vue.set(state, 'auth', auth.data);
      this.auth = auth.data;
      updateAuthHeaders(auth.data);
      // await this.syncDB(auth.data).catch(console.log.bind(console));
      await this.loginCouch();
    },
    async authenticatedProvider({
      auth,
      accountType,
    }: {
      auth: superloginAuthResponse;
      accountType: 'paciente' | 'terapeuta';
    }) {
      updateAuthHeaders(auth);
      // debugger;
      if (
        accountType === 'terapeuta' &&
        !auth.roles.includes('terapeuta') &&
        !auth.roles.includes('paciente')
      ) {
        // Update the roles in the vuex state
        auth.roles.push('terapeuta');
        const [errRegisterTer] = await to(
          api.post('/terapeutas/register/socialLogin')
        );
        if (errRegisterTer) {
          console.error(errRegisterTer);
        }
        this.auth = auth;
      } else if (
        accountType === 'paciente' &&
        !auth.roles.includes('terapeuta') &&
        !auth.roles.includes('paciente')
      ) {
        auth.roles.push('paciente');
        const [errRegisterPac] = await to(
          api.post('/pacients/register/socialLogin')
        );
        if (errRegisterPac) {
          console.error(errRegisterPac);
        }
        this.auth = auth;
      }
      // await this.syncDB(auth);
      console.log('Auth provider', auth, accountType);
    },
    async register(userInfo: UserAuth): Promise<RegisterState> {
      const [err, auth] = await to<
        AxiosResponse<superloginAuthResponse | { success: string }>,
        AxiosError<superloginRegisterErrors>
      >(api.post('/auth/register', userInfo));
      if (err) {
        logAxiosErrors(err);
        if (err.response && [401, 400].includes(err.response.status)) {
          if (err.response.data.error === 'Validation failed') {
            if (
              err.response.data.validationErrors.email?.includes(
                'Email invalid email'
              )
            ) {
              notifyLoginFail('El correo electrónico es invalido.');
              return RegisterState.FAIL;
            }
            if (
              err.response.data.validationErrors.email?.includes(
                'Email already in use'
              )
            ) {
              notifyLoginFail(
                'El correo electrónico ya esta en uso, intenta inciar sesión.'
              );
              return RegisterState.FAIL;
            }
            if (
              err.response.data.validationErrors.password?.includes(
                'Password does not match confirmPassword'
              )
            ) {
              notifyLoginFail('Las contraseñas no son iguales.');
              return RegisterState.FAIL;
            }
            if (
              err.response.data.validationErrors.password?.includes(
                'Password must be at least 8 characters'
              )
            ) {
              notifyLoginFail('La contraseña debe tener mas de 8 caracteres.');
              return RegisterState.FAIL;
            }
          }
        }
        notifyLoginFail('Ocurrio un error registrando tu cuenta.');
        return RegisterState.FAIL;
      }
      console.log('auth', auth);
      if (auth && 'success' in auth.data) {
        if (auth.data.success === 'Request processed.') {
          notifyLogin(
            'Tu cuenta ha sido creada, revisa tu correo para confirmarla.'
          );
          return RegisterState.PROCEED_TO_LOGIN;
        }
        notifyLoginFail('Ocurrio un error registrando tu cuenta.');
        throw new PouchDBFailedLoginError(
          'No auth information returned from server.'
        );
      }
      if (auth && 'userDBs' in auth.data) {
        this.auth = auth.data;
        // const [errSync] = await to(getPersonalDB());
        // if (errSync) {
        //   console.error(errSync);
        //   notifyLoginFail('Ocurrio un error sincronizando con el servidor');
        //   throw err;
        // }
        updateAuthHeaders(this.auth);
        await this.loginCouch();
        return RegisterState.OK;
      }
      return RegisterState.FAIL;
    },
    async loginCouch() {
      // return;
      try {
        if (!this.auth?.userDBs.piba) {
          throw Error('PIBA URL not found');
        }
        // Trying to use `api` resulted in a "400 Malformed Request" I suspect it was because
        // `api` has the headers `Authorization: Bearer ...` set and couchdb is trying to parse them
        // But the format of couch-auth is incompatible to the couchdb uses.
        await PouchDB.fetch(
          `${process.env.VUE_APP_COUCHDB_PROTOCOL}${process.env.VUE_APP_COUCHDB_ADDRESS}_session`,
          {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify({
              username: this.auth.token,
              password: this.auth.password,
            }),
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
            },
          }
        );
        // const request = await axios.post(
        //   `${process.env.VUE_APP_COUCHDB_PROTOCOL}${process.env.VUE_APP_COUCHDB_ADDRESS}_session`,
        // {
        //   name: this.auth.token,
        //   password: this.auth.password
        // },
        //   { withCredentials: true, validateStatus: (status) => status === 200 }
        // );
      } catch (e) {
        notifyLoginFail('No se pudo conectar a la base de datos');
        throw e;
      }
    },
    shouldRefreshSession() {
      if (this.auth && this.isAuthenticated) {
        // this.loginCouch().catch(console.error.bind(console));
        const secondsInOneHour = 60 * 60;
        const FourWeeksMinusOneHourSeconds =
          secondsInOneHour * 24 * 7 * 4 - secondsInOneHour;
        const currentSessionRemainingDurationSeconds =
          this.auth.expires - Date.now();
        if (
          FourWeeksMinusOneHourSeconds < currentSessionRemainingDurationSeconds
        ) {
          // Refresh the token session as soon as one hour has passed
          // The back is configured with a sessionLive of four weeks
          // So when the remaning session duration is three weeks, 6 days and 23 hours (or four weeks minus one hour)
          // We refresh the session.
          // This is to make the session last the whole time the pacient is in the process
          // without ever asking them to login again
          // api
          //   .post<superloginAuthResponse>('/auth/refresh')
          //   .then((response) => {
          //     this.auth = { ...this.auth, ...response.data };
          //   })
          //   .catch(console.error.bind(console));
        }
      }
    },
    async getProfilePicture() {
      try {
        const dbStore = useDbStore();
        const db = await dbStore.getPersonalDB();
        const docId = this.isTerapeuta
          ? '/terapeuta/registro'
          : '/evaluacion/personal';
        const { url, buffer } = await getAttachment({
          db,
          docId,
          attachmentName: 'profile-pics@profile_picture',
        });
        if (buffer != null) {
          this.profilePicture = buffer;
        }
        this.profilePictureURL = url;
      } catch (e) {
        console.error('Error getting the profile picture', e);
      }
    },
  },
  persist: true,
});
