import { RouteLocationNormalized, RouteRecordRaw } from 'vue-router';

import { Breadcrumb } from 'src/types/breadcrumb';
import { EvaluationRoutesExplanationsFullscreen } from './EvaluationRoutes';
import { EvaluationRoutesForms } from 'src/router/EvaluationRoutes';
import { FollowUpRoutesExplanationsFullscreen } from './FollowUpRoutes';
import { FollowUpSixMonthsMeta } from 'src/meta/follow-up/six-months';

const FollowUpSixMonthsBreadcrumb: Breadcrumb = {
  label: FollowUpSixMonthsMeta.title,
  icon: FollowUpSixMonthsMeta.icon
};

export const FollowUpSixMonthsRoutesForms: RouteRecordRaw = {
  path: '/',
  component: () => import('layouts/PacienteLayout.vue'),
  children: [
    ...(EvaluationRoutesForms.children?.map((routeRecord) => {
      const newProps = (route: RouteLocationNormalized) => ({
        // @ts-expect-error We know better than Typescript and know those routes will have the required properties
        ...routeRecord.props,
        pacientId: route.params.pacientId,
        flow: 'follow-up-6',
        breadcrumbs: [FollowUpSixMonthsBreadcrumb],
        // @ts-expect-error We know better than Typescript and know those routes will have the required properties
        stateURL: FollowUpSixMonthsMeta.key + routeRecord.props.stateURL
      });
      return {
        ...routeRecord,
        path: FollowUpSixMonthsMeta.key + routeRecord.path,
        props: newProps
      } as RouteRecordRaw;
    }) ?? [])
  ],
  meta: {
    requiresAuth: true,
    // requiresInitialEvaluation: true,
    onlyForPaciente: true
  }
};

export const FollowUpSixMonthsRoutesExplanationsFullscreen: RouteRecordRaw = {
  path: '/',
  component: () => import('src/layouts/LayoutFullScreen.vue'),
  children: [
    ...(EvaluationRoutesExplanationsFullscreen.children?.map((routeRecord) => {
      const newProps = (route: RouteLocationNormalized) => ({
        // @ts-expect-error We know better than Typescript and know those routes will have the required properties
        ...routeRecord.props,
        pacientId: route.params.pacientId,
        flow: 'follow-up-6',
        breadcrumbs: [FollowUpSixMonthsBreadcrumb],
        // @ts-expect-error We know better than Typescript and know those routes will have the required properties
        stateURL: FollowUpSixMonthsMeta.key + routeRecord.props.stateURL
      });
      return {
        ...routeRecord,
        path: FollowUpSixMonthsMeta.key + routeRecord.path,
        props: newProps
      } as RouteRecordRaw;
    }) ?? []),
    ...(FollowUpRoutesExplanationsFullscreen.children?.map((routeRecord) => {
      const newProps = (route: RouteLocationNormalized) => ({
        // @ts-expect-error We know better than Typescript and know those routes will have the required properties
        ...routeRecord.props,
        pacientId: route.params.pacientId,
        flow: 'follow-up-6',
        breadcrumbs: [FollowUpSixMonthsBreadcrumb],
        // @ts-expect-error We know better than Typescript and know those routes will have the required properties
        stateURL: FollowUpSixMonthsMeta.key + routeRecord.props.stateURL
      });

      return {
        ...routeRecord,
        path: FollowUpSixMonthsMeta.key + routeRecord.path,
        props: newProps
      } as RouteRecordRaw;
    }) ?? [])
  ]
};

const routes: RouteRecordRaw[] = [
  FollowUpSixMonthsRoutesForms,
  FollowUpSixMonthsRoutesExplanationsFullscreen
];
export default routes;
