import { ConsumoDeDrogasData } from 'src/types/initial-evaluation/evaluacion-personal';
import { FIELDS } from 'src/meta/initial-evaluation/ConsumoDeDrogasMeta';
import { Icontext } from 'src/types/blitzar';
import { InitialEvaluationMetaTitleClasses } from 'src/meta/initial-evaluation/InitialEvaluationMeta';

type ctx = Icontext<Partial<ConsumoDeDrogasData>>;

const considerasEscala = [
  'Es un pequeño problema',
  'Es un problema menor',
  'Es un problema mayor',
  'Es un gran problema'
];
export const ConsumoDeDrogasSchema = [
  {
    component: 'p',
    slot: FIELDS.title.label,
    componentClasses: InitialEvaluationMetaTitleClasses
  },
  {
    id: FIELDS['cual-considera-que-es-tu-principal-sustancia'].key,
    label: FIELDS['cual-considera-que-es-tu-principal-sustancia'].label,
    required: FIELDS['cual-considera-que-es-tu-principal-sustancia'].required,
    subLabel: FIELDS['cual-considera-que-es-tu-principal-sustancia'].subLabel,
    options: Object.values(
      FIELDS['cual-considera-que-es-tu-principal-sustancia'].options
    ),
    component: 'QOptionWrapper',
    type: 'radio'
  },
  {
    id: FIELDS['cuales-sustancias-has-probado'].key,
    label: FIELDS['cuales-sustancias-has-probado'].label,
    required: FIELDS['cuales-sustancias-has-probado'].required,
    options: Object.values(FIELDS['cuales-sustancias-has-probado'].options),
    component: 'QOptionWrapper',
    type: 'checkbox',
    defaultValue: () => []
  },
  {
    id: FIELDS['bebida-de-mayor-frecuencia'].key,
    label: FIELDS['bebida-de-mayor-frecuencia'].label,
    subLabel: FIELDS['bebida-de-mayor-frecuencia'].subLabel,
    options: Object.values(FIELDS['bebida-de-mayor-frecuencia'].options),
    component: 'QOptionWrapper',
    type: 'radio',
    dynamicProps: ['showCondition', 'required'],
    required: (val: string, { formData }: ctx) =>
      formData['cual-considera-que-es-tu-principal-sustancia'] === 'alcohol' ||
      formData['cuales-sustancias-has-probado']?.includes('alcohol'),
    showCondition: (val: string, { formData }: ctx) =>
      formData['cual-considera-que-es-tu-principal-sustancia'] === 'alcohol' ||
      formData['cuales-sustancias-has-probado']?.includes('alcohol')
  },
  {
    id: FIELDS['como-describirias-tu-consumo'].key,
    label: FIELDS['como-describirias-tu-consumo'].label,
    required: FIELDS['como-describirias-tu-consumo'].required,
    options: Object.values(FIELDS['como-describirias-tu-consumo'].options),
    component: 'QOptionWrapper',
    type: 'radio'
  },
  {
    id: FIELDS['has-recibido-algun-tratamiento'].key,
    label: FIELDS['has-recibido-algun-tratamiento'].label,
    required: FIELDS['has-recibido-algun-tratamiento'].required,
    options: Object.values(FIELDS['has-recibido-algun-tratamiento'].options),
    component: 'QOptionWrapper',
    type: 'radio'
  },
  {
    id: FIELDS['tipo-tratamiento-que-recibiste'].key,
    label: FIELDS['tipo-tratamiento-que-recibiste'].label,
    options: Object.values(FIELDS['tipo-tratamiento-que-recibiste'].options),
    component: 'QOptionWrapper',
    type: 'radio',
    dynamicProps: ['showCondition', 'required'],
    required: (val: string, { formData }: ctx) =>
      formData['has-recibido-algun-tratamiento'] === 'si',
    showCondition: (val: string, { formData }: ctx) =>
      formData['has-recibido-algun-tratamiento'] === 'si'
  },
  {
    id: FIELDS['fecha-del-tratamiento'].key,
    label: FIELDS['fecha-del-tratamiento'].label,
    options: FIELDS['fecha-del-tratamiento'].options,
    component: 'SelectOrComponent',
    otherComponent: 'q-input',
    defaultValueForOther: '',
    type: 'date',
    dynamicProps: ['showCondition', 'required'],
    required: (val: string, { formData }: ctx) =>
      formData['has-recibido-algun-tratamiento'] === 'si',
    showCondition: (val: string, { formData }: ctx) =>
      formData['has-recibido-algun-tratamiento'] === 'si'
  },
  {
    id: FIELDS['problema-tratado'].key,
    label: FIELDS['problema-tratado'].label,
    options: Object.values(FIELDS['problema-tratado'].options),
    component: 'QOptionWrapper',
    type: 'radio',
    dynamicProps: ['showCondition', 'required'],
    required: (val: string, { formData }: ctx) =>
      formData['has-recibido-algun-tratamiento'] === 'si',
    showCondition: (val: string, { formData }: ctx) =>
      formData['has-recibido-algun-tratamiento'] === 'si'
  },
  {
    id: FIELDS['mayor-periodo-de-abstinencia'].key,
    label: FIELDS['mayor-periodo-de-abstinencia'].label,
    subLabel: FIELDS['mayor-periodo-de-abstinencia'].subLabel,
    component: 'q-input',
    type: 'number',
    min: 0,
    rules: [
      (val: number) => val >= 0 || 'La abstinencia no puede ser negativa'
    ],
    defaultValue: 0,
    dynamicProps: ['showCondition', 'required'],
    required: (val: string, { formData }: ctx) =>
      considerasEscala.includes(formData['como-describirias-tu-consumo'] ?? ''),
    showCondition: (val: string, { formData }: ctx) =>
      considerasEscala.includes(formData['como-describirias-tu-consumo'] ?? '')
  },
  {
    id: FIELDS['fecha-del-periodo-de-abstinencia'].key,
    label: FIELDS['fecha-del-periodo-de-abstinencia'].label,
    options: FIELDS['fecha-del-periodo-de-abstinencia'].options,
    component: 'SelectOrComponent',
    otherComponent: 'q-input',
    defaultValueForOther: '',
    type: 'date',
    dynamicProps: ['showCondition', 'required'],
    required: (val: string, { formData }: ctx) =>
      (formData['mayor-periodo-de-abstinencia'] ?? 0) > 0 &&
      considerasEscala.includes(formData['como-describirias-tu-consumo'] ?? ''),
    showCondition: (val: string, { formData }: ctx) =>
      (formData['mayor-periodo-de-abstinencia'] ?? 0) > 0 &&
      considerasEscala.includes(formData['como-describirias-tu-consumo'] ?? '')
  },
  {
    id: FIELDS['razon-periodo-de-abstinencia'].key,
    label: FIELDS['razon-periodo-de-abstinencia'].label,
    component: 'q-input',
    dynamicProps: ['showCondition', 'required'],
    required: (val: string, { formData }: ctx) =>
      (formData['mayor-periodo-de-abstinencia'] ?? 0) > 0 &&
      considerasEscala.includes(formData['como-describirias-tu-consumo'] ?? ''),
    showCondition: (val: string, { formData }: ctx) =>
      (formData['mayor-periodo-de-abstinencia'] ?? 0) > 0 &&
      considerasEscala.includes(formData['como-describirias-tu-consumo'] ?? '')
  },
  {
    id: FIELDS['fumas'].key,
    label: FIELDS['fumas'].label,
    required: FIELDS['fumas'].required,
    options: Object.values(FIELDS['fumas'].options),
    component: 'QOptionWrapper',
    type: 'radio'
  },
  {
    id: FIELDS['a-edad-de-iniciaste-a-fumar'].key,
    label: FIELDS['a-edad-de-iniciaste-a-fumar'].label,
    component: 'q-input',
    type: 'number',
    min: 1,
    max: 150,
    dynamicProps: ['showCondition', 'required'],
    required: (val: string, { formData }: ctx) => formData.fumas !== 'Nunca',
    showCondition: (val: string, { formData }: ctx) =>
      formData.fumas !== 'Nunca'
  },
  {
    id: FIELDS['fecha-desde-que-fumas'].key,
    label: FIELDS['fecha-desde-que-fumas'].label,
    options: FIELDS['fecha-desde-que-fumas'].options,
    component: 'SelectOrComponent',
    otherComponent: 'q-input',
    defaultValueForOther: '',
    type: 'date',
    dynamicProps: ['showCondition', 'required'],
    required: (val: string, { formData }: ctx) =>
      formData.fumas === 'Actualmente',
    showCondition: (val: string, { formData }: ctx) =>
      formData.fumas === 'Actualmente'
  },
  {
    id: FIELDS['promedio-de-cigarros-diarios'].key,
    label: FIELDS['promedio-de-cigarros-diarios'].label,
    component: 'q-input',
    type: 'number',
    min: 0,
    dynamicProps: ['showCondition', 'required'],
    required: (val: string, { formData }: ctx) =>
      formData.fumas === 'Actualmente',
    showCondition: (val: string, { formData }: ctx) =>
      formData.fumas === 'Actualmente'
  },
  {
    id: FIELDS['tus-conocidos-fuman'].key,
    label: FIELDS['tus-conocidos-fuman'].label,
    required: FIELDS['tus-conocidos-fuman'].required,
    options: Object.values(FIELDS['tus-conocidos-fuman'].options),
    component: 'QOptionWrapper',
    type: 'radio'
  }
];
