import { DatosGeneralesData } from 'src/types/initial-evaluation/evaluacion-personal';
import { FIELDS } from 'src/meta/initial-evaluation/DatosGeneralesMeta';
import { Icontext } from 'src/types/blitzar';
import { InitialEvaluationMetaTitleClasses } from 'src/meta/initial-evaluation/InitialEvaluationMeta';

type ctx = Icontext<Partial<DatosGeneralesData>>;
export const DatosGeneralesSchema = [
  {
    component: 'p',
    slot: FIELDS.title.label,
    componentClasses: InitialEvaluationMetaTitleClasses
  },
  {
    id: FIELDS['con-quien-vives'].key,
    label: FIELDS['con-quien-vives'].label,
    required: FIELDS['con-quien-vives'].required,
    options: Object.values(FIELDS['con-quien-vives'].options),
    component: 'QOptionWrapper',
    type: 'checkbox',
    defaultValue: () => [],
    parseValue: (val: string | string[]) => (Array.isArray(val) ? val : [val])
  },
  {
    id: FIELDS['con-quien-vives-otro'].key,
    component: 'q-input',
    dynamicProps: ['showCondition', 'required'],
    fieldClasses: 'reactive-field',
    required: (val: string, { formData }: ctx) =>
      formData['con-quien-vives']?.includes('otros'),
    showCondition: (val: string, { formData }: ctx) =>
      formData['con-quien-vives']?.includes('otros')
  },
  {
    id: FIELDS['tienes-papa'].key,
    label: FIELDS['tienes-papa'].label,
    required: FIELDS['tienes-papa'].required,
    options: Object.values(FIELDS['tienes-papa'].options),
    span: 1,
    component: 'QOptionWrapper',
    type: 'radio'
  },
  {
    id: FIELDS['tienes-papa-otro'].key,
    label: FIELDS['tienes-papa-otro'].label,
    component: 'q-input',
    dynamicProps: ['showCondition', 'required'],
    fieldClasses: 'reactive-field',
    required: (val: string, { formData }: ctx) =>
      formData['tienes-papa'] === 'otro',
    showCondition: (val: string, { formData }: ctx) =>
      formData['tienes-papa'] === 'otro'
  },
  {
    id: FIELDS['edad-papa'].key,
    label: FIELDS['edad-papa'].label,
    component: 'q-input',
    subLabel: FIELDS['edad-papa'].subLabel,
    type: 'number',
    min: 0,
    rules: [
      (val?: number) => !val || val > -1 || 'La cantidad debe ser valida'
    ],
    dynamicProps: ['showCondition', 'required'],
    required: (val: string, { formData }: ctx) =>
      formData['tienes-papa'] && formData['tienes-papa'] !== 'no',
    showCondition: (val: string, { formData }: ctx) =>
      formData['tienes-papa'] && formData['tienes-papa'] !== 'no'
  },
  {
    id: FIELDS['estudios-papa'].key,
    label: FIELDS['estudios-papa'].label,
    options: Object.values(FIELDS['estudios-papa'].options),
    subLabel: FIELDS['estudios-papa'].subLabel,
    component: 'QOptionWrapper',
    type: 'radio',
    dynamicProps: ['showCondition', 'required'],
    required: (val: string, { formData }: ctx) =>
      formData['tienes-papa'] && formData['tienes-papa'] !== 'no',
    showCondition: (val: string, { formData }: ctx) =>
      formData['tienes-papa'] && formData['tienes-papa'] !== 'no'
  },
  {
    id: FIELDS['a-que-se-dedica-tu-papa'].key,
    label: FIELDS['a-que-se-dedica-tu-papa'].label,
    component: 'q-input',
    subLabel: FIELDS['a-que-se-dedica-tu-papa'].subLabel,
    dynamicProps: ['showCondition', 'required'],
    required: (val: string, { formData }: ctx) =>
      formData['tienes-papa'] && formData['tienes-papa'] !== 'no',
    showCondition: (val: string, { formData }: ctx) =>
      formData['tienes-papa'] && formData['tienes-papa'] !== 'no'
  },
  {
    id: FIELDS['tienes-mama'].key,
    label: FIELDS['tienes-mama'].label,
    required: FIELDS['tienes-mama'].required,
    options: Object.values(FIELDS['tienes-mama'].options),
    component: 'QOptionWrapper',
    type: 'radio'
  },
  {
    id: FIELDS['tienes-mama-otro'].key,
    label: FIELDS['tienes-mama-otro'].label,
    component: 'q-input',
    fieldClasses: 'reactive-field',
    dynamicProps: ['showCondition', 'required'],
    required: (val: string, { formData }: ctx) =>
      formData['tienes-mama'] === 'otro',
    showCondition: (val: string, { formData }: ctx) =>
      formData['tienes-mama'] === 'otro'
  },
  {
    id: FIELDS['edad-mama'].key,
    label: FIELDS['edad-mama'].label,
    subLabel: FIELDS['edad-mama'].subLabel,
    component: 'q-input',
    type: 'number',
    min: 0,
    rules: [
      (val?: number) => !val || val > -1 || 'La cantidad debe ser valida'
    ],
    dynamicProps: ['showCondition', 'required'],
    required: (val: string, { formData }: ctx) =>
      formData['tienes-mama'] && formData['tienes-mama'] !== 'no',
    showCondition: (val: string, { formData }: ctx) =>
      formData['tienes-mama'] && formData['tienes-mama'] !== 'no'
  },
  {
    id: FIELDS['estudios-mama'].key,
    label: FIELDS['estudios-mama'].label,
    subLabel: FIELDS['estudios-mama'].subLabel,
    options: Object.values(FIELDS['estudios-mama'].options),
    span: 1,
    component: 'QOptionWrapper',
    type: 'radio',
    dynamicProps: ['showCondition', 'required'],
    required: (val: string, { formData }: ctx) =>
      formData['tienes-mama'] && formData['tienes-mama'] !== 'no',
    showCondition: (val: string, { formData }: ctx) =>
      formData['tienes-mama'] && formData['tienes-mama'] !== 'no'
  },
  {
    id: FIELDS['a-que-se-dedica-tu-mama'].key,
    label: FIELDS['a-que-se-dedica-tu-mama'].label,
    subLabel: FIELDS['a-que-se-dedica-tu-mama'].subLabel,
    component: 'q-input',
    dynamicProps: ['showCondition', 'required'],
    required: (val: string, { formData }: ctx) =>
      formData['tienes-mama'] && formData['tienes-mama'] !== 'no',
    showCondition: (val: string, { formData }: ctx) =>
      formData['tienes-mama'] && formData['tienes-mama'] !== 'no'
  },
  {
    id: FIELDS['cantidad-de-hermanos'].key,
    label: FIELDS['cantidad-de-hermanos'].label,
    required: FIELDS['cantidad-de-hermanos'].required,
    component: 'q-input',
    type: 'number',
    min: 0,
    rules: [(val?: number) => !val || val > -1 || 'La cantidad debe ser valida']
  },
  {
    id: FIELDS['cantidad-de-hermanas'].key,
    label: FIELDS['cantidad-de-hermanas'].label,
    required: FIELDS['cantidad-de-hermanas'].required,
    component: 'q-input',
    type: 'number',
    min: 0,
    rules: [(val?: number) => !val || val > -1 || 'La cantidad debe ser valida']
  },
  {
    id: FIELDS['que-lugar-ocupas-entre-tus-hermanos'].key,
    label: FIELDS['que-lugar-ocupas-entre-tus-hermanos'].label,
    required: FIELDS['que-lugar-ocupas-entre-tus-hermanos'].required,
    component: 'q-input',
    type: 'number',
    min: 0,
    rules: [(val?: number) => !val || val > -1 || 'La cantidad debe ser valida']
  },
  {
    id: FIELDS['cuanto-crees-que-ganan-los-adultos-de-tu-familia'].key,
    label: FIELDS['cuanto-crees-que-ganan-los-adultos-de-tu-familia'].label,
    required:
      FIELDS['cuanto-crees-que-ganan-los-adultos-de-tu-familia'].required,
    options: Object.values(
      FIELDS['cuanto-crees-que-ganan-los-adultos-de-tu-familia'].options
    ),
    component: 'QOptionWrapper',
    type: 'radio'
  }
];
