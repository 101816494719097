import { AdministracionTiempoLibreSchema } from 'src/components/evaluation/AdministracionTiempoLibreForm';
import { AreaConductaAntisocialSchema } from 'src/components/evaluation/AreaConductaAntisocialForm';
import { AreaEscolarLaboralSchema } from 'src/components/evaluation/AreaEscolarLaboralForm';
import { AreaFamiliarSchema } from 'src/components/evaluation/AreaFamiliarForm';
import { AreaSaludSchema } from 'src/components/evaluation/AreaSaludForm';
import { AreaSexualidadSchema } from 'src/components/evaluation/AreaSexualidadForm';
// import { Breadcrumb } from 'src/types/breadcrumb';
import { ConfianzaSitSchema } from 'src/components/confianza-situacional/ConfianzaSituacionalForm';
import { ConsumoDeDrogasAlcoholSchema } from 'src/components/evaluation/HistoriaDeAlcoholForm';
import { ConsumoDeDrogasSchema } from 'src/components/evaluation/ConsumoDeDrogasForm';
import { DepresionRiesgoSuicidaSchema } from 'src/components/evaluation/DepresionRiesgoSuicidaForm';
// import { EvaluacionInicialMeta } from 'src/meta/initial-evaluation/InitialEvaluationMeta';
import { EvaluationRoutes } from 'src/meta/evaluation/EvaluationMeta';
import { HistoriaDeDrogasSchema } from 'src/components/evaluation/HistoriaDeDrogasForm';
import { IndicadoresDepSchema } from 'src/components/indicadores-dependencia/IndicadoresDependenciaForm';
import { PositSchemaColumns } from 'src/components/evaluation/PositForm';
import { RouteRecordRaw } from 'vue-router';
import { SatisfaccionPersonalSchema } from 'src/components/evaluation/SatisfaccionPersonalForm';
import { actionButtonDefaults } from 'components/blitzForm/actionButtonDefaults';
import { defineAsyncComponent } from 'vue';
// import { TerapeutaPersonalInfoFormSchema } from 'src/components/terapeuta-initial-registration/TerapeutaPersonalInfoForm';
// import { divideSchema } from 'src/scripts/divideSchema';

// divideSchema(ConfianzaSitSchema);

// const InitialInterviewBreadcrumb: Breadcrumb = {
//   label: EvaluacionInicialMeta.title,
//   icon: EvaluacionInicialMeta.icon
// };

export const EvaluationRoutesForms: RouteRecordRaw = {
  path: '/',
  component: () => import('layouts/PacienteLayout.vue'),
  children: [
    {
      path: EvaluationRoutes.POSIT.key,
      component: () => import('pages/evaluation/GenericWrapperForm.vue'),
      props: {
        stateURL: EvaluationRoutes.POSIT.key,
        mode: 'edit',
        schema: PositSchemaColumns,
        actionButtonDefaults: actionButtonDefaults,
        actionButtons: () => ['save']
        // pacientId: route.params.pacientId,
        // breadcrumbs: [InitialInterviewBreadcrumb]
      },
      meta: {
        title: EvaluationRoutes.POSIT.title,
        titleInBreadcrumb: true,
        titleIcon: EvaluationRoutes.POSIT.icon
      }
    },
    {
      path: EvaluationRoutes.AREA_SCHOOL_WORK.key,
      component: () => import('pages/evaluation/GenericWrapperForm.vue'),
      props: {
        stateURL: EvaluationRoutes.AREA_SCHOOL_WORK.key,
        mode: 'edit',
        schema: AreaEscolarLaboralSchema,
        actionButtonDefaults: actionButtonDefaults,
        actionButtons: () => ['save']
        // pacientId: route.params.pacientId,
        // breadcrumbs: [InitialInterviewBreadcrumb]
      },
      meta: {
        title: EvaluationRoutes.AREA_SCHOOL_WORK.title,
        titleInBreadcrumb: true,
        titleIcon: EvaluationRoutes.AREA_SCHOOL_WORK.icon
      }
    },
    {
      path: EvaluationRoutes.DRUG_CONSUMPTION.key,
      component: () => import('pages/evaluation/GenericWrapperForm.vue'),
      props: {
        stateURL: EvaluationRoutes.DRUG_CONSUMPTION.key,
        mode: 'edit',
        schema: ConsumoDeDrogasSchema,
        actionButtonDefaults: actionButtonDefaults,
        actionButtons: () => ['save']
        // pacientId: route.params.pacientId,
        // breadcrumbs: [InitialInterviewBreadcrumb]
      },
      meta: {
        title: EvaluationRoutes.DRUG_CONSUMPTION.title,
        titleInBreadcrumb: true,
        titleIcon: EvaluationRoutes.DRUG_CONSUMPTION.icon
      }
    },
    {
      path: EvaluationRoutes.ALCOHOL_HISTORY.key,
      component: () => import('pages/evaluation/GenericWrapperForm.vue'),
      props: {
        stateURL: EvaluationRoutes.ALCOHOL_HISTORY.key,
        mode: 'edit',
        schema: ConsumoDeDrogasAlcoholSchema,
        actionButtonDefaults: actionButtonDefaults,
        actionButtons: () => ['save']
        // pacientId: route.params.pacientId,
        // breadcrumbs: [InitialInterviewBreadcrumb]
      },
      meta: {
        title: EvaluationRoutes.ALCOHOL_HISTORY.title,
        titleInBreadcrumb: true,
        titleIcon: EvaluationRoutes.ALCOHOL_HISTORY.icon
      }
    },
    {
      path: EvaluationRoutes.DRUG_HISTORY.key,
      component: () => import('pages/evaluation/GenericWrapperForm.vue'),
      props: {
        stateURL: EvaluationRoutes.DRUG_HISTORY.key,
        mode: 'edit',
        schema: HistoriaDeDrogasSchema,
        actionButtonDefaults: actionButtonDefaults,
        actionButtons: () => ['save']
        // pacientId: route.params.pacientId,
        // breadcrumbs: [InitialInterviewBreadcrumb]
      },
      meta: {
        title: EvaluationRoutes.DRUG_HISTORY.title,
        titleInBreadcrumb: true,
        titleIcon: EvaluationRoutes.DRUG_HISTORY.icon
      }
    },
    {
      path: EvaluationRoutes.FAMILY_INFO.key,
      component: () => import('pages/evaluation/GenericWrapperForm.vue'),
      props: {
        stateURL: EvaluationRoutes.FAMILY_INFO.key,
        mode: 'edit',
        schema: AreaFamiliarSchema,
        actionButtonDefaults: actionButtonDefaults,
        actionButtons: () => ['save']
        // pacientId: route.params.pacientId,
        // breadcrumbs: [InitialInterviewBreadcrumb]
      },
      meta: {
        title: EvaluationRoutes.FAMILY_INFO.title,
        titleInBreadcrumb: true,
        titleIcon: EvaluationRoutes.FAMILY_INFO.icon
      }
    },
    {
      path: EvaluationRoutes.HEALTH_INFO.key,
      component: () => import('pages/evaluation/GenericWrapperForm.vue'),
      props: {
        stateURL: EvaluationRoutes.HEALTH_INFO.key,
        mode: 'edit',
        schema: AreaSaludSchema,
        actionButtonDefaults: actionButtonDefaults,
        actionButtons: () => ['save']
        // pacientId: route.params.pacientId,
        // breadcrumbs: [InitialInterviewBreadcrumb]
      },
      meta: {
        title: EvaluationRoutes.HEALTH_INFO.title,
        titleInBreadcrumb: true,
        titleIcon: EvaluationRoutes.HEALTH_INFO.icon
      }
    },

    {
      path: EvaluationRoutes.DEPRESSION_SUICIDE_RISK.key,
      component: () => import('pages/evaluation/GenericWrapperForm.vue'),
      props: {
        stateURL: EvaluationRoutes.DEPRESSION_SUICIDE_RISK.key,
        mode: 'edit',
        schema: DepresionRiesgoSuicidaSchema,
        actionButtonDefaults: actionButtonDefaults,
        actionButtons: () => ['save']
        // pacientId: route.params.pacientId,
        // breadcrumbs: [InitialInterviewBreadcrumb]
      },
      meta: {
        title: EvaluationRoutes.DEPRESSION_SUICIDE_RISK.title,
        titleInBreadcrumb: true,
        titleIcon: EvaluationRoutes.DEPRESSION_SUICIDE_RISK.icon
      }
    },

    {
      path: EvaluationRoutes.SEXUALITY_AREA.key,
      component: () => import('pages/evaluation/GenericWrapperForm.vue'),
      props: {
        stateURL: EvaluationRoutes.SEXUALITY_AREA.key,
        mode: 'edit',
        schema: AreaSexualidadSchema,
        actionButtonDefaults: actionButtonDefaults,
        actionButtons: () => ['save']
        // pacientId: route.params.pacientId,
        // breadcrumbs: [InitialInterviewBreadcrumb]
      },
      meta: {
        title: EvaluationRoutes.SEXUALITY_AREA.title,
        titleInBreadcrumb: true,
        titleIcon: EvaluationRoutes.SEXUALITY_AREA.icon
      }
    },
    {
      path: EvaluationRoutes.ANTISOCIAL_CONDUCT.key,
      component: () => import('pages/evaluation/GenericWrapperForm.vue'),
      props: {
        stateURL: EvaluationRoutes.ANTISOCIAL_CONDUCT.key,
        mode: 'edit',
        schema: AreaConductaAntisocialSchema,
        actionButtonDefaults: actionButtonDefaults,
        actionButtons: () => ['save']
        // pacientId: route.params.pacientId,
        // breadcrumbs: [InitialInterviewBreadcrumb]
      },
      meta: {
        title: EvaluationRoutes.ANTISOCIAL_CONDUCT.title,
        titleInBreadcrumb: true,
        titleIcon: EvaluationRoutes.ANTISOCIAL_CONDUCT.icon
      }
    },
    {
      path: EvaluationRoutes.FREE_TIME_INFO.key,
      component: () => import('pages/evaluation/GenericWrapperForm.vue'),
      props: {
        stateURL: EvaluationRoutes.FREE_TIME_INFO.key,
        mode: 'edit',
        schema: AdministracionTiempoLibreSchema,
        actionButtonDefaults: actionButtonDefaults,
        actionButtons: () => ['save']
        // pacientId: route.params.pacientId,
        // breadcrumbs: [InitialInterviewBreadcrumb]
      },
      meta: {
        title: EvaluationRoutes.FREE_TIME_INFO.title,
        titleInBreadcrumb: true,
        titleIcon: EvaluationRoutes.FREE_TIME_INFO.icon
      }
    },
    {
      path: EvaluationRoutes.PERSONAL_SATISFACTION.key,
      component: () => import('pages/evaluation/GenericWrapperForm.vue'),
      props: {
        stateURL: EvaluationRoutes.PERSONAL_SATISFACTION.key,
        mode: 'edit',
        schema: SatisfaccionPersonalSchema,
        actionButtonDefaults: actionButtonDefaults,
        actionButtons: () => ['save']
        // pacientId: route.params.pacientId,
        // breadcrumbs: [InitialInterviewBreadcrumb]
      },
      meta: {
        title: EvaluationRoutes.PERSONAL_SATISFACTION.title,
        titleInBreadcrumb: true,
        titleIcon: EvaluationRoutes.PERSONAL_SATISFACTION.icon
      }
    },
    {
      path: EvaluationRoutes.DEPENDENCY_INDICATORS_EVALUATION.key,
      component: () => import('pages/evaluation/GenericWrapperForm.vue'),
      props: {
        stateURL: EvaluationRoutes.DEPENDENCY_INDICATORS_EVALUATION.key,
        mode: 'edit',
        schema: IndicadoresDepSchema,
        actionButtonDefaults: actionButtonDefaults,
        actionButtons: () => ['save']
        // pacientId: route.params.pacientId,
        // breadcrumbs: [InitialInterviewBreadcrumb]
      },
      meta: {
        title: EvaluationRoutes.DEPENDENCY_INDICATORS_EVALUATION.title,
        titleInBreadcrumb: true,
        titleIcon: EvaluationRoutes.DEPENDENCY_INDICATORS_EVALUATION.icon
      }
    },
    {
      path: EvaluationRoutes.SITUATIONAL_CONFIDENCE_EVALUATION.key,
      component: () => import('pages/evaluation/GenericWrapperForm.vue'),
      props: {
        stateURL: EvaluationRoutes.SITUATIONAL_CONFIDENCE_EVALUATION.key,
        mode: 'edit',
        schema: ConfianzaSitSchema,
        actionButtonDefaults: actionButtonDefaults,
        actionButtons: () => ['save']
        // pacientId: route.params.pacientId,
        // breadcrumbs: [InitialInterviewBreadcrumb]
      },
      meta: {
        title: EvaluationRoutes.SITUATIONAL_CONFIDENCE_EVALUATION.title,
        titleInBreadcrumb: true,
        titleIcon: EvaluationRoutes.SITUATIONAL_CONFIDENCE_EVALUATION.icon
      }
    },
    {
      path: EvaluationRoutes.RESULTS.key,
      component: () => import('src/pages/ResultsEvaluation.vue'),
      props: {
        stateURL: EvaluationRoutes.RESULTS.key
        // pacientId: route.params.pacientId,
        // breadcrumbs: [InitialInterviewBreadcrumb]
      },
      meta: {
        title: EvaluationRoutes.RESULTS.title,
        titleInBreadcrumb: true,
        titleIcon: EvaluationRoutes.RESULTS.icon
      }
    },

    // ------------------------
    {
      path: EvaluationRoutes.LIBARE_EVALUATION.key,
      component: () => import('src/pages/libare/LibareInterviewWrapper.vue'),
      props: {
        stateURL: EvaluationRoutes.LIBARE_EVALUATION.key,
        mode: 'edit'
        // pacientId: route.params.pacientId,
        // breadcrumbs: [InitialInterviewBreadcrumb]
      },
      meta: {
        title: EvaluationRoutes.LIBARE_EVALUATION.title
      }
    }
  ],
  meta: {
    requiresAuth: true,
    // requiresInitialEvaluation: true,
    onlyForPaciente: true
  }
};

export const EvaluationRoutesExplanationsFullscreen: RouteRecordRaw = {
  path: '/',
  component: () => import('src/layouts/LayoutFullScreen.vue'),
  children: [
    {
      path: EvaluationRoutes.LIBARE_EXPLANATION.key,
      component: () => import('pages/PresentationManager.vue'),
      props: {
        stateURL: EvaluationRoutes.LIBARE_EXPLANATION.key,
        // pacientId: route.params.pacientId,
        // breadcrumbs: [InitialInterviewBreadcrumb],
        componentList: [
          {
            component: defineAsyncComponent(
              () =>
                import(
                  'src/components/libare/explicacion/ExplicacionLibare1.vue'
                )
            ),
            mode: 'edit'
          },
          {
            component: defineAsyncComponent(
              () =>
                import(
                  'src/components/libare/explicacion/ExplicacionLibare2.vue'
                )
            ),
            mode: 'edit'
          }
        ]
      },
      meta: {
        title: EvaluationRoutes.LIBARE_EXPLANATION.title,
        titleInBreadcrumb: true,
        titleIcon: EvaluationRoutes.LIBARE_EXPLANATION.icon
      }
    },
    {
      path: EvaluationRoutes.DEPENDENCY_INDICATORS_EXPLANATION.key,
      component: () => import('pages/PresentationManager.vue'),
      props: {
        stateURL: EvaluationRoutes.DEPENDENCY_INDICATORS_EXPLANATION.key,
        // pacientId: route.params.pacientId,
        // breadcrumbs: [InitialInterviewBreadcrumb],
        componentList: [
          {
            component: defineAsyncComponent(
              () =>
                import(
                  'src/components/indicadores-dependencia/ExplicacionIndicadoresDependencia.vue'
                )
            ),
            mode: 'edit'
          }
        ]
      },
      meta: {
        title: EvaluationRoutes.DEPENDENCY_INDICATORS_EXPLANATION.title,
        titleInBreadcrumb: true,
        titleIcon: EvaluationRoutes.DEPENDENCY_INDICATORS_EXPLANATION.icon
      }
    },
    {
      path: EvaluationRoutes.SITUATIONAL_CONFIDENCE_EXPLANATION.key,
      component: () => import('pages/PresentationManager.vue'),
      props: {
        stateURL: EvaluationRoutes.SITUATIONAL_CONFIDENCE_EXPLANATION.key,
        // pacientId: route.params.pacientId,
        // breadcrumbs: [InitialInterviewBreadcrumb],
        componentList: [
          {
            component: defineAsyncComponent(
              () =>
                import(
                  'src/components/confianza-situacional/ExplicacionConfianzaSituacional.vue'
                )
            ),
            mode: 'edit'
          }
        ]
      },
      meta: {
        title: EvaluationRoutes.SITUATIONAL_CONFIDENCE_EXPLANATION.title,
        titleInBreadcrumb: true,
        titleIcon: EvaluationRoutes.SITUATIONAL_CONFIDENCE_EXPLANATION.icon
      }
    }
  ]
};

// const routes: RouteRecordRaw[] = [
//   InitialInterviewRoutesForms,
//   InitialInterviewRoutesExplanationsFullscreen
// ];
// export default routes;
