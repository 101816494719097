import { BasicRouteInfo } from 'src/meta/evaluation/EvaluationMeta';

export const FollowUpRoutes: Record<string, BasicRouteInfo> = {
  WELCOME_FOLLOWUP: {
    key: 'bienvenido-al-seguimiento',
    icon: '',
    index: 1,
    title: 'Bienvenido al seguimiento'
  },
  END_FOLLOWUP: {
    key: 'fin-seguimiento',
    icon: 'list',
    index: 21.1,
    title: 'Fin Seguimiento'
  }
  // DASHBOARD: { key: 'dashboard', icon: '', index: 22, title: 'Dashboard' }
  // THREE_MONTHS: { key: 'tres-meses', icon: '', index: 23, title: 'Tres meses' },
  // SIX_MONTHS: { key: 'seis-meses', icon: '', index: 24, title: 'Seis meses' },
  // TWELVE_MONTHS: { key: 'doce-meses', icon: '', index: 25, title: 'Doce meses' }
};
