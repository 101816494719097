export interface BasicRouteInfo {
  key: string;
  icon?: string;
  title: string;
  index: number;
}

export const EvaluationRoutes: Record<string, BasicRouteInfo> = {
  START: { key: '/', icon: '', title: 'PIBA', index: 0 },
  POSIT: { key: 'posit', icon: 'list', index: 2, title: 'Cuestionario POSIT' },
  AREA_SCHOOL_WORK: {
    key: 'area-escolar-laboral',
    icon: 'list',
    index: 3,
    title: 'Área escolar y laboral'
  },
  DRUG_CONSUMPTION: {
    key: 'consumo-de-drogas',
    icon: 'list',
    index: 4,
    title: 'Consumo de drogas'
  },
  ALCOHOL_HISTORY: {
    key: 'historia-de-alcohol',
    icon: 'list',
    index: 5,
    title: 'Historia de drogas psicoactivas (Alcohol)'
  },
  DRUG_HISTORY: {
    key: 'historia-de-drogas',
    icon: 'list',
    index: 6,
    title: 'Historia de drogas psicoactivas (Consumo de drogas)'
  },
  FAMILY_INFO: {
    key: 'area-familiar',
    icon: 'list',
    index: 7,
    title: 'Área familiar'
  },
  HEALTH_INFO: {
    key: 'area-salud',
    icon: 'list',
    index: 8,
    title: 'Área de Salud'
  },
  DEPRESSION_SUICIDE_RISK: {
    key: 'depresion-riesgo-suicida',
    icon: 'list',
    index: 9,
    title: 'Depresión y riesgo suicida '
  },
  SEXUALITY_AREA: {
    key: 'area-sexualidad',
    icon: 'list',
    index: 10,
    title: 'Área de sexualidad '
  },
  ANTISOCIAL_CONDUCT: {
    key: 'area-conducta-antisocial',
    icon: 'list',
    index: 11,
    title: 'Área de conducta antisocial '
  },
  FREE_TIME_INFO: {
    key: 'administracion-tiempo-libre',
    icon: 'list',
    index: 12,
    title: 'Administración del tiempo libre '
  },
  PERSONAL_SATISFACTION: {
    key: 'satisfaccion-personal',
    icon: 'list',
    index: 13,
    title: 'Satisfacción personal'
  },
  LIBARE_EXPLANATION: {
    key: 'explicacion-libare',
    icon: 'list',
    index: 14,
    title: 'Explicación LIBARE'
  },
  LIBARE_EVALUATION: {
    key: 'libare',
    icon: 'format_list_numbered',
    index: 15,
    title: 'LIBARE'
  },
  DEPENDENCY_INDICATORS_EXPLANATION: {
    key: 'explicacion-indicadores',
    icon: 'list',
    index: 16,
    title: 'Explicación Indicadores de Dependencia'
  },
  DEPENDENCY_INDICATORS_EVALUATION: {
    key: 'indicadores-dependencia',
    icon: 'list',
    index: 17,
    title: 'Indicadores de Dependencia'
  },
  SITUATIONAL_CONFIDENCE_EXPLANATION: {
    key: 'explicacion-confianza-situacional',
    icon: 'list',
    index: 18,
    title: 'Explicación Cuestionario Breve de Confianza Situacional'
  },
  SITUATIONAL_CONFIDENCE_EVALUATION: {
    key: 'confianza-situacional',
    icon: 'list',
    index: 19,
    title: 'Cuestionario Breve de Confianza Situacional'
  },
  RESULTS: { key: 'resultados', icon: '', index: 21, title: 'Resultados' }
  // DASHBOARD: { key: 'dashboard', icon: '', index: 22, title: 'Dashboard' }
};
