import { FlowState } from 'src/composables/FlowState';
import { FollowUpTwelveMonthURLs } from 'src/meta/follow-up/twelve-months';

export class FlowStateFollowUpTwelveMonths extends FlowState {
  constructor(db: PouchDB.Database) {
    super('flowStateFollowUpTwelveMonths', FollowUpTwelveMonthURLs, db);
  }
}

export async function useFlowStateFollowUpTwelveMonths(db: PouchDB.Database) {
  const flowStatePaciente = new FlowStateFollowUpTwelveMonths(db);
  await flowStatePaciente.load_current_state();
  return flowStatePaciente;
}
