import { RouteLocationPathRaw } from 'vue-router';
// import { FlowStatePaciente } from 'src/composables/useFlowState';
import { boot } from 'quasar/wrappers';
import { generateCorrectRoutePath } from 'src/composables/route-helpers';
import { useDbStore } from 'stores/db';
import { useFlowSessionFollowUp } from 'src/composables/useFlowSessionFollowUp';
import { useFlowStateFollowUpSixMonths } from 'src/composables/useFlowStateFollowUpSixMonths';
import { useFlowStateFollowUpThreeMonths } from 'src/composables/useFlowStateFollowUpThreeMonths';
import { useFlowStateFollowUpTwelveMonths } from 'src/composables/useFlowStateFollowUpTwelveMonths';
import { useFlowStateInitialEvaluation } from 'src/composables/useflowStateInitialEvaluation';
import { useFlowStateTerapeuta } from 'src/composables/useFlowStateTerapeuta';
import { useUserStore } from 'stores/user';

// "async" is optional;
// more info on params: https://v2.quasar.dev/quasar-cli/boot-files
function removePacientIdFromPath(path: string, pacientId?: string) {
  return pacientId ? path.replace(`/${pacientId}`, '') : path;
}

export default boot(({ router }) => {
  router.beforeEach(async (to, from, next) => {
    const userStore = useUserStore();
    const dbStore = useDbStore();
    const pacientId = to.params.pacientId as string | undefined;
    // debugger;
    if (to.matched.some((record) => record.meta.requiresAuth)) {
      if (!userStore.isAuthenticated) {
        return next({ path: '/login', query: { redirect: to.fullPath } });
      }
    }
    if (
      to.matched.some((record) => record.meta.requiresInitialEvaluation) &&
      (userStore.isPaciente || pacientId)
    ) {
      // debugger;
      const pacientDB = await dbStore.getPatientDBOrLocal(pacientId);
      const flowStatePaciente = await useFlowStateInitialEvaluation(pacientDB);
      const proceedTo = flowStatePaciente.verifyAuthFlowState(
        removePacientIdFromPath(to.path, pacientId)
      );
      if (proceedTo) {
        const correctRoute = generateCorrectRoutePath(proceedTo, pacientId);
        return next(correctRoute);
      }
    }
    if (
      to.matched.some((record) => record.meta.requiresInitialRegistration) &&
      userStore.isTerapeuta
    ) {
      const personalDB = await dbStore.getPersonalDB();
      const flowStateTerapeuta = await useFlowStateTerapeuta(personalDB);
      const proceedTo = flowStateTerapeuta.verifyAuthFlowState(to.path);
      if (proceedTo) {
        return next(proceedTo);
      }
    }
    if (to.matched.some((record) => record.meta.requiresFollowUpCheck)) {
      const pacientDB = await dbStore.getPatientDBOrLocal(pacientId);
      const flowSessionFollowUp = await useFlowSessionFollowUp(pacientDB);
      const followUpEventNumber = to.meta.followUpEventNumber as number;
      const proceedTo = await flowSessionFollowUp.verifyCanProceedEvent(
        followUpEventNumber
      );
      // debugger;
      if (proceedTo) {
        const correctRoute = generateCorrectRoutePath(proceedTo, pacientId);
        return next(correctRoute);
      }
      // debugger;
      const routePathWitoutPacientId = ''; // Here we need to remove the pacientId from path
      // To make it easy when verifying the state
      // We should use the `.replace(`/${to.params.pacientId}`)` but we need to confirm what
      // does the path looks like
      let proceedToInterview: RouteLocationPathRaw | undefined;
      switch (followUpEventNumber) {
        case 1:
          const flowStateThreeMonths = await useFlowStateFollowUpThreeMonths(
            pacientDB
          );
          proceedToInterview = flowStateThreeMonths.verifyAuthFlowState(
            removePacientIdFromPath(to.path, pacientId)
          );
          break;
        case 2:
          const flowStateSixMonths = await useFlowStateFollowUpSixMonths(
            pacientDB
          );
          proceedToInterview = flowStateSixMonths.verifyAuthFlowState(
            removePacientIdFromPath(to.path, pacientId)
          );
          break;
        case 3:
          const flowStateTwelveMonths = await useFlowStateFollowUpTwelveMonths(
            pacientDB
          );
          proceedToInterview = flowStateTwelveMonths.verifyAuthFlowState(
            removePacientIdFromPath(to.path, pacientId)
          );
          break;
      }
      if (proceedToInterview) {
        return next(generateCorrectRoutePath(proceedToInterview, pacientId));
      }
    }
    if (
      to.matched.some((record) => record.meta.guest) &&
      userStore.isAuthenticated
    ) {
      return next({ path: '/dashboard' });
    }
    if (
      to.matched.some((record) => record.meta.onlyForPaciente) &&
      !userStore.isPaciente
    ) {
      return next({ path: '/dashboard' });
    }
    if (
      to.matched.some((record) => record.meta.onlyForTerapeuta) &&
      !userStore.isTerapeuta
    ) {
      return next({ path: '/dashboard' });
    }

    next();
  });
  // something to do
});
