import { RouteLocationNormalized, RouteRecordRaw } from 'vue-router';

import { Breadcrumb } from 'src/types/breadcrumb';
import { DatosGeneralesSchema } from 'src/components/evaluation/DatosGeneralesForm';
import { EvaluacionInicialMeta } from 'src/meta/initial-evaluation/InitialEvaluationMeta';
import { EvaluationRoutesExplanationsFullscreen } from './EvaluationRoutes';
import { EvaluationRoutesForms } from 'src/router/EvaluationRoutes';
import { InitialEvaluationRoutes } from 'src/meta/initial-evaluation/InitialEvaluationMeta';
import { PersonalInfoSchemaColumns } from 'src/components/evaluation/PersonalInfoForm';
import { actionButtonDefaults } from 'components/blitzForm/actionButtonDefaults';
import { defineAsyncComponent } from 'vue';

const InitialInterviewBreadcrumb: Breadcrumb = {
  label: EvaluacionInicialMeta.title,
  icon: EvaluacionInicialMeta.icon
};

export const InitialInterviewRoutesForms: RouteRecordRaw = {
  path: '/',
  component: () => import('layouts/PacienteLayout.vue'),
  children: [
    {
      path: InitialEvaluationRoutes.GENERAL_INFO.key,
      component: () => import('pages/evaluation/GenericWrapperForm.vue'),
      props: (route) => ({
        stateURL: InitialEvaluationRoutes.GENERAL_INFO.key,
        mode: 'edit',
        schema: DatosGeneralesSchema,
        actionButtonDefaults: actionButtonDefaults,
        actionButtons: () => ['save'],
        pacientId: route.params.pacientId,
        flow: 'initial-evaluation',
        breadcrumbs: [InitialInterviewBreadcrumb]
      }),
      meta: {
        title: InitialEvaluationRoutes.GENERAL_INFO.title,
        titleInBreadcrumb: true,
        titleIcon: InitialEvaluationRoutes.GENERAL_INFO.icon
      }
    },
    ...(EvaluationRoutesForms.children?.map((routeRecord) => {
      const newProps = (route: RouteLocationNormalized) => ({
        // @ts-expect-error We know better than Typescript and know those routes will have the required properties
        ...routeRecord.props,
        pacientId: route.params.pacientId,
        flow: 'initial-evaluation',
        breadcrumbs: [InitialInterviewBreadcrumb],
        // @ts-expect-error We know better than Typescript and know those routes will have the required properties
        stateURL: EvaluacionInicialMeta.key + routeRecord.props.stateURL
      });
      return {
        ...routeRecord,
        path: EvaluacionInicialMeta.key + routeRecord.path,
        props: newProps
      } as RouteRecordRaw;
    }) ?? []),
    {
      path: InitialEvaluationRoutes.PERSONAL_INFO.key,
      component: () => import('pages/evaluation/GenericWrapperForm.vue'),
      props: (route) => ({
        stateURL: InitialEvaluationRoutes.PERSONAL_INFO.key,
        mode: 'edit',
        schema: PersonalInfoSchemaColumns,
        actionButtonDefaults: actionButtonDefaults,
        actionButtons: () => ['save'],
        pacientId: route.params.pacientId,
        flow: 'initial-evaluation',
        breadcrumbs: [InitialInterviewBreadcrumb]
      }),
      meta: {
        title: InitialEvaluationRoutes.PERSONAL_INFO.title,
        titleInBreadcrumb: true,
        iconTitle: InitialEvaluationRoutes.PERSONAL_INFO.icon
      }
    }
  ],
  meta: {
    requiresAuth: true,
    requiresInitialEvaluation: true,
    onlyForPaciente: true
  }
};

export const InitialInterviewRoutesExplanationsFullscreen: RouteRecordRaw = {
  path: '/',
  component: () => import('src/layouts/LayoutFullScreen.vue'),
  meta: {
    requiresAuth: true,
    requiresInitialEvaluation: true
  },
  children: [
    ...(EvaluationRoutesExplanationsFullscreen.children?.map((routeRecord) => {
      const newProps = (route: RouteLocationNormalized) => ({
        // @ts-expect-error We know better than Typescript and know those routes will have the required properties
        ...routeRecord.props,
        pacientId: route.params.pacientId,
        flow: 'initial-evaluation',
        breadcrumbs: [InitialInterviewBreadcrumb],
        // @ts-expect-error We know better than Typescript and know those routes will have the required properties
        stateURL: EvaluacionInicialMeta.key + routeRecord.props.stateURL
      });
      return {
        ...routeRecord,
        path: EvaluacionInicialMeta.key + routeRecord.path,
        props: newProps
      } as RouteRecordRaw;
    }) ?? []),
    {
      path: InitialEvaluationRoutes.WELCOME_EVALUATION.key,
      component: () => import('pages/PresentationManager.vue'),
      props: (route) => ({
        stateURL: InitialEvaluationRoutes.WELCOME_EVALUATION.key,
        pacientId: route.params.pacientId,
        flow: 'initial-evaluation',
        breadcrumbs: [InitialInterviewBreadcrumb],
        componentList: [
          {
            component: defineAsyncComponent(
              () =>
                import(
                  'components/evaluation/bienvenida-evaluacion/RegistroExitoso.vue'
                )
            ),
            mode: 'edit'
          },
          {
            component: defineAsyncComponent(
              () =>
                import(
                  'components/evaluation/bienvenida-evaluacion/BienvenidoEvaluacionIntroduccion.vue'
                )
            ),
            mode: 'edit'
          },
          {
            component: defineAsyncComponent(
              () =>
                import(
                  'components/evaluation/bienvenida-evaluacion/BienvenidoEvaluacionExplicacion.vue'
                )
            ),
            mode: 'edit'
          }
        ]
      }),
      meta: {
        title: InitialEvaluationRoutes.WELCOME_EVALUATION.title,
        titleInBreadcrumb: true,
        titleIcon: InitialEvaluationRoutes.WELCOME_EVALUATION.icon
      }
    },

    {
      path: InitialEvaluationRoutes.END_EVALUATION.key,
      component: () => import('pages/PresentationManager.vue'),
      props: (route) => ({
        stateURL: InitialEvaluationRoutes.END_EVALUATION.key,
        pacientId: route.params.pacientId,
        flow: 'initial-evaluation',
        breadcrumbs: [InitialInterviewBreadcrumb],
        componentList: [
          {
            component: defineAsyncComponent(
              () => import('components/evaluation/FinEvaluacion.vue')
            ),
            mode: 'edit'
          }
        ]
      }),
      meta: {
        title: InitialEvaluationRoutes.END_EVALUATION.title,
        titleInBreadcrumb: true,
        titleIcon: InitialEvaluationRoutes.END_EVALUATION.icon
      }
    },
    {
      path: InitialEvaluationRoutes.WELCOME_PACIENTS.key,
      component: () => import('pages/PresentationManager.vue'),
      props: (route) => ({
        stateURL: InitialEvaluationRoutes.WELCOME_PACIENTS.key,
        pacientId: route.params.pacientId,
        flow: 'initial-evaluation',
        breadcrumbs: [InitialInterviewBreadcrumb],
        componentList: [
          {
            component: defineAsyncComponent(
              () => import('components/evaluation/BienvenidaAdolescentes.vue')
            ),
            mode: 'edit'
          }
        ]
      }),
      meta: {
        title: InitialEvaluationRoutes.WELCOME_PACIENTS.title,
        titleInBreadcrumb: true,
        titleIcon: InitialEvaluationRoutes.WELCOME_PACIENTS.icon
      }
    },
    {
      path: InitialEvaluationRoutes.PROGRAM_ASSIGNATION.key,
      component: () => import('pages/PresentationManager.vue'),
      props: (route) => ({
        stateURL: InitialEvaluationRoutes.PROGRAM_ASSIGNATION.key,
        pacientId: route.params.pacientId,
        flow: 'initial-evaluation',
        breadcrumbs: [InitialInterviewBreadcrumb],
        componentList: [
          {
            component: defineAsyncComponent(
              () => import('src/components/AsignacionPrograma.vue')
            ),
            mode: 'edit'
          }
        ]
      }),
      meta: {
        title: InitialEvaluationRoutes.PROGRAM_ASSIGNATION.title,
        titleInBreadcrumb: true,
        titleIcon: InitialEvaluationRoutes.PROGRAM_ASSIGNATION.icon
      }
    },
    {
      path: InitialEvaluationRoutes.INTRODUCTION_PIBA.key,
      component: () => import('pages/PresentationManager.vue'),
      props: (route) => ({
        stateURL: InitialEvaluationRoutes.INTRODUCTION_PIBA.key,
        pacientId: route.params.pacientId,
        flow: 'initial-evaluation',
        breadcrumbs: [InitialInterviewBreadcrumb],
        componentList: [
          {
            component: defineAsyncComponent(
              () => import('src/components/intro-piba/QueEs.vue')
            ),
            mode: 'edit'
          },
          {
            component: defineAsyncComponent(
              () => import('src/components/intro-piba/SesionesPiba.vue')
            ),
            mode: 'edit'
          },
          {
            component: defineAsyncComponent(
              () => import('src/components/intro-piba/ConsumosRegistro.vue')
            ),
            mode: 'edit'
          },
          {
            component: defineAsyncComponent(
              () => import('src/components/intro-piba/MiTerapeuta.vue')
            ),
            mode: 'edit'
          },
          {
            component: defineAsyncComponent(
              () => import('src/components/intro-piba/MisHabilidades.vue')
            ),
            mode: 'edit'
          },
          {
            component: defineAsyncComponent(
              () => import('src/components/intro-piba/MisResultados.vue')
            ),
            mode: 'edit'
          }
        ]
      }),
      meta: {
        title: InitialEvaluationRoutes.INTRODUCTION_PIBA.title,
        titleInBreadcrumb: true,
        titleIcon: InitialEvaluationRoutes.INTRODUCTION_PIBA.icon
      }
    }
  ]
};

const routes: RouteRecordRaw[] = [
  InitialInterviewRoutesForms,
  InitialInterviewRoutesExplanationsFullscreen
];
export default routes;
