import { InitialEvaluationRoutes } from 'src/meta/initial-evaluation/InitialEvaluationMeta';
export const FIELDS = {
  title: {
    label: InitialEvaluationRoutes.DEPENDENCY_INDICATORS_EVALUATION.title
  },
  DSMV_1: {
    key: 'DSMV_1',
    label:
      '¿Ha notado que el tiempo que lleva ingiriendo la sustancia\n    y la cantidad con la que la consume han\n    incrementado, respecto a sus propósitos de consumo?',
    options: {
      si: {
        label: 'Sí',
        value: 'si'
      },
      no: {
        label: 'No',
        value: 'no'
      }
    },
    required: true
  },
  DSMV_2: {
    key: 'DSMV_2',
    label:
      '¿Experimenta un deseo constante por consumir la sustancia\n    o ha fracasado en sus intentos para abandonar o controlar el consumo?',
    options: {
      si: {
        label: 'Sí',
        value: 'si'
      },
      no: {
        label: 'No',
        value: 'no'
      }
    },
    required: true
  },
  DSMV_3: {
    key: 'DSMV_3',
    label:
      '¿Invierte mucho tiempo en actividades necesarias para conseguir la droga,\n    consumirla o recuperarse de sus efectos?',
    options: {
      si: {
        label: 'Sí',
        value: 'si'
      },
      no: {
        label: 'No',
        value: 'no'
      }
    },
    required: true
  },
  DSMV_4: {
    key: 'DSMV_4',
    label:
      '¿Experimenta ansias o un fuerte deseo o necesidad por consumir la sustancia?',
    options: {
      si: {
        label: 'Sí',
        value: 'si'
      },
      no: {
        label: 'No',
        value: 'no'
      }
    },
    required: true
  },
  DSMV_5: {
    key: 'DSMV_5',
    label:
      '¿Tiene un consumo recurrente que le ocasiona el incumplimiento de sus\n    principales deberes en el trabajo, la escuela o el hogar?',
    options: {
      si: {
        label: 'Sí',
        value: 'si'
      },
      no: {
        label: 'No',
        value: 'no'
      }
    },
    required: true
  },
  DSMV_6: {
    key: 'DSMV_6',
    label:
      '¿Ha continuado consumiendo, a pesar de que esto le ocasione frecuentes\n    o severos problemas en sus relaciones interpersonales?',
    options: {
      si: {
        label: 'Sí',
        value: 'si'
      },
      no: {
        label: 'No',
        value: 'no'
      }
    },
    required: true
  },
  DSMV_7: {
    key: 'DSMV_7',
    label:
      '¿El consumo de la droga le provoca el abandono o reducción de importantes\n    actividades laborales, académicas, sociales o recreativas?',
    options: {
      si: {
        label: 'Sí',
        value: 'si'
      },
      no: {
        label: 'No',
        value: 'no'
      }
    },
    required: true
  },
  DSMV_8: {
    key: 'DSMV_8',
    label:
      '¿Consume con frecuencia, aún en situaciones en las\n    que pone en riesgo su integridad  física?',
    options: {
      si: {
        label: 'Sí',
        value: 'si'
      },
      no: {
        label: 'No',
        value: 'no'
      }
    },
    required: true
  },
  DSMV_9: {
    key: 'DSMV_9',
    label:
      '¿Continua consumiendo la droga a pesar de saber que le ocasiona\n    o le intensifica un problema físico o psicológico?',
    options: {
      si: {
        label: 'Sí',
        value: 'si'
      },
      no: {
        label: 'No',
        value: 'no'
      }
    },
    required: true
  },
  DSMV_10: {
    key: 'DSMV_10',
    label:
      '¿Requiere consumir cantidades mayores de la droga para experimentar\n    el efecto deseado? ó ¿La cantidad que consume de la droga ya no\n    le ocasiona el efecto deseado?',
    options: {
      si: {
        label: 'Sí',
        value: 'si'
      },
      no: {
        label: 'No',
        value: 'no'
      }
    },
    required: true
  },
  DSMV_11: {
    key: 'DSMV_11',
    label:
      '\n    Cuando ha pasado cierto tiempo después de consumir la última dosis:\n    ¿Presenta síntomas del síndrome de abstinencia como: ansiedad, deseo intenso por consumir,\n    temblor, sudoración, taquicardia, náusea, vómito, insomnio, inapetencia, etc.?,\n    ¿O consume la sustancia o alguna similar para aliviar o quitar esos síntomas del\n    síndrome de abstinencia?',
    options: {
      si: {
        label: 'Sí',
        value: 'si'
      },
      no: {
        label: 'No',
        value: 'no'
      }
    },
    required: true
  }
};
