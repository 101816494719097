import { InitialEvaluationRoutes } from 'src/meta/initial-evaluation/InitialEvaluationMeta';
export const FIELDS = {
  title: {
    label: InitialEvaluationRoutes.DEPRESSION_SUICIDE_RISK.title
  },
  'te-sientes-triste-con-frecuencia': {
    key: 'te-sientes-triste-con-frecuencia',
    label: '¿Te sientes triste con frecuencia?',
    options: {
      si: {
        label: 'Sí',
        value: 'si'
      },
      no: {
        label: 'No',
        value: 'no'
      }
    },
    required: true
  },
  'cuantas-veces-has-sentido-que-no-puedes-seguir-adelante-durante-ultimo-mes':
    {
      key: 'cuantas-veces-has-sentido-que-no-puedes-seguir-adelante-durante-ultimo-mes',
      label:
        '¿Cuántas veces durante el último mes has sentido que no puedes salir adelante?',
      options: {
        nunca: {
          label: 'Nunca',
          value: 'nunca'
        },
        'una-a-tres-veces': {
          label: 'De 1 a 3 veces',
          value: 'una-a-tres-veces'
        },
        'cuatro-a-seis-veces': {
          label: 'De 4 a 6 veces',
          value: 'cuatro-a-seis-veces'
        },
        'siete-o-mas-veces': {
          label: 'De 7 o más veces',
          value: 'siete-o-mas-veces'
        }
      },
      required: true
    },
  'cuantas-veces-has-sentido-que-tenias-pensamientos-sobre-la-muence-durante-ultimo-mes':
    {
      key: 'cuantas-veces-has-sentido-que-tenias-pensamientos-sobre-la-muence-durante-ultimo-mes',
      label:
        '¿Cuántas veces durante el último mes has sentido que tenías pensamientos sobre la muerte?',
      options: {
        nunca: {
          label: 'Nunca',
          value: 'nunca'
        },
        'una-a-tres-veces': {
          label: 'De 1 a 3 veces',
          value: 'una-a-tres-veces'
        },
        'cuatro-a-seis-veces': {
          label: 'De 4 a 6 veces',
          value: 'cuatro-a-seis-veces'
        },
        'siete-o-mas-veces': {
          label: 'De 7 o más veces',
          value: 'siete-o-mas-veces'
        }
      },
      required: true
    },
  'cuantas-veces-has-sentido-que-tu-familia-estaria-mejor-si-estuvieras-muerto-durante-ultimo-mes':
    {
      key: 'cuantas-veces-has-sentido-que-tu-familia-estaria-mejor-si-estuvieras-muerto-durante-ultimo-mes',
      label:
        '¿Cuántas veces durante el último mes has sentido que tu familia estaría mejor si tu estuvieras muerto?',
      options: {
        nunca: {
          label: 'Nunca',
          value: 'nunca'
        },
        'una-a-tres-veces': {
          label: 'De 1 a 3 veces',
          value: 'una-a-tres-veces'
        },
        'cuatro-a-seis-veces': {
          label: 'De 4 a 6 veces',
          value: 'cuatro-a-seis-veces'
        },
        'siete-o-mas-veces': {
          label: 'De 7 o más veces',
          value: 'siete-o-mas-veces'
        }
      },
      required: true
    },
  'cuantas-veces-has-pensado-en-matarte-durante-ultimo-mes': {
    key: 'cuantas-veces-has-pensado-en-matarte-durante-ultimo-mes',
    label: '¿Cuántas veces durante el último mes has pensado en matarte?',
    options: {
      nunca: {
        label: 'Nunca',
        value: 'nunca'
      },
      'una-a-tres-veces': {
        label: 'De 1 a 3 veces',
        value: 'una-a-tres-veces'
      },
      'cuatro-a-seis-veces': {
        label: 'De 4 a 6 veces',
        value: 'cuatro-a-seis-veces'
      },
      'siete-o-mas-veces': {
        label: 'De 7 o más veces',
        value: 'siete-o-mas-veces'
      }
    },
    required: true
  },
  'alguna-vez-te-has-herido-cortado-hecho-daño-con-el-fin-de-quitarte-la-vida':
    {
      key: 'alguna-vez-te-has-herido-cortado-hecho-daño-con-el-fin-de-quitarte-la-vida',
      label:
        '¿Alguna vez, a propósito te has herido, cortado, intoxicado o hecho daño, con el fin de quitarte la vida?',
      options: {
        nunca: {
          label: 'Nunca',
          value: 'nunca'
        },
        'si-una-vez': {
          label: 'Una vez',
          value: 'si-una-vez'
        },
        'si-dos-o-mas-veces': {
          label: 'De 2 o más veces',
          value: 'si-dos-o-mas-veces'
        }
      },
      required: true
    },
  'que-edad-tenias-la-ultima-vez-que-te-hiciste-daño-con-el-fin-de-quitarte-la-vida':
    {
      key: 'que-edad-tenias-la-ultima-vez-que-te-hiciste-daño-con-el-fin-de-quitarte-la-vida',
      label:
        '¿Qué edad tenías la última vez que te hiciste daño a propósito, con el fin de quitarte la vida?',
      required: true
    },
  'que-motivo-a-hacerte-daño': {
    key: 'que-motivo-a-hacerte-daño',
    label: '¿Qué te motivó a hacerte daño?',
    required: true
  }
};
