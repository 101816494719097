/* eslint-disable max-classes-per-file */

// eslint-disable-next-line max-classes-per-file
/**
 * This defines the way we store the substance and unit of consumption
 * @example ["670a","e68f"]
 */
export type ISustancia = [string, string];

export class Sustancia {
  value: ISustancia = ['', ''];

  set nombre(value: string) {
    this.value[0] = value;
  }

  /** This actually returns the sustancia hash! :'c */
  get nombre() {
    return this.value[0];
  }

  set unidad(value: string) {
    this.value[1] = value;
  }

  /** This actually returns the unidad hash! :'c */
  get unidad() {
    return this.value[1];
  }

  // constructor();

  constructor(sustancia: ISustancia) {
    this.value = sustancia;
  }
}
export interface IEvento {
  /**
   * The id is composed of the prefix `E_`, the date of the event in ISO format
   * and a random number to handle multiple events withouth time in the same day
   *
   * @example 'E_2021-05-12T20:28:42.684Z_5.33'
   */
  _id: string;
  /**
   * Date in ISO format.
   * @example 2021-05-12T20:28:42.684Z
   * 'YYYY-MM-DD'
   */
  date: string;
  createdAt: string;
  eventType: 'c' | 's' | 'pr' | 'cb' | 'ss' | 'e';
  /**
   * Small text that describes the type of the event.
   *
   * E.g. in consumo.
   * @example 'Consumo Registrado'
   */
  calendarEventCardDescription: string;
  /**
   * Information about the event.
   *
   * E.g. in consumo
   * @example 'Alcohol: 1 trago(s)'
   *
   * E.g. in sesion_realizada
   * @example 'Sesión 1: Sesión de Inducción'
   *
   * E.g. in practica_realizada
   * @example 'Practica Sesión 3: ¿Que puedo hacer yo?'
   */
  calendarEventSummary: string;
  /**
   * This hex color is applied to the left side of the calendar card.
   *
   * Also in the calendar mini mode.
   *
   * @example '#FFBC88' - for Alcohol cosumos events
   */
  color: string;
}

export abstract class Evento implements IEvento {
  _id: string;

  date: IEvento['date'];

  createdAt: string;

  abstract eventType: 'c' | 's' | 'pr' | 'cb' | 'ss' | 'e';

  abstract calendarEventCardDescription: string;

  abstract calendarEventSummary: string;

  abstract color: string;

  constructor(date?: string) {
    if (date == null) {
      throw new Error('Evento date was undefined');
    }
    this.date = date;
    this.createdAt = new Date().toISOString();
    this._id = `E_${this.date}_${(Math.random() * 10).toFixed(2)}`;
  }
}
export interface IConsumo extends IEvento {
  sustancia: ISustancia;
  cantidad: number;
  sustanciaHash: string;
  unidadHash: string;
  estabaCon: string;
  estabaEn: string;
  consumiCuando: string;
  source: string;
}

export interface ISesion extends IEvento {
  /**
   *
   */
  sesionNumber: number;
  done: boolean;
}

export interface IConsejoBreveEvent extends IEvento {
  sustanciaHash: string;
  done: boolean;
}
/**
 * Returns the date in ISO format with timezone offset
 *
 * @see https://stackoverflow.com/a/17415677
 * @param date
 * @returns
 */
export function toIsoString(date: Date) {
  const tzo = -date.getTimezoneOffset();
  const dif = tzo >= 0 ? '+' : '-';
  const pad = function pad(num: number) {
    const norm = Math.floor(Math.abs(num));
    // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
    return (norm < 10 ? '0' : '') + norm;
  };

  return `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(
    date.getDate()
  )}T${pad(date.getHours())}:${pad(date.getMinutes())}:${pad(
    date.getSeconds()
  )}${dif}${pad(tzo / 60)}:${pad(tzo % 60)}`;
}
