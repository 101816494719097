import {
  FollowUpThreeMonthsRoutesExplanationsFullscreen,
  FollowUpThreeMonthsRoutesForms
} from './FollowUpThreeMonthsRoutes';
import {
  InitialInterviewRoutesExplanationsFullscreen,
  InitialInterviewRoutesForms
} from 'src/router/InitialInterviewRoutes';
import {
  PacientSesionesExplanationRoutes,
  PacientSesionesRoutes,
  PacienteDashboardPagesRoutes
} from './PacienteRoutes';

import { FollowUpRoutesGuarded } from './FollowUpRoutes';
import { InitialEvaluationRoutes } from 'src/meta/initial-evaluation/InitialEvaluationMeta';
import { RouteRecordRaw } from 'vue-router';
import { RoutesMeta } from './routesMeta';
import { TerapeutaPersonalInfoFormSchema } from 'src/components/terapeuta-initial-registration/TerapeutaPersonalInfoForm';
import { TerapeutaRegisterRoutes } from 'src/composables/useFlowStateTerapeuta';
import { actionButtonDefaults } from 'components/blitzForm/actionButtonDefaults';

// console.log(InitialInterviewRoutesForms.children);
const routes: RouteRecordRaw[] = [
  // ---------------------------------------------------------------------------------------------
  // ---------------------------------------------------------------------------------------------
  // -------------------------- Terapeuta As Paciente Routes -------------------------------------
  // ---------------------------------------------------------------------------------------------
  // ---------------------------------------------------------------------------------------------
  {
    path: '/:pacientId/',
    component: () => import('layouts/TerapeutaSistemaLayout.vue'),
    meta: {
      requiresInitialEvaluation: true
    },
    children: [
      ...(InitialInterviewRoutesForms.children
        ? InitialInterviewRoutesForms.children
        : []),
      ...(FollowUpRoutesGuarded.children ? FollowUpRoutesGuarded.children : []),
      ...PacienteDashboardPagesRoutes
    ]
  },
  // ---------------------------- Seguimiento Guarded Routes -------------------------------

  // ---------------------------- Seguimiento Routes 3 MESES -------------------------------
  {
    path: '/:pacientId/',
    component: () => import('layouts/TerapeutaSistemaLayout.vue'),
    meta: {
      requiresAuth: true,
      requiresFollowUpCheck: true,
      followUpEventNumber: 1
    },
    children: [
      ...(FollowUpThreeMonthsRoutesForms.children
        ? FollowUpThreeMonthsRoutesForms.children
        : [])
    ]
  },
  {
    path: '/:pacientId',
    component: () => import('src/layouts/LayoutFullScreen.vue'),
    meta: {
      requiresAuth: true,
      requiresFollowUpCheck: true,
      followUpEventNumber: 1
    },
    children: [
      ...(FollowUpThreeMonthsRoutesExplanationsFullscreen.children
        ? FollowUpThreeMonthsRoutesExplanationsFullscreen.children
        : [])
    ]
  },

  // --------------------------------- FULLSCREEN COMPONENTS ------------------------------------------
  {
    path: '/:pacientId',
    component: () => import('src/layouts/LayoutFullScreen.vue'),
    meta: {
      requiresInitialEvaluation: true
    },
    children: [
      ...(InitialInterviewRoutesExplanationsFullscreen.children
        ? InitialInterviewRoutesExplanationsFullscreen.children
        : []),
      ...(PacientSesionesRoutes.children ? PacientSesionesRoutes.children : []),
      {
        path: 'dashboard',
        component: () => import('src/pages/dashboard/DashboardBase.vue'),
        props: (route) => ({
          pacientId: route.params.pacientId
        }),
        meta: {
          title: InitialEvaluationRoutes.DASHBOARD.title,
          iconTitle: InitialEvaluationRoutes.DASHBOARD.icon,
          requiresInitialEvaluation: true
        }
      }
    ]
  },
  // --------------------------------- FULLSCREEN NO SUSPENSE COMPONENTS ------------------------------------------
  {
    path: '/:pacientId',
    component: () => import('src/layouts/LayoutFullScreenNoSuspense.vue'),
    meta: {
      requiresInitialEvaluation: true
    },
    children: [
      ...(PacientSesionesExplanationRoutes.children
        ? PacientSesionesExplanationRoutes.children
        : [])
    ]
  },
  // ---------------------------------------------------------------------------------------------
  // ---------------------------------------------------------------------------------------------
  // -------------------------- Terapeuta As Paciente Routes END -------------------------------------
  // ---------------------------------------------------------------------------------------------
  // ---------------------------------------------------------------------------------------------
  {
    path: '/:pacientId/',
    component: () => import('layouts/TerapeutaSistemaLayout.vue'),
    children: [
      {
        path: 'perfil',
        component: () =>
          import('src/pages/profile/PerfilPacienteExpediente.vue'),
        props: (route) => ({
          pacientId: route.params.pacientId
        })
      },
      {
        path: 'expediente',
        component: () => import('src/pages/expediente/ExpedienteWrapper.vue'),
        props: (route) => ({
          pacientId: route.params.pacientId
        }),
        children: [
          {
            path: 'entrevista-inicial',
            component: () =>
              import('src/pages/expediente/ExpedienteEvalucionInicial.vue'),
            props: (route) => ({
              pacientId: route.params.pacientId
            })
          },
          {
            path: 'seguimiento/tres-meses',
            component: () =>
              import('src/pages/expediente/ExpedienteSeguimientoTresMeses.vue'),
            props: (route) => ({
              pacientId: route.params.pacientId
            })
          },
          {
            path: 'seguimiento/seis-meses',
            component: () =>
              import('src/pages/expediente/ExpedienteSeguimientoSeisMeses.vue'),
            props: (route) => ({
              pacientId: route.params.pacientId
            })
          },
          {
            path: 'seguimiento/doce-meses',
            component: () =>
              import('src/pages/expediente/ExpedienteSeguimientoDoceMeses.vue'),
            props: (route) => ({
              pacientId: route.params.pacientId
            })
          },
          {
            path: 'sesiones/:sesionNumber?',
            component: () =>
              import('src/pages/expediente/ExpedienteSesiones.vue'),
            props: (route) => ({
              pacientId: route.params.pacientId,
              sesionNumber: route.params.sesionNumber
                ? Number(route.params.sesionNumber)
                : undefined
            })
          },
          {
            path: 'practicas/:sesionNumber?',
            component: () =>
              import('src/pages/expediente/ExpedientePracticasSesiones.vue'),
            props: (route) => ({
              pacientId: route.params.pacientId,
              sesionNumber: route.params.sesionNumber
                ? Number(route.params.sesionNumber)
                : undefined
            })
          }
        ]
      }
    ]
  },
  {
    path: '/',
    component: () => import('src/layouts/LayoutFullScreen.vue'),
    children: [
      {
        path: TerapeutaRegisterRoutes.INTRODUCTION.key,
        component: () =>
          import(
            'pages/terapeuta-initial-registration/TerapeutaIntroduccionVideo.vue'
          ),
        props: {
          stateURL: TerapeutaRegisterRoutes.INTRODUCTION.key
        }
      },
      {
        path: TerapeutaRegisterRoutes.WELCOME.key,
        component: () =>
          import(
            'pages/terapeuta-initial-registration/TerapeutaBienvenida.vue'
          ),
        props: {
          stateURL: TerapeutaRegisterRoutes.WELCOME.key
        }
      },
      {
        path: TerapeutaRegisterRoutes.WELCOME_VIDEO.key,
        component: () =>
          import(
            'pages/terapeuta-initial-registration/TerapeutaBienvenidaVideo.vue'
          ),
        props: {
          stateURL: TerapeutaRegisterRoutes.WELCOME_VIDEO.key
        }
      },
      {
        path: '/',
        component: () => import('src/layouts/TerapeutaSistemaLayout.vue'),
        children: [
          {
            path: TerapeutaRegisterRoutes.PERSONAL_INFO.key,
            component: () => import('pages/evaluation/GenericWrapperForm.vue'),
            props: {
              stateURL: TerapeutaRegisterRoutes.PERSONAL_INFO.key,
              flow: 'initial-registration-therapist',
              mode: 'edit',
              schema: TerapeutaPersonalInfoFormSchema,
              actionButtonDefaults: actionButtonDefaults,
              actionButtons: () => ['save']
            }
          },
          {
            path: 'citas',
            component: () => import('src/pages/terapeuta/CitasCalendario.vue')
          },
          {
            path: 'pacientes',
            component: () => import('src/pages/terapeuta/PacientesList.vue')
          },
          {
            path: '/terapeutas/list',
            component: () => import('src/pages/terapeuta/TerapeutasList.vue')
          },
          {
            path: '/instituciones',
            component: () => import('src/pages/terapeuta/InstitucionesList.vue')
          },
          {
            path: RoutesMeta.PROFILE_TERAPEUTA.key,
            component: () => import('src/pages/profile/PerfilTerapeuta.vue')
          },
          {
            path: 'estadisticas-ter',
            component: () =>
              import('src/pages/terapeuta/EstadisticasConsumosTerapueta.vue')
          }
        ],
        meta: {
          requiresAuth: true,
          requiresInitialRegistration: true
        }
      }
    ]
  }
];

export default routes;
