import { DepresionRiesgoSuicidaData } from 'src/types/initial-evaluation/evaluacion-personal';
import { FIELDS } from 'src/meta/initial-evaluation/DepresionRiesgoSuicidaMeta';
import { Icontext } from 'src/types/blitzar';
import { InitialEvaluationMetaTitleClasses } from 'src/meta/initial-evaluation/InitialEvaluationMeta';

type ctx = Icontext<Partial<DepresionRiesgoSuicidaData>>;
export const DepresionRiesgoSuicidaSchema = [
  {
    component: 'p',
    slot: FIELDS.title.label,
    componentClasses: InitialEvaluationMetaTitleClasses
  },
  {
    id: FIELDS['te-sientes-triste-con-frecuencia'].key,
    label: FIELDS['te-sientes-triste-con-frecuencia'].label,
    required: FIELDS['te-sientes-triste-con-frecuencia'].required,
    options: Object.values(FIELDS['te-sientes-triste-con-frecuencia'].options),
    component: 'QOptionWrapper',
    type: 'radio'
  },
  {
    id: FIELDS[
      'cuantas-veces-has-sentido-que-no-puedes-seguir-adelante-durante-ultimo-mes'
    ].key,
    label:
      FIELDS[
        'cuantas-veces-has-sentido-que-no-puedes-seguir-adelante-durante-ultimo-mes'
      ].label,
    required:
      FIELDS[
        'cuantas-veces-has-sentido-que-no-puedes-seguir-adelante-durante-ultimo-mes'
      ].required,
    options: Object.values(
      FIELDS[
        'cuantas-veces-has-sentido-que-no-puedes-seguir-adelante-durante-ultimo-mes'
      ].options
    ),
    component: 'QOptionWrapper',
    type: 'radio'
  },
  {
    id: FIELDS[
      'cuantas-veces-has-sentido-que-tenias-pensamientos-sobre-la-muence-durante-ultimo-mes'
    ].key,
    label:
      FIELDS[
        'cuantas-veces-has-sentido-que-tenias-pensamientos-sobre-la-muence-durante-ultimo-mes'
      ].label,
    required:
      FIELDS[
        'cuantas-veces-has-sentido-que-tenias-pensamientos-sobre-la-muence-durante-ultimo-mes'
      ].required,
    options: Object.values(
      FIELDS[
        'cuantas-veces-has-sentido-que-tenias-pensamientos-sobre-la-muence-durante-ultimo-mes'
      ].options
    ),
    component: 'QOptionWrapper',
    type: 'radio'
  },
  {
    id: FIELDS[
      'cuantas-veces-has-sentido-que-tu-familia-estaria-mejor-si-estuvieras-muerto-durante-ultimo-mes'
    ].key,
    label:
      FIELDS[
        'cuantas-veces-has-sentido-que-tu-familia-estaria-mejor-si-estuvieras-muerto-durante-ultimo-mes'
      ].label,
    required:
      FIELDS[
        'cuantas-veces-has-sentido-que-tu-familia-estaria-mejor-si-estuvieras-muerto-durante-ultimo-mes'
      ].required,
    options: Object.values(
      FIELDS[
        'cuantas-veces-has-sentido-que-tu-familia-estaria-mejor-si-estuvieras-muerto-durante-ultimo-mes'
      ].options
    ),
    component: 'QOptionWrapper',
    type: 'radio'
  },
  {
    id: FIELDS['cuantas-veces-has-pensado-en-matarte-durante-ultimo-mes'].key,
    label:
      FIELDS['cuantas-veces-has-pensado-en-matarte-durante-ultimo-mes'].label,
    required:
      FIELDS['cuantas-veces-has-pensado-en-matarte-durante-ultimo-mes']
        .required,
    options: Object.values(
      FIELDS['cuantas-veces-has-pensado-en-matarte-durante-ultimo-mes'].options
    ),
    component: 'QOptionWrapper',
    type: 'radio'
  },
  {
    id: FIELDS[
      'alguna-vez-te-has-herido-cortado-hecho-daño-con-el-fin-de-quitarte-la-vida'
    ].key,
    label:
      FIELDS[
        'alguna-vez-te-has-herido-cortado-hecho-daño-con-el-fin-de-quitarte-la-vida'
      ].label,
    required:
      FIELDS[
        'alguna-vez-te-has-herido-cortado-hecho-daño-con-el-fin-de-quitarte-la-vida'
      ].required,
    options: Object.values(
      FIELDS[
        'alguna-vez-te-has-herido-cortado-hecho-daño-con-el-fin-de-quitarte-la-vida'
      ].options
    ),
    component: 'QOptionWrapper',
    type: 'radio'
  },
  {
    id: FIELDS[
      'que-edad-tenias-la-ultima-vez-que-te-hiciste-daño-con-el-fin-de-quitarte-la-vida'
    ].key,
    label:
      FIELDS[
        'que-edad-tenias-la-ultima-vez-que-te-hiciste-daño-con-el-fin-de-quitarte-la-vida'
      ].label,
    component: 'q-input',
    type: 'number',
    min: 0,
    max: 150,
    dynamicProps: ['showCondition', 'required'],
    required: (val: number, { formData }: ctx) =>
      formData[
        'alguna-vez-te-has-herido-cortado-hecho-daño-con-el-fin-de-quitarte-la-vida'
      ]?.includes('si-'),
    showCondition: (val: number, { formData }: ctx) =>
      formData[
        'alguna-vez-te-has-herido-cortado-hecho-daño-con-el-fin-de-quitarte-la-vida'
      ]?.includes('si-')
  },
  {
    id: FIELDS['que-motivo-a-hacerte-daño'].key,
    label: FIELDS['que-motivo-a-hacerte-daño'].label,
    component: 'q-input',
    dynamicProps: ['showCondition', 'required'],
    required: (val: number, { formData }: ctx) =>
      formData[
        'alguna-vez-te-has-herido-cortado-hecho-daño-con-el-fin-de-quitarte-la-vida'
      ]?.includes('si-'),
    showCondition: (val: number, { formData }: ctx) =>
      formData[
        'alguna-vez-te-has-herido-cortado-hecho-daño-con-el-fin-de-quitarte-la-vida'
      ]?.includes('si-')
  }
];
