import {
  InitialEvaluationRoutes,
  RelativeDatesOptions
} from 'src/meta/initial-evaluation/InitialEvaluationMeta';
export const FIELDS = {
  title: {
    label: InitialEvaluationRoutes.SEXUALITY_AREA.title
  },
  'tienes-novi@': {
    key: 'tienes-novi@',
    label: '¿Tienes novi@?',
    options: {
      si: {
        label: 'Sí',
        value: 'si'
      },
      no: {
        label: 'No',
        value: 'no'
      }
    },
    required: true
  },
  'cuando-iniciaste-la-relacion': {
    key: 'cuando-iniciaste-la-relacion',
    label: '¿Cuándo iniciaste la relación?',
    options: RelativeDatesOptions,
    required: true
  },
  'has-tenido-relaciones-sexuales': {
    key: 'has-tenido-relaciones-sexuales',
    label: '¿Has tenido relaciones sexuales?',
    options: {
      si: {
        label: 'Sí',
        value: 'si'
      },
      no: {
        label: 'No',
        value: 'no'
      }
    },
    required: true
  },
  'a-que-edad-iniciaste-relaciones-sexuales': {
    key: 'a-que-edad-iniciaste-relaciones-sexuales',
    label: '¿A qué edad iniciaste?',
    required: true
  },
  'utilizas-algun-metodo-anticonceptivo': {
    key: 'utilizas-algun-metodo-anticonceptivo',
    label:
      'Cuando tienes relaciones sexuales, ¿Utilizas algún método anticonceptivo para evitar el embarazo o para no contraer alguna enfermedad?',
    options: {
      si: {
        label: 'Sí',
        value: 'si'
      },
      no: {
        label: 'No',
        value: 'no'
      }
    },
    required: true
  },
  'cual-metodo-anticonceptivo': {
    key: 'cual-metodo-anticonceptivo',
    label: '¿Cuál método anticonceptivo?',
    required: true
  },
  'con-que-frecuencia-utilizas-medoto-anticonceptivo': {
    key: 'con-que-frecuencia-utilizas-medoto-anticonceptivo',
    label: '¿Con qué frecuencia?',
    options: {
      siempre: {
        label: 'Siempre',
        value: 'siempre'
      },
      'casi-siempre': {
        label: 'Casi siempre',
        value: 'casi-siempre'
      },
      frecuentemente: {
        label: 'frecuentemente',
        value: 'frecuentemente'
      },
      'algunas-veces': {
        label: 'Algunas veces',
        value: 'algunas-veces'
      }
    },
    required: true
  },
  'has-tenido-problemas-relacionados-conducta-sexual': {
    key: 'has-tenido-problemas-relacionados-conducta-sexual',
    label: '¿Has tenido problemas relacionados a tu conducta sexual?',
    options: {
      si: {
        label: 'Sí',
        value: 'si'
      },
      no: {
        label: 'No',
        value: 'no'
      }
    },
    required: true
  },
  'has-tenido-relaciones-despues-de-consumir-alcohol-o-drogas': {
    key: 'has-tenido-relaciones-despues-de-consumir-alcohol-o-drogas',
    label:
      '¿Has tenido relaciones sexuales después de consumir alcohol o drogas?',
    options: {
      si: {
        label: 'Sí',
        value: 'si'
      },
      no: {
        label: 'No',
        value: 'no'
      }
    },
    required: true
  }
};
