import { InitialEvaluationRoutes } from 'src/meta/initial-evaluation/InitialEvaluationMeta';
export const FIELDS = {
  title: {
    label: InitialEvaluationRoutes.SITUATIONAL_CONFIDENCE_EVALUATION.title
  },
  CS_1: {
    key: 'CS_1',
    label: 'Ante emociones desagradables',
    subLabel: 'Cuando me siento triste, deprimido o molesto por alguna razón.',
    required: true
  },
  CS_2: {
    key: 'CS_2',
    label: 'Malestar físico',
    subLabel: 'Cuando tengo dolor de cabeza, gripa o con tensión.',
    required: true
  },
  CS_3: {
    key: 'CS_3',
    label: 'Ante emociones agradables',
    subLabel: 'Cuando quiero celebrar o estoy feliz.',
    required: true
  },
  CS_4: {
    key: 'CS_4',
    label: 'Probando mi control sobre mi consumo',
    subLabel: 'Cuando me propongo solo consumir cierta cantidad.',
    required: true
  },
  CS_5: {
    key: 'CS_5',
    label: 'Ante necesidad física',
    subLabel:
      'Si tuviera la necesidad y urgencia de consumir y no puedo hacer nada más.',
    required: true
  },
  CS_6: {
    key: 'CS_6',
    label: 'Ante conflictos con otros',
    subLabel: 'Cuando tengo peleas con amigos, familia o pareja.',
    required: true
  },
  CS_7: {
    key: 'CS_7',
    label: 'Ante presión social',
    subLabel: 'Si me presionan amigos o familiares a consumir.',
    required: true
  },
  CS_8: {
    key: 'CS_8',
    label: 'Ante momentos agradables con otros',
    subLabel: 'Cuando estoy en una celebración de un amigo o familiar.',
    required: true
  }
};
