import { InstitucionViewKeyName } from 'src/types/Instituciones';

export enum codigoInvitacionEstado {
  SIN_INGRESAR = 0,
  INGRESADO = 1,
  VALIDANDO = 2,
  ACEPTADO = 3,
  EXPIRADO = 4,
  NO_ENCONTRADO = 5,
  ACTIVADO_POR_ADMIN = 6
}
export interface RegisterTerapeutaPersonalData {
  _id?: string;
  names: string;
  lastNames: string;
  institucion: InstitucionViewKeyName;
  puesto: string;
  codigoInvitacion: {
    estado: codigoInvitacionEstado;
    codigo: string;
    id?: string;
  };
  attachments: {
    'profile-pics@profile_picture'?: File;
  };
  profileStatus?: 'active' | 'pending' | 'blocked' | '';
  /**
   * The user key in the sl-users database. Used for pacients registering to
   */
  keycode?: string;
  /**
   * Attachment’s with s3 object name. Available after the attachment is moved
   *  from couchdb and uploaded in the s3 bucket.
   */
  // eslint-disable-next-line camelcase
  attachments_s3?: {
    [key: string]: {
      content_type: string;
      revpos: number;
      digest: string;
      length: number;
      stub: boolean;
      objectName: string;
      url: string;
    };
  };
}

export class RegisterTerapeutaPersonal
  implements RegisterTerapeutaPersonalData
{
  _id: RegisterTerapeutaPersonalData['_id'];

  names: RegisterTerapeutaPersonalData['names'] = '';

  lastNames: RegisterTerapeutaPersonalData['lastNames'] = '';

  institucion: RegisterTerapeutaPersonalData['institucion'] = {
    id: '',
    key: '',
    value: ''
  };

  puesto: RegisterTerapeutaPersonalData['puesto'] = '';

  codigoInvitacion: {
    estado: RegisterTerapeutaPersonalData['codigoInvitacion']['estado'];
    codigo: RegisterTerapeutaPersonalData['codigoInvitacion']['codigo'];
    id: RegisterTerapeutaPersonalData['codigoInvitacion']['id'];
  } = {
    estado: codigoInvitacionEstado.SIN_INGRESAR,
    codigo: '',
    id: undefined
  };

  attachments: RegisterTerapeutaPersonalData['attachments'] = {
    'profile-pics@profile_picture': undefined
  };

  attachments_s3: RegisterTerapeutaPersonalData['attachments_s3'];

  profileStatus: RegisterTerapeutaPersonalData['profileStatus'] = undefined;

  keycode = undefined;
}
