import { InitialEvaluationMetaTitleClasses } from 'src/meta/initial-evaluation/InitialEvaluationMeta';
import { Icontext } from 'src/types/blitzar';
import { RegisterPacientePersonalData } from 'src/types/initial-evaluation/evaluacion-personal';
import { SchemaField } from 'blitzar';
import { FIELDS } from 'src/meta/initial-evaluation/EvaluacionPersonalMeta';

type ctx = Icontext<RegisterPacientePersonalData>;
export const PersonalInfoSchemaColumns = <SchemaField[]>[
  {
    component: 'p',
    slot: FIELDS.title.label,
    componentClasses: InitialEvaluationMetaTitleClasses
  },
  {
    id: FIELDS.names.key,
    label: FIELDS.names.label,
    placeholder: FIELDS.names.placeholder,
    required: FIELDS.names.required,
    component: 'q-input',
    type: 'text'
  },
  {
    id: FIELDS.lastNames.key,
    label: FIELDS.lastNames.label,
    placeholder: FIELDS.lastNames.placeholder,
    required: FIELDS.lastNames.required,
    hint: FIELDS.lastNames.hint,
    component: 'q-input'
  },
  {
    id: FIELDS['attachments.profile-pics@profile_picture'].key,
    label: FIELDS['attachments.profile-pics@profile_picture'].label,
    required: FIELDS['attachments.profile-pics@profile_picture'].required,
    component: 'profile-pic',
    events: {
      input: function input(value: File, context: ctx) {
        console.log(value);
        context.updateField({
          id: 'attachments.profile-pics@profile_picture',
          value
        });
      }
    },
    parseValue: (_val: File, context: ctx) => {
      if (context.mode === 'raw') {
        return 'Imagen de perfil';
      }
      if (
        context.formData.attachments_s3?.['profile-pics@profile_picture'].url
      ) {
        const urlS3 = process.env.VUE_APP_S3_URL;
        const attData =
          context.formData.attachments_s3?.['profile-pics@profile_picture'];
        const urlProfilePic = attData.url;
        const { digest } = attData;
        return `${urlS3}${urlProfilePic}?digest=${digest}`;
      }
      return '/avatar.png';
    }
  },
  {
    id: FIELDS.terapeutaElection.key,
    label: FIELDS.terapeutaElection.label,
    required: FIELDS.terapeutaElection.required,
    hint: FIELDS.terapeutaElection.hint,
    component: 'ElectionTerapeuta',
    defaultValue: {}
  },
  {
    id: FIELDS.birthDate.key,
    label: FIELDS.birthDate.label,
    required: FIELDS.birthDate.required,
    component: 'q-input',
    type: 'date',
    defaultValue: ''
  },
  {
    id: FIELDS.sex.key,
    label: FIELDS.sex.label,
    required: FIELDS.sex.required,
    component: 'SexPicker',
    defaultValue: ''
  },
  {
    id: FIELDS.academicInfo.key,
    label: FIELDS.academicInfo.label,
    required: FIELDS.academicInfo.required,
    component: 'InformacionAcademica',
    defaultValue: {}
  },
  {
    id: FIELDS.addressInfo.key,
    label: FIELDS.addressInfo.label,
    required: FIELDS.addressInfo.required,
    component: 'InformacionDomiciliaria',
    defaultValue: {}
  }
];
