import { DocWithAttachments } from 'src/types/pouchdb/attachmentDoc';
import { api } from 'src/boot/axios';
import to from 'await-to-js';

export function useS3Object() {
  async function getS3Object(url: string) {
    const s3Server = `${process.env.VUE_APP_AUTH_SERVER}/attachments`;
    // To get the attachment you need to be authenticated so we use the axios api instace
    // https://stackoverflow.com/a/52154751/13737199
    // To check if the browser is caching the attachments open a new tab if you reload the
    // request are made to the server again https://stackoverflow.com/a/65651099
    const [err, response] = await to(
      api.get<Buffer>(`${s3Server}${url}`, { responseType: 'blob' })
    );
    if (response != null) {
      return {
        // url: `${process.env.VUE_APP_S3_URL}${doc.attachments_s3[attachmentName].url}`,
        // url: URL.createObjectURL(blob),
        // @ts-expect-error There are some inconsistencies in the type Blob and Buffer but the function still works as expected
        url: URL.createObjectURL(response.data),
        buffer: response.data
      };
    }
    console.error(err);
    console.error('The attachment could not be retreived');
    return { url: '/placeholder-1.png', buffer: undefined };
  }
  return { getS3Object };
}

export async function getAttachment({
  db,
  docId,
  attachmentName,
  attachmentNameS3
}: {
  db: PouchDB.Database;
  docId: string;
  attachmentName: string;
  attachmentNameS3?: string;
}): Promise<
  { url: string; buffer: undefined } | { url: string; buffer: Blob | Buffer }
> {
  const [errDoc, doc] = await to(db.get<DocWithAttachments>(docId));
  if (errDoc) {
    console.error(`The requested attachment ${attachmentName} in the doc ${docId} was not found`,errDoc);
    // throw new Error('The doc was not found');
    return { url: '/placeholder-1.png', buffer: undefined };
  }
  if (
    doc?.attachments_s3 != null &&
    doc.attachments_s3[attachmentNameS3 || attachmentName]
  ) {
    const { getS3Object } = useS3Object();
    return getS3Object(
      doc.attachments_s3[attachmentNameS3 || attachmentName].url
    );
  }
  if (doc?._attachments != null && doc._attachments[attachmentName]) {
    const [errPicture, picture] = await to(
      db.getAttachment(docId, attachmentName)
    );
    if (errPicture) {
      
      console.error(`The requested attachment ${attachmentName} in the doc ${docId} was not found`,errPicture);
      // throw new Error('The attachment could not be retreived');
      return { url: '/placeholder-1.png', buffer: undefined };
    }
    if (picture == null) {
      // throw new Error('The attachment could not be retreived');
      console.error(`The requested attachment ${attachmentName} in the doc ${docId} was not found, the file was null`);
      return { url: '/placeholder-1.png', buffer: undefined };
    }
    // @ts-expect-error There are some inconsistencies in the type Blob and Buffer but the function still works as expected
    return { url: URL.createObjectURL(picture), buffer: picture };
  }
  return { url: '/placeholder-1.png', buffer: undefined };
}
