export const options = {
  si: {
    key: 'si',
    label: 'Si',
    value: 1
  },
  no: {
    key: 'no',
    label: 'No',
    value: 0
  }
};

import { InitialEvaluationRoutes } from 'src/meta/initial-evaluation/InitialEvaluationMeta';
export const FIELDS = {
  title: {
    label: InitialEvaluationRoutes.POSIT.title
  },
  'has-tenido-problemas-porque-consumes-drogas-o-bebidas-alcoholi': {
    key: 'has-tenido-problemas-porque-consumes-drogas-o-bebidas-alcoholi',
    label:
      '¿Has tenido problemas porque consumes drogas o bebidas alcohólicas en la escuela o en el trabajo?',
    required: true
  },
  'te-has-hecho-dano-o-has-hecho-dano-otra-persona-accidentalment': {
    key: 'te-has-hecho-dano-o-has-hecho-dano-otra-persona-accidentalment',
    label:
      '¿Te has hecho daño o has hecho daño a otra persona accidentalmente estando bajo el efecto de las drogas o el alcohol?',
    required: true
  },
  'sueles-perderte-actividades-o-acontecimientos-porque-has-gasta': {
    key: 'sueles-perderte-actividades-o-acontecimientos-porque-has-gasta',
    label:
      '¿Sueles perderte actividades o acontecimientos porque has gastado demasiado dinero en drogas o bebidas alcohólicas?',
    required: true
  },
  'has-sentido-eres-adicto-al-alcohol-o-drogas': {
    key: 'has-sentido-eres-adicto-al-alcohol-o-drogas',
    label: '¿Has sentido que eres adicto (a) al alcohol o a las drogas?',
    required: true
  },
  'has-comenzado-consumir-mayores-cantidades-drogas-o-alcohol-obt': {
    key: 'has-comenzado-consumir-mayores-cantidades-drogas-o-alcohol-obt',
    label:
      '¿Has comenzado a consumir mayores cantidades de drogas o alcohol para obtener el efecto que deseas?',
    required: true
  },
  'te-vas-fiestas-porque-no-hay-bebidas-alcoholicas-o-drogas': {
    key: 'te-vas-fiestas-porque-no-hay-bebidas-alcoholicas-o-drogas',
    label: '¿Te vas de las fiestas porque no hay bebidas alcohólicas o drogas?',
    required: true
  },
  'sientes-un-deseo-constante-consumir-bebidas-alcoholicas-o-drog': {
    key: 'sientes-un-deseo-constante-consumir-bebidas-alcoholicas-o-drog',
    label:
      '¿Sientes un deseo constante de consumir bebidas alcohólicas o drogas?',
    required: true
  },
  'has-tenido-un-accidente-automovilistico-estando-efecto-del-alc': {
    key: 'has-tenido-un-accidente-automovilistico-estando-efecto-del-alc',
    label:
      '¿Has tenido un accidente automovilístico estando bajo el efecto del alcohol o de drogas?',
    required: true
  },
  'olvidas-lo-haces-cuando-bebes-o-te-drogas': {
    key: 'olvidas-lo-haces-cuando-bebes-o-te-drogas',
    label: '¿Olvidas lo que haces cuando bebes o te drogas?',
    required: true
  },
  'mes-pasado-manejaste-un-automovil-estando-borracho-o-drogado': {
    key: 'mes-pasado-manejaste-un-automovil-estando-borracho-o-drogado',
    label:
      'El mes pasado ¿manejaste un automóvil estando borracho(a) o drogado(a)?',
    required: true
  },
  '-uso-del-alcohol-o-drogas-te-produce-cambios-repentinos-humor-': {
    key: '-uso-del-alcohol-o-drogas-te-produce-cambios-repentinos-humor-',
    label:
      '¿El uso del alcohol o de las drogas te produce cambios repentinos de humor, como pasar de estar contento(a) a estar triste, o de estar triste a estar contento(a)?',
    required: true
  },
  'pierdes-dias-clase-o-llegas-tarde-escuela-o-al-trabajo-haber-c': {
    key: 'pierdes-dias-clase-o-llegas-tarde-escuela-o-al-trabajo-haber-c',
    label:
      '¿Pierdes días de clase o llegas tarde a la escuela o al trabajo por haber consumido bebidas alcohólicas o drogas?',
    required: true
  },
  'te-han-dicho-alguna-vez-tus-familiares-o-amigos-debes-bajarle-': {
    key: 'te-han-dicho-alguna-vez-tus-familiares-o-amigos-debes-bajarle-',
    label:
      '¿Te han dicho alguna vez tus familiares o amigos que debes bajarle al consumo de bebidas alcohólicas o drogas?',
    required: true
  },
  'discutes-seriamente-tus-amigos-o-familiares-consumo-haces-bebi': {
    key: 'discutes-seriamente-tus-amigos-o-familiares-consumo-haces-bebi',
    label:
      '¿Discutes seriamente con tus amigos o familiares por el consumo que haces de las bebidas alcohólicas o drogas?',
    required: true
  },
  '-bebidas-alcoholicas-o-drogas-te-han-llevado-hacer-algo-normal': {
    key: '-bebidas-alcoholicas-o-drogas-te-han-llevado-hacer-algo-normal',
    label:
      '¿Las bebidas alcohólicas o las drogas te han llevado hacer algo que normalmente no harías, como desobedecer alguna regla o ley, o la hora de llegar a casa, o a tener relaciones sexuales con alguien?',
    required: true
  },
  'tienes-dificultades-alguno-tus-amigos-debido-bebidas-alcoholic': {
    key: 'tienes-dificultades-alguno-tus-amigos-debido-bebidas-alcoholic',
    label:
      '¿Tienes dificultades con alguno de tus amigos debido a las bebidas alcohólicas o drogas que consumes?',
    required: true
  },
  'has-sentido-no-puedes-controlar-deseo-consumir-bebidas-alcohol': {
    key: 'has-sentido-no-puedes-controlar-deseo-consumir-bebidas-alcohol',
    label:
      '¿Has sentido que no puedes controlar el deseo de consumir bebidas alcohólicas o drogas?',
    required: true
  }
};
