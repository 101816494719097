import { AdministracionTiempoLibreData } from 'src/types/initial-evaluation/evaluacion-personal';
import { FIELDS } from 'src/meta/initial-evaluation/AdministracionTiempoLibreMeta';
import { Icontext } from 'src/types/blitzar';
import { InitialEvaluationMetaTitleClasses } from 'src/meta/initial-evaluation/InitialEvaluationMeta';

type ctx = Icontext<Partial<AdministracionTiempoLibreData>>;
export const AdministracionTiempoLibreSchema = [
  {
    component: 'p',
    slot: FIELDS.title.label,
    componentClasses: InitialEvaluationMetaTitleClasses
  },
  {
    id: FIELDS['ves-television-en-casa'].key,
    label: FIELDS['ves-television-en-casa'].label,
    required: FIELDS['ves-television-en-casa'].required,
    options: Object.values(FIELDS['ves-television-en-casa'].options),
    component: 'QOptionWrapper',
    type: 'radio'
  },
  {
    id: FIELDS['practicas-algun-deporte'].key,
    label: FIELDS['practicas-algun-deporte'].label,
    required: FIELDS['practicas-algun-deporte'].required,
    options: Object.values(FIELDS['practicas-algun-deporte'].options),
    component: 'QOptionWrapper',
    type: 'radio'
  },
  {
    id: FIELDS['juegas-o-sales-con-amigos'].key,
    label: FIELDS['juegas-o-sales-con-amigos'].label,
    required: FIELDS['juegas-o-sales-con-amigos'].required,
    options: Object.values(FIELDS['juegas-o-sales-con-amigos'].options),
    component: 'QOptionWrapper',
    type: 'radio'
  },
  {
    id: FIELDS['ayudas-en-las-labores-de-la-casa'].key,
    label: FIELDS['ayudas-en-las-labores-de-la-casa'].label,
    required: FIELDS['ayudas-en-las-labores-de-la-casa'].required,
    options: Object.values(FIELDS['ayudas-en-las-labores-de-la-casa'].options),
    component: 'QOptionWrapper',
    type: 'radio'
  },
  {
    id: FIELDS['haces-tarea-o-estudias'].key,
    label: FIELDS['haces-tarea-o-estudias'].label,
    required: FIELDS['haces-tarea-o-estudias'].required,
    options: Object.values(FIELDS['haces-tarea-o-estudias'].options),
    component: 'QOptionWrapper',
    type: 'radio'
  },
  {
    id: FIELDS['juegas-videojuegos'].key,
    label: FIELDS['juegas-videojuegos'].label,
    required: FIELDS['juegas-videojuegos'].required,
    options: Object.values(FIELDS['juegas-videojuegos'].options),
    component: 'QOptionWrapper',
    type: 'radio'
  },
  {
    id: FIELDS['sales-a-pasear-con-tu-familia'].key,
    label: FIELDS['sales-a-pasear-con-tu-familia'].label,
    required: FIELDS['sales-a-pasear-con-tu-familia'].required,
    options: Object.values(FIELDS['sales-a-pasear-con-tu-familia'].options),
    component: 'QOptionWrapper',
    type: 'radio'
  },
  {
    id: FIELDS['vas-a-beber-con-tus-amigos'].key,
    label: FIELDS['vas-a-beber-con-tus-amigos'].label,
    required: FIELDS['vas-a-beber-con-tus-amigos'].required,
    options: Object.values(FIELDS['vas-a-beber-con-tus-amigos'].options),
    component: 'QOptionWrapper',
    type: 'radio'
  },
  {
    id: FIELDS['escuchas-musica'].key,
    label: FIELDS['escuchas-musica'].label,
    required: FIELDS['escuchas-musica'].required,
    options: Object.values(FIELDS['escuchas-musica'].options),
    component: 'QOptionWrapper',
    type: 'radio'
  },
  {
    id: FIELDS['hablas-por-telefono'].key,
    label: FIELDS['hablas-por-telefono'].label,
    required: FIELDS['hablas-por-telefono'].required,
    options: Object.values(FIELDS['hablas-por-telefono'].options),
    component: 'QOptionWrapper',
    type: 'radio'
  },
  {
    id: FIELDS['vas-a-fiestas'].key,
    label: FIELDS['vas-a-fiestas'].label,
    required: FIELDS['vas-a-fiestas'].required,
    options: Object.values(FIELDS['vas-a-fiestas'].options),
    component: 'QOptionWrapper',
    type: 'radio'
  },
  {
    id: FIELDS['asistes-a-clases-artisticas'].key,
    label: FIELDS['asistes-a-clases-artisticas'].label,
    required: FIELDS['asistes-a-clases-artisticas'].required,
    options: Object.values(FIELDS['asistes-a-clases-artisticas'].options),
    component: 'QOptionWrapper',
    type: 'radio'
  },
  {
    id: FIELDS['vas-al-cine'].key,
    label: FIELDS['vas-al-cine'].label,
    required: FIELDS['vas-al-cine'].required,
    options: Object.values(FIELDS['vas-al-cine'].options),
    component: 'QOptionWrapper',
    type: 'radio'
  },
  {
    id: FIELDS['otra-actividad'].key,
    label: FIELDS['otra-actividad'].label,
    required: FIELDS['otra-actividad'].required,
    component: 'q-input',
    type: 'text',
    sublabel: 'especifíca',
    labelClasses: 'noRequired'
  }
];
