import { InitialEvaluationRoutes } from 'src/meta/initial-evaluation/InitialEvaluationMeta';
import { RelativeDatesOptions } from 'src/meta/initial-evaluation/InitialEvaluationMeta';
export const FIELDS = {
  title: {
    label: InitialEvaluationRoutes.DRUG_HISTORY.title
  },
  'alguna-vez-has-probado-droga': {
    key: 'alguna-vez-has-probado-droga',
    label: '¿Alguna vez en tu vida has probado alguna droga?',
    options: {
      si: {
        label: 'Sí',
        value: 'si'
      },
      no: {
        label: 'No',
        value: 'no'
      }
    },
    required: true
  },
  'has-probado-marihuana': {
    key: 'has-probado-marihuana',
    label: '¿Has probado la Marihuana?',
    options: {
      si: {
        label: 'Sí',
        value: 'si'
      },
      no: {
        label: 'No',
        value: 'no'
      }
    },
    required: true
  },
  'has-probado-cocaína': {
    key: 'has-probado-cocaína',
    label: '¿Has probado la cocaína?',
    options: {
      si: {
        label: 'Sí',
        value: 'si'
      },
      no: {
        label: 'No',
        value: 'no'
      }
    },
    required: true
  },
  'has-probado-inhalables': {
    key: 'has-probado-inhalables',
    label: '¿Has probado inhalables?',
    subLabel: '(thinner, cemento, resistol, etc.)',
    options: {
      si: {
        label: 'Sí',
        value: 'si'
      },
      no: {
        label: 'No',
        value: 'no'
      }
    },
    required: true
  },
  'has-probado-anfetaminas': {
    key: 'has-probado-anfetaminas',
    label: '¿Has probado pastillas?',
    subLabel: '(Anfetaminas, estimulantes, etc.)',
    options: {
      si: {
        label: 'Sí',
        value: 'si'
      },
      no: {
        label: 'No',
        value: 'no'
      }
    },
    required: true
  },
  'has-probado-tranquilizantes': {
    key: 'has-probado-tranquilizantes',
    label: '¿Has probado tranquilizantes o sedantes?',
    options: {
      si: {
        label: 'Sí',
        value: 'si'
      },
      no: {
        label: 'No',
        value: 'no'
      }
    },
    required: true
  },
  'has-probado-heroína': {
    key: 'has-probado-heroína',
    label: '¿Has probado heroína?',
    options: {
      si: {
        label: 'Sí',
        value: 'si'
      },
      no: {
        label: 'No',
        value: 'no'
      }
    },
    required: true
  },
  'que-edad-tenias-primera-vez-consumiste-alguna-estas-drogas': {
    key: 'que-edad-tenias-primera-vez-consumiste-alguna-estas-drogas',
    label:
      '¿Qué edad tenías la primera vez que consumiste alguna de las drogas anteriores?',
    required: true
  },
  'cuantas-veces-en-tu-vida-has-probado-estas-drogas': {
    key: 'cuantas-veces-en-tu-vida-has-probado-estas-drogas',
    label: '¿Cuántas veces en tu vida has probado estas drogas?',
    options: {
      'una-o-dos': {
        label: '1 o 2 veces',
        value: 'una-o-dos'
      },
      'menos-de-diez': {
        label: 'De 3 a 10 veces',
        value: 'menos-de-diez'
      },
      'menos-de-cincuenta': {
        label: 'De 11 a 49 veces',
        value: 'menos-de-cincuenta'
      },
      'mas-de-cincuenta': {
        label: 'De 50 o más veces',
        value: 'mas-de-cincuenta'
      }
    },
    required: true
  },
  'hace-cuanto-tiempo-probaste-por-primera-vez': {
    key: 'hace-cuanto-tiempo-probaste-por-primera-vez',
    label: '¿Hace cuánto tiempo probaste estas drogas por primera vez?',
    options: {
      'menos-de-un-mes': {
        label: 'Hace menos de un mes',
        value: 'menos-de-un-mes'
      },
      'dos-a-seis-meses': {
        label: 'De 2 a 6 meses',
        value: 'dos-a-seis-meses'
      },
      'siete-a-doce-meses': {
        label: 'De 7 a 12 meses',
        value: 'siete-a-doce-meses'
      },
      'mas-de-un-año': {
        label: 'Hace más de una año',
        value: 'mas-de-un-año'
      }
    },
    required: true
  },
  'has-consumido-estos-ultimos-doce-meses': {
    key: 'has-consumido-estos-ultimos-doce-meses',
    label: 'En los últimos 12 meses ¿Has consumido estas drogas?',
    options: {
      si: {
        label: 'Sí',
        value: 'si'
      },
      no: {
        label: 'No',
        value: 'no'
      }
    },
    required: true
  },
  'has-consumido-el-ultimo-mes': {
    key: 'has-consumido-el-ultimo-mes',
    label: '¿Consumiste en el último mes?,¿Hace cuánto consumiste?',
    options: {
      'no-consumi': {
        label: 'No consumí',
        value: 'no-consumi'
      },
      'uno-a-cinco-dias': {
        label: 'De 1 a 5 días',
        value: 'uno-a-cinco-dias'
      },
      'seis-a-diecinueve-dias': {
        label: 'De 6 a 19 días',
        value: 'seis-a-diecinueve-dias'
      },
      'mas-de-veintedias': {
        label: 'Hace más de 20 días',
        value: 'mas-de-veintedias'
      }
    },
    required: true
  },
  'has-intentado-dejar-o-consumir-menos': {
    key: 'has-intentado-dejar-o-consumir-menos',
    label: '¿Has intentado dejar de consumir o consumir menos drogas?',
    options: {
      si: {
        label: 'Sí',
        value: 'si'
      },
      no: {
        label: 'No',
        value: 'no'
      }
    },
    required: true
  },
  'fecha-de-cuando-intentaste': {
    key: 'fecha-de-cuando-intentaste',
    label: '¿Cuándo fue tu intento?',
    options: RelativeDatesOptions,
    required: true
  },
  'porque-hiciste-el-intento': {
    key: 'porque-hiciste-el-intento',
    label: '¿Por qué hiciste el intento?',
    required: true
  },
  'todavia-estas-intentandolo': {
    key: 'todavia-estas-intentandolo',
    label: '¿Todavía estás haciéndolo?',
    options: {
      si: {
        label: 'Sí',
        value: 'si'
      },
      no: {
        label: 'No',
        value: 'no'
      }
    },
    required: true
  },
  'en-que-plazo-te-gustaria-intentar-consumir-menos': {
    key: 'en-que-plazo-te-gustaria-intentar-consumir-menos',
    label: '¿En qué plazo te gustaría intentar consumir menos drogas?',
    options: {
      'proximos-seis-meses': {
        label: 'En los próximos 6 meses',
        value: 'proximos-seis-meses'
      },
      'proximo-mes': {
        label: 'En el próximo mes',
        value: 'proximo-mes'
      }
    },
    required: true
  },
  'que-tan-peligroso-crees-consumir-drogas-que-has-probado': {
    key: 'que-tan-peligroso-crees-consumir-drogas-que-has-probado',
    label:
      '¿Qué tan peligroso crees que es consumir drogas como las que tu has probado?',
    options: {
      'no-es-peligroso': {
        label: 'No es peligroso',
        value: 'no-es-peligroso'
      },
      peligroso: {
        label: 'Es peligroso',
        value: 'peligroso'
      },
      'muy-peligroso': {
        label: 'Es muy peligroso',
        value: 'muy-peligroso'
      }
    },
    required: true
  },
  'que-tan-peligroso-crees-que-consumir-alcohol-muy-seguido': {
    key: 'que-tan-peligroso-crees-que-consumir-alcohol-muy-seguido',
    label: '¿Qué tan peligroso crees que es consumir muy seguido alcohol?',
    options: {
      'no-es-peligroso': {
        label: 'No es peligroso',
        value: 'no-es-peligroso'
      },
      peligroso: {
        label: 'Es peligroso',
        value: 'peligroso'
      },
      'muy-peligroso': {
        label: 'Es muy peligroso',
        value: 'muy-peligroso'
      }
    },
    required: true
  },
  'que-opina-tu-mama': {
    key: 'que-opina-tu-mama',
    label: '¿Qué opina tu mamá de que tomes alcohol o pruebes drogas?',
    options: {
      'no-lo-sabe': {
        label: 'No lo sabe',
        value: 'no-lo-sabe'
      },
      'lo-ve-bien': {
        label: 'Lo ve bien',
        value: 'lo-ve-bien'
      },
      'no-lo-ve-bien-ni-mal': {
        label: 'No lo ve ni bien ni mal',
        value: 'no-lo-ve-bien-ni-mal'
      },
      'lo-ve-mal': {
        label: 'Lo ve mal',
        value: 'lo-ve-mal'
      }
    },
    required: true
  },
  'que-opina-tu-papa': {
    key: 'que-opina-tu-papa',
    label: '¿Qué opina tu papá de que tomes alcohol o pruebes drogas?',
    options: {
      'no-lo-sabe': {
        label: 'No lo sabe',
        value: 'no-lo-sabe'
      },
      'lo-ve-bien': {
        label: 'Lo ve bien',
        value: 'lo-ve-bien'
      },
      'no-lo-ve-bien-ni-mal': {
        label: 'No lo ve ni bien ni mal',
        value: 'no-lo-ve-bien-ni-mal'
      },
      'lo-ve-mal': {
        label: 'Lo ve mal',
        value: 'lo-ve-mal'
      }
    },
    required: true
  },
  'opinan-tus-maestros-o-patrones': {
    key: 'opinan-tus-maestros-o-patrones',
    label:
      '¿Qué opinan tus maestros/patrones de que tomes alcohol o pruebes drogas?',
    options: {
      'no-lo-sabe': {
        label: 'No lo sabe',
        value: 'no-lo-sabe'
      },
      'lo-ve-bien': {
        label: 'Lo ve bien',
        value: 'lo-ve-bien'
      },
      'no-lo-ve-bien-ni-mal': {
        label: 'No lo ve ni bien ni mal',
        value: 'no-lo-ve-bien-ni-mal'
      },
      'lo-ve-mal': {
        label: 'Lo ve mal',
        value: 'lo-ve-mal'
      }
    },
    required: true
  },
  'opina-tu-mejor-amigo': {
    key: 'opina-tu-mejor-amigo',
    label: '¿Qué opina tu mejor amigo de que tomes alcohol o pruebes drogas?',
    options: {
      'no-lo-sabe': {
        label: 'No lo sabe',
        value: 'no-lo-sabe'
      },
      'lo-ve-bien': {
        label: 'Lo ve bien',
        value: 'lo-ve-bien'
      },
      'no-lo-ve-bien-ni-mal': {
        label: 'No lo ve ni bien ni mal',
        value: 'no-lo-ve-bien-ni-mal'
      },
      'lo-ve-mal': {
        label: 'Lo ve mal',
        value: 'lo-ve-mal'
      }
    },
    required: true
  },
  'que-opinan-la-mayoria-de-jovenes': {
    key: 'que-opinan-la-mayoria-de-jovenes',
    label:
      '¿Qué opinan la mayoría de los jóvenes de tu edad de que tomes alcohol o pruebes drogas?',
    options: {
      'no-lo-sabe': {
        label: 'No lo sabe',
        value: 'no-lo-sabe'
      },
      'lo-ve-bien': {
        label: 'Lo ve bien',
        value: 'lo-ve-bien'
      },
      'no-lo-ve-bien-ni-mal': {
        label: 'No lo ve ni bien ni mal',
        value: 'no-lo-ve-bien-ni-mal'
      },
      'lo-ve-mal': {
        label: 'Lo ve mal',
        value: 'lo-ve-mal'
      }
    },
    required: true
  },
  'ha-usado-alguna-droga-ilegal-papa': {
    key: 'ha-usado-alguna-droga-ilegal-papa',
    label: '¿Tu papá ha usado algún tipo de droga ilegal?',
    options: {
      si: {
        label: 'Sí',
        value: 'si'
      },
      no: {
        label: 'No',
        value: 'no'
      }
    },
    required: true
  },
  'ha-usado-alguna-droga-ilegal-mama': {
    key: 'ha-usado-alguna-droga-ilegal-mama',
    label: '¿Tu mamá ha usado algún tipo de droga ilegal?',
    options: {
      si: {
        label: 'Sí',
        value: 'si'
      },
      no: {
        label: 'No',
        value: 'no'
      }
    },
    required: true
  },
  'ha-usado-alguna-droga-ilegal-hermanos': {
    key: 'ha-usado-alguna-droga-ilegal-hermanos',
    label: '¿Alguno de tus hermanos(as) ha usado algún tipo de droga ilegal?',
    options: {
      si: {
        label: 'Sí',
        value: 'si'
      },
      no: {
        label: 'No',
        value: 'no'
      }
    },
    required: true
  },
  'ha-usado-alguna-droga-ilegal-amigo': {
    key: 'ha-usado-alguna-droga-ilegal-amigo',
    label: '¿Tu mejor amigo ha usado algún tipo de droga ilegal?',
    options: {
      si: {
        label: 'Sí',
        value: 'si'
      },
      no: {
        label: 'No',
        value: 'no'
      }
    },
    required: true
  },
  'ha-usado-alguna-droga-ilegal-familiar': {
    key: 'ha-usado-alguna-droga-ilegal-familiar',
    label:
      '¿Algún familiar que viva contigo ha usado algún tipo de droga ilegal?',
    options: {
      si: {
        label: 'Sí',
        value: 'si'
      },
      no: {
        label: 'No',
        value: 'no'
      }
    },
    required: true
  },
  'ha-tenido-algun-problema-por-consumo-de-alcohol-papa': {
    key: 'ha-tenido-algun-problema-por-consumo-de-alcohol-papa',
    label: '¿Tu papá ha tenido algún problema debido a su consumo de alcohol?',
    options: {
      si: {
        label: 'Sí',
        value: 'si'
      },
      no: {
        label: 'No',
        value: 'no'
      }
    },
    required: true
  },
  'ha-tenido-algun-problema-por-consumo-de-alcohol-mama': {
    key: 'ha-tenido-algun-problema-por-consumo-de-alcohol-mama',
    label: '¿Tu mamá ha tenido algún problema debido a su consumo de alcohol?',
    options: {
      si: {
        label: 'Sí',
        value: 'si'
      },
      no: {
        label: 'No',
        value: 'no'
      }
    },
    required: true
  },
  'ha-tenido-algun-problema-por-consumo-de-alcohol-hermanos': {
    key: 'ha-tenido-algun-problema-por-consumo-de-alcohol-hermanos',
    label:
      '¿Alguno de tus hermanos(as) ha tenido algún problema debido a su consumo de alcohol?',
    options: {
      si: {
        label: 'Sí',
        value: 'si'
      },
      no: {
        label: 'No',
        value: 'no'
      }
    },
    required: true
  },
  'ha-tenido-algun-problema-por-consumo-de-alcohol-amigo': {
    key: 'ha-tenido-algun-problema-por-consumo-de-alcohol-amigo',
    label:
      '¿Tu mejor amigo ha tenido algún problema debido a su consumo de alcohol?',
    options: {
      si: {
        label: 'Sí',
        value: 'si'
      },
      no: {
        label: 'No',
        value: 'no'
      }
    },
    required: true
  },
  'ha-tenido-algun-problema-por-consumo-de-alcohol-familiar': {
    key: 'ha-tenido-algun-problema-por-consumo-de-alcohol-familiar',
    label:
      '¿Algún familiar que viva contigo ha tenido algún problema debido a su consumo de alcohol?',
    options: {
      si: {
        label: 'Sí',
        value: 'si'
      },
      no: {
        label: 'No',
        value: 'no'
      }
    },
    required: true
  }
};
