import { RouteLocationPathRaw } from 'vue-router';
export function generateCorrectRoutePath(
  route: RouteLocationPathRaw,
  pacientId?: string
) {
  if (route.path[0] !== '/') {
    // Due changes in the Route Meta objects and to facilitate re-use between
    // pacient and therapist as pacient routes we removed the absolute route path
    // i.e. before `/evaluacion/personal` after `evaluacion/personal`
    // So to keep everything working with the data that is already in the database
    // We are just prepending the docId with a foward slash
    route.path = `/${route.path}`;
  }
  if (pacientId) {
    route.path = `/${pacientId}${route.path}`;
  }
  return route;
}
export function generateCorrectRoute(path: string, pacientId?: string) {
  let newPath = path;
  if (path[0] !== '/') {
    // Due changes in the Route Meta objects and to facilitate re-use between
    // pacient and therapist as pacient routes we removed the absolute route path
    // i.e. before `/evaluacion/personal` after `evaluacion/personal`
    // So to keep everything working with the data that is already in the database
    // We are just prepending the docId with a foward slash
    newPath = `/${path}`;
  }
  if (pacientId) {
    newPath = `/${pacientId}${path}`;
  }
  return newPath;
}
