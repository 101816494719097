import { InitialEvaluationRoutes } from 'src/meta/initial-evaluation/InitialEvaluationMeta';
export const FIELDS = {
  title: {
    label: InitialEvaluationRoutes.FREE_TIME_INFO.title
  },
  'ves-television-en-casa': {
    key: 'ves-television-en-casa',
    label: '¿Ves televisión en casa?',
    options: {
      nunca: {
        label: 'Nunca',
        value: 'nunca'
      },
      'algunas-veces': {
        label: 'Algunas veces',
        value: 'algunas-veces'
      },
      'casi-siempre': {
        label: 'Casi siempre',
        value: 'casi-siempre'
      }
    },
    required: true
  },
  'practicas-algun-deporte': {
    key: 'practicas-algun-deporte',
    label: '¿Prácticas algún deporte?',
    options: {
      nunca: {
        label: 'Nunca',
        value: 'nunca'
      },
      'algunas-veces': {
        label: 'Algunas veces',
        value: 'algunas-veces'
      },
      'casi-siempre': {
        label: 'Casi siempre',
        value: 'casi-siempre'
      }
    },
    required: true
  },
  'juegas-o-sales-con-amigos': {
    key: 'juegas-o-sales-con-amigos',
    label: '¿Juegas o sales con amigos?',
    options: {
      nunca: {
        label: 'Nunca',
        value: 'nunca'
      },
      'algunas-veces': {
        label: 'Algunas veces',
        value: 'algunas-veces'
      },
      'casi-siempre': {
        label: 'Casi siempre',
        value: 'casi-siempre'
      }
    },
    required: true
  },
  'ayudas-en-las-labores-de-la-casa': {
    key: 'ayudas-en-las-labores-de-la-casa',
    label: '¿Ayudas en las labores de la casa?',
    options: {
      nunca: {
        label: 'Nunca',
        value: 'nunca'
      },
      'algunas-veces': {
        label: 'Algunas veces',
        value: 'algunas-veces'
      },
      'casi-siempre': {
        label: 'Casi siempre',
        value: 'casi-siempre'
      }
    },
    required: true
  },
  'haces-tarea-o-estudias': {
    key: 'haces-tarea-o-estudias',
    label: '¿Haces tarea o estudias?',
    options: {
      nunca: {
        label: 'Nunca',
        value: 'nunca'
      },
      'algunas-veces': {
        label: 'Algunas veces',
        value: 'algunas-veces'
      },
      'casi-siempre': {
        label: 'Casi siempre',
        value: 'casi-siempre'
      }
    },
    required: true
  },
  'juegas-videojuegos': {
    key: 'juegas-videojuegos',
    label: '¿Juegas en tu celular o con alguna consola?',
    options: {
      nunca: {
        label: 'Nunca',
        value: 'nunca'
      },
      'algunas-veces': {
        label: 'Algunas veces',
        value: 'algunas-veces'
      },
      'casi-siempre': {
        label: 'Casi siempre',
        value: 'casi-siempre'
      }
    },
    required: true
  },
  'sales-a-pasear-con-tu-familia': {
    key: 'sales-a-pasear-con-tu-familia',
    label: '¿Sales a pasear con tu familia?',
    options: {
      nunca: {
        label: 'Nunca',
        value: 'nunca'
      },
      'algunas-veces': {
        label: 'Algunas veces',
        value: 'algunas-veces'
      },
      'casi-siempre': {
        label: 'Casi siempre',
        value: 'casi-siempre'
      }
    },
    required: true
  },
  'vas-a-beber-con-tus-amigos': {
    key: 'vas-a-beber-con-tus-amigos',
    label: '¿Sales a beber con tus amigos?',
    options: {
      nunca: {
        label: 'Nunca',
        value: 'nunca'
      },
      'algunas-veces': {
        label: 'Algunas veces',
        value: 'algunas-veces'
      },
      'casi-siempre': {
        label: 'Casi siempre',
        value: 'casi-siempre'
      }
    },
    required: true
  },
  'escuchas-musica': {
    key: 'escuchas-musica',
    label: '¿Escuchas música?',
    options: {
      nunca: {
        label: 'Nunca',
        value: 'nunca'
      },
      'algunas-veces': {
        label: 'Algunas veces',
        value: 'algunas-veces'
      },
      'casi-siempre': {
        label: 'Casi siempre',
        value: 'casi-siempre'
      }
    },
    required: true
  },
  'hablas-por-telefono': {
    key: 'hablas-por-telefono',
    label: '¿Hablas por teléfono?',
    options: {
      nunca: {
        label: 'Nunca',
        value: 'nunca'
      },
      'algunas-veces': {
        label: 'Algunas veces',
        value: 'algunas-veces'
      },
      'casi-siempre': {
        label: 'Casi siempre',
        value: 'casi-siempre'
      }
    },
    required: true
  },
  'vas-a-fiestas': {
    key: 'vas-a-fiestas',
    label: '¿Vas a fiestas?',
    options: {
      nunca: {
        label: 'Nunca',
        value: 'nunca'
      },
      'algunas-veces': {
        label: 'Algunas veces',
        value: 'algunas-veces'
      },
      'casi-siempre': {
        label: 'Casi siempre',
        value: 'casi-siempre'
      }
    },
    required: true
  },
  'asistes-a-clases-artisticas': {
    key: 'asistes-a-clases-artisticas',
    label:
      '¿Asistes a clases artísticas (baile, tocar un instrumento, etcétera)?',
    options: {
      nunca: {
        label: 'Nunca',
        value: 'nunca'
      },
      'algunas-veces': {
        label: 'Algunas veces',
        value: 'algunas-veces'
      },
      'casi-siempre': {
        label: 'Casi siempre',
        value: 'casi-siempre'
      }
    },
    required: true
  },
  'vas-al-cine': {
    key: 'vas-al-cine',
    label: '¿Vas al cine?',
    options: {
      nunca: {
        label: 'Nunca',
        value: 'nunca'
      },
      'algunas-veces': {
        label: 'Algunas veces',
        value: 'algunas-veces'
      },
      'casi-siempre': {
        label: 'Casi siempre',
        value: 'casi-siempre'
      }
    },
    required: true
  },
  'otra-actividad': {
    key: 'otra-actividad',
    label: '¿Otra actividad?',
    required: false
  }
};
