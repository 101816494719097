import { FlowState } from 'src/composables/FlowState';
import { InitialEvaluationURLs } from 'src/meta/initial-evaluation/InitialEvaluationMeta';

export class FlowStateInitialEvaluation extends FlowState {
  constructor(db: PouchDB.Database) {
    super('flowStateInitialEvaluation', InitialEvaluationURLs, db);
  }
}

export async function useFlowStateInitialEvaluation(db: PouchDB.Database) {
  const flowStatePaciente = new FlowStateInitialEvaluation(db);
  await flowStatePaciente.load_current_state();
  return flowStatePaciente;
}
