import PouchDB from 'pouchdb';
import PouchWorker from 'worker-pouch';
import { boot } from 'quasar/wrappers';
// import { nextTick } from 'vue';

// "async" is optional;
// more info on params: https://v2.quasar.dev/quasar-cli/boot-files
export default boot( (/* { app, router, ... } */) => {
  // something to do
  // window.process = window.process || {};
  // window.process.nextTick = (function () {
  //   const canSetImmediate =
  //     typeof window !== 'undefined' && window.setImmediate;
  //   const canPost = typeof window !== 'undefined' && window.addEventListener;
  //   if (canSetImmediate) {
  //     return function (f) {
  //       return window.setImmediate(f);
  //     };
  //   }
  //   if (canPost) {
  //     const queue: (() => void)[] = [];
  //     window.addEventListener(
  //       'message',
  //       function (ev) {
  //         const source = ev.source;
  //         if (
  //           (source === window || source === null) &&
  //           ev.data === 'process-tick'
  //         ) {
  //           ev.stopPropagation();
  //           if (queue.length > 0) {
  //             const fn = queue.shift();
  //             if (fn) {
  //               fn();
  //             }
  //           }
  //         }
  //       },
  //       true
  //     );
  //     return function nextTick(fn) {
  //       queue.push(fn);
  //       window.postMessage('process-tick', '*');
  //     };
  //   }
  //   return function nextTick(fn) {
  //     setTimeout(fn, 0);
  //   };
  // })();
  // window.global = window as unknown as NodeJS.Global & typeof globalThis;
  // @ts-expect-error: Property 'adapter' does not exist on type 'Static<{}>'
  PouchDB.adapter('worker', PouchWorker);
});
