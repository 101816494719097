import { PouchDoc } from 'src/types/pouchdb/pouchtypes';
import { RouteLocationPathRaw } from 'vue-router';
import { State } from 'src/composables/State';
import { flowStateDocType } from 'src/types/flowState/flowStateTypes.d';

interface updateFlowStateReturn {
  state: State;
  route: RouteLocationPathRaw;
}
export abstract class FlowState {
  state: State;
  db: PouchDB.Database;
  flowStateDocId: string;
  flowStateURLs: string[];
  flowStateDoc?: PouchDoc<flowStateDocType>;
  _build_state(url: string) {
    return new State({
      currentURL: url,
      flowStateURLs: this.flowStateURLs
    });
  }
  _build_state_index(index: number) {
    return new State({
      currentIndex: index,
      flowStateURLs: this.flowStateURLs
    });
  }
  private async _load_current_state() {
    this.flowStateDoc = await this.db.get<flowStateDocType>(
      this.flowStateDocId
    );
    this.state = this._build_state(this.flowStateDoc.key);
    return this.state;
  }
  async load_current_state() {
    // Will fetch the current state that is saved
    // It will act as a `get_or_create` method to access the state doc
    try {
      return await this._load_current_state();
    } catch {
      console.log('No current state, Creating new one...');
      await this._create_flow_state_doc();
      return await this._load_current_state();
    }
  }
  async set_current_state(nextState: State) {
    const newFlowState = {
      ...this.flowStateDoc,
      key: nextState.url,
      value: nextState._stateIndex
    };
    await this.db.put(newFlowState);
  }
  async _create_flow_state_doc() {
    const flowState = {
      _id: this.flowStateDocId,
      key: this.flowStateURLs[1],
      value: 1
    };
    return this.db.put<flowStateDocType>(flowState);
  }
  verifyAuthFlowState(toPath: string) {
    // We would check if the User has already finished the initial interview and
    // can proceed to the URL `toPath` otherwise we would return an object
    // containing the next URL the user must visit
    // e.g. `{path: "/evaluacion/historia-de-drogas"}`
    // if the user already completed the initial interview we would return `undefined`
    const toState = this._build_state(toPath);
    if (!this.state.can_proceed(toState)) {
      return { path: this.state.url };
    }
  }
  /**
   *
   * @param nextState
   */
  async updateFlowState(nextState: State): Promise<updateFlowStateReturn> {
    // Here we would only update the doc `flowStateInitialEvaluation` with the
    // newPath to keep track of the user progress and be able to resume it
    // if he leaves the initial interview
    if (this.state.value < nextState.value) {
      await this.set_current_state(nextState);
      this.state = nextState;
    }
    // Also we will use this method to abstract the behavior a FlowState should do after
    // advancing to another, e.g. for the initial interview just need to redirect to the next state.url
    // but for weekly sessions we redirect to the dashboard after each session
    const nextRoute: RouteLocationPathRaw = {
      path: nextState.url
    };
    return { state: nextState, route: nextRoute };
  }
  constructor(
    flowStateDocId: string,
    flowStateURLs: string[],
    db: PouchDB.Database,
    state?: State
  ) {
    this.flowStateDocId = flowStateDocId;
    this.flowStateURLs = flowStateURLs;
    this.db = db;
    this.state = state ?? this._build_state('/');
  }
}
