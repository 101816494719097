import { InitialEvaluationRoutes } from 'src/meta/initial-evaluation/InitialEvaluationMeta';
import { RelativeDatesOptions } from './InitialEvaluationMeta';
export const FIELDS = {
  title: {
    label: InitialEvaluationRoutes.AREA_SCHOOL_WORK.title
  },
  'ocupacion-principal': {
    key: 'ocupacion-principal',
    label: '¿Cuál es tu ocupación principal?',
    options: {
      Estudiar: {
        label: 'Estudiar',
        value: 'Estudiar'
      },
      Trabajar: {
        label: 'Trabajar',
        value: 'Trabajar'
      },
      'Estudiar y trabajar': {
        label: 'Estudiar y trabajar',
        value: 'Estudiar y trabajar'
      },
      ninguna: {
        label: 'Ninguna',
        value: 'ninguna'
      }
    },
    required: true
  },
  'fuiste-estudiante-el-año-pasado': {
    key: 'fuiste-estudiante-el-año-pasado',
    label: 'La mayor parte del año pasado ¿fuiste estudiante?',
    options: {
      si: {
        label: 'Sí',
        value: 'si'
      },
      no: {
        label: 'No',
        value: 'no'
      }
    },
    required: true
  },
  'asistes-a-la-escuela-de-manera-regular': {
    key: 'asistes-a-la-escuela-de-manera-regular',
    label: '¿Asistes a la escuela de manera regular?',
    options: {
      si: {
        label: 'Sí',
        value: 'si'
      },
      no: {
        label: 'No',
        value: 'no'
      }
    },
    required: true
  },
  'promedio-escolar': {
    key: 'promedio-escolar',
    label: '¿Cuál es tu promedio escolar?',
    required: true
  },
  'consideras-tu-desempeño-escolar': {
    key: 'consideras-tu-desempeño-escolar',
    label: '¿Cómo consideras tu desempeño escolar?',
    options: {
      muyBueno: {
        label: 'Muy bueno',
        value: 'muyBueno'
      },
      bueno: {
        label: 'Bueno',
        value: 'bueno'
      },
      regular: {
        label: 'Regular',
        value: 'regular'
      },
      malo: {
        label: 'Malo',
        value: 'malo'
      },
      muyMalo: {
        label: 'Muy malo',
        value: 'muyMalo'
      }
    },
    required: true
  },
  'has-dejado-tus-estudios-durante-seis-meses': {
    key: 'has-dejado-tus-estudios-durante-seis-meses',
    label: '¿Has dejado de estudiar durante 6 meses o más?',
    options: {
      si: {
        label: 'Sí',
        value: 'si'
      },
      no: {
        label: 'No',
        value: 'no'
      }
    },
    required: true
  },
  'en-que-trabajas': {
    key: 'en-que-trabajas',
    label: '¿En qué trabajas?',
    required: true
  },
  'cuantas-horas-diarias-trabajas': {
    key: 'cuantas-horas-diarias-trabajas',
    label: '¿Cuántas horas diarias trabajas?',
    options: {
      '8horas': {
        label: 'Aproximadamente 8 horas',
        value: '8horas'
      },
      '4horas': {
        label: 'Aproximadamente 4 horas',
        value: '4horas'
      },
      'trabaja por su cuenta': {
        label: 'Trabajo por mi cuenta',
        value: 'trabaja por su cuenta'
      },
      'trabajo ocasional': {
        label: 'Trabajo ocasionalmente',
        value: 'trabajo ocasional'
      }
    },
    required: true
  },
  'desde-cuando-trabajas': {
    key: 'desde-cuando-trabajas',
    label: '¿Desde cuándo trabajas?',
    options: RelativeDatesOptions,
    required: true
  },
  'cuanto-ganas-mes': {
    key: 'cuanto-ganas-mes',
    label: '¿Cuánto ganas al mes?',
    required: true
  }
};
