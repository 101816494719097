import { InitialEvaluationRoutes } from 'src/meta/initial-evaluation/InitialEvaluationMeta';
import { RelativeDatesOptions } from 'src/meta/initial-evaluation/InitialEvaluationMeta';
export const FIELDS = {
  title: {
    label: InitialEvaluationRoutes.ALCOHOL_HISTORY.title
  },
  'has-tomado-una-cerveza-o-cuba': {
    key: 'has-tomado-una-cerveza-o-cuba',
    label:
      '¿Alguna vez en tu vida has tomado una cerveza completa o una cuba de cualquier otra bebida alcohólica?',
    options: {
      si: {
        label: 'Sí',
        value: 'si'
      },
      no: {
        label: 'No',
        value: 'no'
      }
    },
    required: true
  },
  'has-tomado-vino': {
    key: 'has-tomado-vino',
    label: '¿Has tomado una copa completa de vino (blanco, tinto, rosado)?',
    options: {
      si: {
        label: 'Sí',
        value: 'si'
      },
      no: {
        label: 'No',
        value: 'no'
      }
    },
    required: true
  },
  'has-tomado-coolers': {
    key: 'has-tomado-coolers',
    label:
      '¿Has tomado una copa completa de bebidas como "coolers", "viña real", etc?',
    options: {
      si: {
        label: 'Sí',
        value: 'si'
      },
      no: {
        label: 'No',
        value: 'no'
      }
    },
    required: true
  },
  'has-tomado-brandy': {
    key: 'has-tomado-brandy',
    label:
      '¿Has tomado una copa completa de brandy, vodka, tequila, ron, whisky, etc.?',
    options: {
      si: {
        label: 'Sí',
        value: 'si'
      },
      no: {
        label: 'No',
        value: 'no'
      }
    },
    required: true
  },
  'has-tomado-bebidas-preparadas': {
    key: 'has-tomado-bebidas-preparadas',
    label: '¿Has tomado una copa completa de bebidas preparadas de lata?',
    options: {
      si: {
        label: 'Sí',
        value: 'si'
      },
      no: {
        label: 'No',
        value: 'no'
      }
    },
    required: true
  },
  'has-tomado-pulque': {
    key: 'has-tomado-pulque',
    label: '¿Has tomado una copa completa de pulque?',
    options: {
      si: {
        label: 'Sí',
        value: 'si'
      },
      no: {
        label: 'No',
        value: 'no'
      }
    },
    required: true
  },
  'has-tomado-aguardiente': {
    key: 'has-tomado-aguardiente',
    label: '¿Has tomado una copa completa de alchol puro o aguardiente?',
    options: {
      si: {
        label: 'Sí',
        value: 'si'
      },
      no: {
        label: 'No',
        value: 'no'
      }
    },
    required: true
  },
  'que-edad-tenias-la-primera-vez-tomaste-una-copa-completa': {
    key: 'que-edad-tenias-la-primera-vez-tomaste-una-copa-completa',
    label:
      '¿Qué edad tenías la primara vez que tomaste una copa completa de alguna de las bebidas anteriores?',
    required: true
  },
  'cuantas-veces-tomaste-en-el-ultimo-año': {
    key: 'cuantas-veces-tomaste-en-el-ultimo-año',
    label: '¿Cuántas veces tomaste en el último año?',
    subLabel: 'Una o más copas de cualquier bebida alcohólica',
    options: {
      MenosdeUna: {
        label: 'Menos de una vez',
        value: 'MenosdeUna'
      },
      UnaaDos: {
        label: 'De 1 a 2 veces',
        value: 'UnaaDos'
      },
      TresaOnce: {
        label: 'De 3 a 11 veces',
        value: 'TresaOnce'
      },
      UnaaTresMes: {
        label: 'De 1 a 3 veces en el último mes',
        value: 'UnaaTresMes'
      },
      UnaoMasMes: {
        label: 'Una o más veces en la última semana',
        value: 'UnaoMasMes'
      }
    },
    required: true
  },
  'cuantas-veces-tomaste-en-el-ultimo-mes-una-o-mas-copas': {
    key: 'cuantas-veces-tomaste-en-el-ultimo-mes-una-o-mas-copas',
    label: '¿Cuántas veces tomaste en el último mes?',
    subLabel: 'Una o más copas de cualquier bebida alcohólica',
    options: {
      nunca: {
        label: 'Nunca',
        value: 'nunca'
      },
      una: {
        label: 'Una vez',
        value: 'una'
      },
      dostres: {
        label: 'De 2 a 3 veces',
        value: 'dostres'
      },
      unaoMasMes: {
        label: 'Una o más veces en la última semana',
        value: 'unaoMasMes'
      }
    },
    required: true
  },
  'cuantas-veces-tomaste-en-el-ultimo-mes-cinco-o-mas-copas': {
    key: 'cuantas-veces-tomaste-en-el-ultimo-mes-cinco-o-mas-copas',
    label: '¿Cuántas veces tomaste en el último mes?',
    subLabel: '5 o más copas de cualquier bebida alcohólica',
    options: {
      nunca: {
        label: 'Nunca',
        value: 'nunca'
      },
      una: {
        label: 'Una vez',
        value: 'una'
      },
      dostres: {
        label: 'De 2 a 3 veces',
        value: 'dostres'
      },
      unaoMasMes: {
        label: 'Una o más veces en la última semana',
        value: 'unaoMasMes'
      }
    },
    required: true
  },
  'cuantas-veces-te-emborrachaste-en-el-ultimo-mes': {
    key: 'cuantas-veces-te-emborrachaste-en-el-ultimo-mes',
    label: '¿Cuántas veces te emborrachaste en el último mes?',
    options: {
      nunca: {
        label: 'Nunca',
        value: 'nunca'
      },
      una: {
        label: 'Una vez',
        value: 'una'
      },
      dostres: {
        label: 'De 2 a 3 veces',
        value: 'dostres'
      },
      unaoMasMes: {
        label: 'Una o más veces en la última semana',
        value: 'unaoMasMes'
      }
    },
    required: true
  },
  'has-intentado-tomar-menos-o-dejar-de-tomar': {
    key: 'has-intentado-tomar-menos-o-dejar-de-tomar',
    label: '¿Has intentado tomar menos copas por ocasión, o dejar de tomar?',
    options: {
      si: {
        label: 'Sí',
        value: 'si'
      },
      no: {
        label: 'No',
        value: 'no'
      }
    },
    required: true
  },
  'desde-cuando-iniciaste-a-cambiar-tu-consumo': {
    key: 'desde-cuando-iniciaste-a-cambiar-tu-consumo',
    label: '¿Desde cuándo iniciaste a cambiar tu consumo?',
    options: RelativeDatesOptions,
    required: true
  },
  'porque-hiciste-el-cambio': {
    key: 'porque-hiciste-el-cambio',
    label: '¿Por qué hiciste el cambio?',
    required: true
  },
  'todavia-te-encuentras-realizando-este-cambio': {
    key: 'todavia-te-encuentras-realizando-este-cambio',
    label: '¿Todavía te encuentras realizando este cambio?',
    options: {
      si: {
        label: 'Sí',
        value: 'si'
      },
      no: {
        label: 'No',
        value: 'no'
      }
    },
    required: true
  },
  'en-que-plazo-te-gustaria-intentar-tomar-menos': {
    key: 'en-que-plazo-te-gustaria-intentar-tomar-menos',
    label: '¿En qué plazo te gustaría intentar tomar menos alcohol?',
    options: {
      '6meses': {
        label: 'En los próximos 6 meses',
        value: '6meses'
      },
      proximoMes: {
        label: 'En el próximo mes',
        value: 'proximoMes'
      }
    },
    required: true
  }
};
