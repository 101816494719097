import { FIELDS } from 'src/meta/IndicadoresDependenciaMeta';
import { Icontext } from 'src/types/blitzar';
import { IndicadoresDependenciaData } from 'src/types/initial-evaluation/evaluacion-personal';
import { InitialEvaluationMetaTitleClasses } from 'src/meta/initial-evaluation/InitialEvaluationMeta';

type ctx = Icontext<Partial<IndicadoresDependenciaData>>;

export const IndicadoresDepSchema = [
  {
    component: 'p',
    slot: FIELDS.title.label,
    componentClasses: InitialEvaluationMetaTitleClasses
  },
  {
    id: FIELDS['DSMV_1'].key,
    label: FIELDS['DSMV_1'].label,
    required: FIELDS['DSMV_1'].required,
    options: Object.values(FIELDS['DSMV_1'].options),
    component: 'q-option-group',
    type: 'radio'
  },
  {
    id: FIELDS['DSMV_2'].key,
    label: FIELDS['DSMV_2'].label,
    required: FIELDS['DSMV_2'].required,
    options: Object.values(FIELDS['DSMV_2'].options),
    component: 'q-option-group',
    type: 'radio'
  },
  {
    id: FIELDS['DSMV_3'].key,
    label: FIELDS['DSMV_3'].label,
    required: FIELDS['DSMV_3'].required,
    options: Object.values(FIELDS['DSMV_3'].options),
    component: 'q-option-group',
    type: 'radio'
  },
  {
    id: FIELDS['DSMV_4'].key,
    label: FIELDS['DSMV_4'].label,
    required: FIELDS['DSMV_4'].required,
    options: Object.values(FIELDS['DSMV_4'].options),
    component: 'q-option-group',
    type: 'radio'
  },
  {
    id: FIELDS['DSMV_5'].key,
    label: FIELDS['DSMV_5'].label,
    required: FIELDS['DSMV_5'].required,
    options: Object.values(FIELDS['DSMV_5'].options),
    component: 'q-option-group',
    type: 'radio'
  },
  {
    id: FIELDS['DSMV_6'].key,
    label: FIELDS['DSMV_6'].label,
    required: FIELDS['DSMV_6'].required,
    options: Object.values(FIELDS['DSMV_6'].options),
    component: 'q-option-group',
    type: 'radio'
  },
  {
    id: FIELDS['DSMV_7'].key,
    label: FIELDS['DSMV_7'].label,
    required: FIELDS['DSMV_7'].required,
    options: Object.values(FIELDS['DSMV_7'].options),
    component: 'q-option-group',
    type: 'radio'
  },
  {
    id: FIELDS['DSMV_8'].key,
    label: FIELDS['DSMV_8'].label,
    required: FIELDS['DSMV_8'].required,
    options: Object.values(FIELDS['DSMV_8'].options),
    component: 'q-option-group',
    type: 'radio'
  },
  {
    id: FIELDS['DSMV_9'].key,
    label: FIELDS['DSMV_9'].label,
    required: FIELDS['DSMV_9'].required,
    options: Object.values(FIELDS['DSMV_9'].options),
    component: 'q-option-group',
    type: 'radio'
  },
  {
    id: FIELDS['DSMV_10'].key,
    label: FIELDS['DSMV_10'].label,
    required: FIELDS['DSMV_10'].required,
    options: Object.values(FIELDS['DSMV_10'].options),
    component: 'q-option-group',
    type: 'radio'
  },
  {
    id: FIELDS['DSMV_11'].key,
    label: FIELDS['DSMV_11'].label,
    required: FIELDS['DSMV_11'].required,
    options: Object.values(FIELDS['DSMV_11'].options),
    component: 'q-option-group',
    type: 'radio'
  }
];
