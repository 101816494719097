import { AreaSexualidadData } from 'src/types/initial-evaluation/evaluacion-personal';
import { FIELDS } from 'src/meta/initial-evaluation/AreaSexualidadMeta';
import { Icontext } from 'src/types/blitzar';
import { InitialEvaluationMetaTitleClasses } from 'src/meta/initial-evaluation/InitialEvaluationMeta';

type ctx = Icontext<Partial<AreaSexualidadData>>;
export const AreaSexualidadSchema = [
  {
    component: 'p',
    slot: FIELDS.title.label,
    componentClasses: InitialEvaluationMetaTitleClasses
  },
  {
    id: FIELDS['tienes-novi@'].key,
    label: FIELDS['tienes-novi@'].label,
    required: FIELDS['tienes-novi@'].required,
    options: Object.values(FIELDS['tienes-novi@'].options),
    component: 'QOptionWrapper',
    type: 'radio'
  },
  {
    id: FIELDS['cuando-iniciaste-la-relacion'].key,
    label: FIELDS['cuando-iniciaste-la-relacion'].label,
    options: FIELDS['cuando-iniciaste-la-relacion'].options,
    component: 'SelectOrComponent',
    otherComponent: 'q-input',
    defaultValueForOther: '',
    type: 'date',
    dynamicProps: ['showCondition', 'required'],
    required: (val: string, { formData }: ctx) =>
      formData['tienes-novi@'] === 'si',
    showCondition: (val: string, { formData }: ctx) =>
      formData['tienes-novi@'] === 'si'
  },
  {
    id: FIELDS['has-tenido-relaciones-sexuales'].key,
    label: FIELDS['has-tenido-relaciones-sexuales'].label,
    required: FIELDS['has-tenido-relaciones-sexuales'].required,
    options: Object.values(FIELDS['has-tenido-relaciones-sexuales'].options),
    component: 'QOptionWrapper',
    type: 'radio'
  },
  {
    id: FIELDS['a-que-edad-iniciaste-relaciones-sexuales'].key,
    label: FIELDS['a-que-edad-iniciaste-relaciones-sexuales'].label,
    component: 'q-input',
    type: 'number',
    min: 0,
    max: 150,
    dynamicProps: ['showCondition', 'required'],
    required: (val: string, { formData }: ctx) =>
      formData['has-tenido-relaciones-sexuales'] === 'si',

    showCondition: (val: string, { formData }: ctx) =>
      formData['has-tenido-relaciones-sexuales'] === 'si'
  },
  {
    id: FIELDS['utilizas-algun-metodo-anticonceptivo'].key,
    label: FIELDS['utilizas-algun-metodo-anticonceptivo'].label,
    options: Object.values(
      FIELDS['utilizas-algun-metodo-anticonceptivo'].options
    ),
    component: 'QOptionWrapper',
    type: 'radio',
    dynamicProps: ['showCondition', 'required'],
    required: (val: string, { formData }: ctx) =>
      formData['has-tenido-relaciones-sexuales'] === 'si',
    showCondition: (val: string, { formData }: ctx) =>
      formData['has-tenido-relaciones-sexuales'] === 'si'
  },
  {
    id: FIELDS['cual-metodo-anticonceptivo'].key,
    label: FIELDS['cual-metodo-anticonceptivo'].label,
    component: 'q-input',
    type: 'text',
    dynamicProps: ['showCondition', 'required'],
    required: (val: string, { formData }: ctx) =>
      formData['has-tenido-relaciones-sexuales'] === 'si' &&
      formData['utilizas-algun-metodo-anticonceptivo'] === 'si',
    showCondition: (val: string, { formData }: ctx) =>
      formData['has-tenido-relaciones-sexuales'] === 'si' &&
      formData['utilizas-algun-metodo-anticonceptivo'] === 'si'
  },
  {
    id: FIELDS['con-que-frecuencia-utilizas-medoto-anticonceptivo'].key,
    label: FIELDS['con-que-frecuencia-utilizas-medoto-anticonceptivo'].label,
    options: Object.values(
      FIELDS['con-que-frecuencia-utilizas-medoto-anticonceptivo'].options
    ),
    component: 'QOptionWrapper',
    type: 'radio',
    dynamicProps: ['showCondition', 'required'],
    required: (val: string, { formData }: ctx) =>
      formData['has-tenido-relaciones-sexuales'] === 'si' &&
      formData['utilizas-algun-metodo-anticonceptivo'] === 'si',
    showCondition: (val: string, { formData }: ctx) =>
      formData['has-tenido-relaciones-sexuales'] === 'si' &&
      formData['utilizas-algun-metodo-anticonceptivo'] === 'si'
  },
  {
    id: FIELDS['has-tenido-problemas-relacionados-conducta-sexual'].key,
    label: FIELDS['has-tenido-problemas-relacionados-conducta-sexual'].label,
    options: Object.values(
      FIELDS['has-tenido-problemas-relacionados-conducta-sexual'].options
    ),
    component: 'QOptionWrapper',
    type: 'radio',
    dynamicProps: ['showCondition', 'required'],
    required: (val: string, { formData }: ctx) =>
      formData['has-tenido-relaciones-sexuales'] === 'si',

    showCondition: (val: string, { formData }: ctx) =>
      formData['has-tenido-relaciones-sexuales'] === 'si'
  },
  {
    id: FIELDS['has-tenido-relaciones-despues-de-consumir-alcohol-o-drogas']
      .key,
    label:
      FIELDS['has-tenido-relaciones-despues-de-consumir-alcohol-o-drogas']
        .label,
    options: Object.values(
      FIELDS['has-tenido-relaciones-despues-de-consumir-alcohol-o-drogas']
        .options
    ),
    component: 'QOptionWrapper',
    type: 'radio',
    dynamicProps: ['showCondition', 'required'],
    required: (val: string, { formData }: ctx) =>
      formData['has-tenido-relaciones-sexuales'] === 'si',
    showCondition: (val: string, { formData }: ctx) =>
      formData['has-tenido-relaciones-sexuales'] === 'si'
  }
];
