import { InitialEvaluationRoutes } from 'src/meta/initial-evaluation/InitialEvaluationMeta';
export const FIELDS = {
  title: {
    label: InitialEvaluationRoutes.ANTISOCIAL_CONDUCT.title
  },
  'has-tomado-un-auto-sin-permiso': {
    key: 'has-tomado-un-auto-sin-permiso',
    label: '¿Has tomado un auto sin permiso del dueño?',
    options: {
      si: {
        label: 'Sí',
        value: 'si'
      },
      no: {
        label: 'No',
        value: 'no'
      }
    },
    required: true
  },
  'has-tomado-dinero-o-cosas-con-valor-de-$50-o-menos-que-no-te-pertenecen': {
    key: 'has-tomado-dinero-o-cosas-con-valor-de-$50-o-menos-que-no-te-pertenecen',
    label:
      '¿Has tomado dinero o cosas con valor de $50 pesos o menos que no te pertenecen?',
    options: {
      si: {
        label: 'Sí',
        value: 'si'
      },
      no: {
        label: 'No',
        value: 'no'
      }
    },
    required: true
  },
  'has-tomado-dinero-o-cosas-con-valor-de-$500-o-mas-que-no-te-pertenecen': {
    key: 'has-tomado-dinero-o-cosas-con-valor-de-$500-o-mas-que-no-te-pertenecen',
    label:
      '¿Has tomado dinero o cosas con valor de $500 pesos o más que no te pertenecen?',
    options: {
      si: {
        label: 'Sí',
        value: 'si'
      },
      no: {
        label: 'No',
        value: 'no'
      }
    },
    required: true
  },
  'has-forzado-cerraduras-para-entrar-algun-lugar-que-no-sea-tu-casa': {
    key: 'has-forzado-cerraduras-para-entrar-algun-lugar-que-no-sea-tu-casa',
    label:
      '¿Has forzado cerraduras para entrar a algún lugar que no sea tu casa?',
    options: {
      si: {
        label: 'Sí',
        value: 'si'
      },
      no: {
        label: 'No',
        value: 'no'
      }
    },
    required: true
  },
  'has-tomado-mercancia-de-una-tienda-sin-pagarla': {
    key: 'has-tomado-mercancia-de-una-tienda-sin-pagarla',
    label: '¿Has tomado mercancia de alguna tienda sin pagarla (causar daños)?',
    options: {
      si: {
        label: 'Sí',
        value: 'si'
      },
      no: {
        label: 'No',
        value: 'no'
      }
    },
    required: true
  },
  'has-golpeado-o-dañado-algo-que-no-te-pertenece': {
    key: 'has-golpeado-o-dañado-algo-que-no-te-pertenece',
    label:
      '¿Has golpeado o dañado algo (objeto o propiedad) que no te pertenece?',
    options: {
      si: {
        label: 'Sí',
        value: 'si'
      },
      no: {
        label: 'No',
        value: 'no'
      }
    },
    required: true
  },
  'has-atacado-a-alguien-usando-un-objeto-como-arma': {
    key: 'has-atacado-a-alguien-usando-un-objeto-como-arma',
    label:
      '¿Has atacado a alguien usando algún objeto como arma (cuchillo, palo, navaja, etcétera)?',
    options: {
      si: {
        label: 'Sí',
        value: 'si'
      },
      no: {
        label: 'No',
        value: 'no'
      }
    },
    required: true
  },
  'has-vendido-drogas-marihuana-cocaina-etc': {
    key: 'has-vendido-drogas-marihuana-cocaina-etc',
    label: '¿Has vendido alguna droga (marihuana, cocaína, etcétera)?',
    options: {
      si: {
        label: 'Sí',
        value: 'si'
      },
      no: {
        label: 'No',
        value: 'no'
      }
    },
    required: true
  },
  'has-golpeado-herido-a-proposito-a-alguien-sin-considerar-a-tus-hermanos': {
    key: 'has-golpeado-herido-a-proposito-a-alguien-sin-considerar-a-tus-hermanos',
    label:
      '¿Has golpeado o herido a propósito a alguien, sin considerar pleitos o discusiones con tus hermanos?',
    options: {
      si: {
        label: 'Sí',
        value: 'si'
      },
      no: {
        label: 'No',
        value: 'no'
      }
    },
    required: true
  },
  'has-formado-parte-de-riñas-o-peleas': {
    key: 'has-formado-parte-de-riñas-o-peleas',
    label: '¿Has formado parte en riñas o peleas?',
    options: {
      si: {
        label: 'Sí',
        value: 'si'
      },
      no: {
        label: 'No',
        value: 'no'
      }
    },
    required: true
  },
  'has-encendido-fuego-a-proposito-a-objetos-que-no-te-pertenecen': {
    key: 'has-encendido-fuego-a-proposito-a-objetos-que-no-te-pertenecen',
    label: '¿Has encendido fuego a propósito a objetos que no te pertenecen?',
    options: {
      si: {
        label: 'Sí',
        value: 'si'
      },
      no: {
        label: 'No',
        value: 'no'
      }
    },
    required: true
  },
  'has-usado-un-cuchillo-o-pistola-para-obtener-algun-objeto-de-otra-persona': {
    key: 'has-usado-un-cuchillo-o-pistola-para-obtener-algun-objeto-de-otra-persona',
    label:
      '¿Has usado un cuchillo o pistola para obtener algún objeto de otra persona?',
    options: {
      si: {
        label: 'Sí',
        value: 'si'
      },
      no: {
        label: 'No',
        value: 'no'
      }
    },
    required: true
  },
  'edad-de-inicio-primera-vez-que-realizaste-estas-actividades': {
    key: 'edad-de-inicio-primera-vez-que-realizaste-estas-actividades',
    label:
      '¿Qué edad tenias la primera vez que realzaste alguna de las actividades anteriores?',
    required: true
  },
  'tus-amigos-han-realizado-alguna-de-las-activadades-anteriores': {
    key: 'tus-amigos-han-realizado-alguna-de-las-activadades-anteriores',
    label: '¿Tus amigos han realizado alguna de las actividades anteriores?',
    options: {
      si: {
        label: 'Sí',
        value: 'si'
      },
      no: {
        label: 'No',
        value: 'no'
      }
    },
    required: true
  },
  'cuales-actividades-han-realizado-tus-amigos': {
    key: 'cuales-actividades-han-realizado-tus-amigos',
    label: '¿Cuáles?',
    required: true
  }
};
