import { FlowState } from 'src/composables/FlowState';
import { FollowUpSixMonthURLs } from 'src/meta/follow-up/six-months';

export class FlowStateFollowUpSixMonths extends FlowState {
  constructor(db: PouchDB.Database) {
    super('flowStateFollowUpSixMonths', FollowUpSixMonthURLs, db);
  }
}

export async function useFlowStateFollowUpSixMonths(db: PouchDB.Database) {
  const flowStatePaciente = new FlowStateFollowUpSixMonths(db);
  await flowStatePaciente.load_current_state();
  return flowStatePaciente;
}
