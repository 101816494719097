import { EndOfSessionArgs } from 'src/pages/sesiones/SesionPresentationManagerType';
import { FollowUpRoutesGuarded } from './FollowUpRoutes';
import { InitialEvaluationRoutes } from 'src/meta/initial-evaluation/InitialEvaluationMeta';
import { RouteRecordRaw } from 'vue-router';
import { RoutesMeta } from './routesMeta';
import { date } from 'quasar';
import { defineAsyncComponent } from 'vue';
import { useFlowSessionFollowUp } from 'src/composables/useFlowSessionFollowUp';
const exampleSituacionesConsumo = [
  {
    question: '¿Cuál fue esta situación?',
    answer: 'En mi último cumpleaños hice una fiesta y bebimos mucho '
  },
  {
    question: '¿Qué consumí?',
    answer: 'Cervezas y shots de tequila '
  },
  {
    question: '¿Cuánto consumí?',
    answer: '11 tragos '
  },
  {
    question: 'Cosas positivas',
    answer: 'Me divertí mucho con mis amigos.'
  },
  {
    question: 'Cosas negativas ',
    answer:
      'Después de 7 tragos ya me sentía muy mal y al día siguiente tuve una cruda horrible.'
  }
];
const exampleMetas = [
  {
    question: 'Primera Meta',
    answer: 'Ingresar a la universidad '
  },
  {
    question: 'En un mes… ¿Qué haré para lograr mi meta?',
    answer:
      'Me pondré a estudiar diario para pasar mis materias con mejor calificación en las materias que no me va tan bien.'
  },
  {
    question: '¿Cuál será el resultado?',
    answer: 'Obtendré mejores calificaciones y subiré mi promedio.'
  },
  {
    question: 'En seis meses… ¿Qué haré para lograr mi meta?',
    answer:
      'Preguntaré en que universidades está la carrera que quiero estudiar y preguntaré que necesito para entrar.'
  },
  {
    question: '¿Cuál será el resultado?',
    answer:
      'Sabré a que universidad quiero ir y sabré que exámenes o que calificación necesito para entrar y así podré saber en qué materias necesito esforzarme más.'
  }
];
export const PacientSesionesRoutes: RouteRecordRaw = {
  path: '/',
  component: () => import('src/layouts/LayoutFullScreen.vue'),
  children: [
    {
      path: 'sesion0',
      component: () => import('src/pages/ConsejoBreve.vue'),
      props: (route) => ({
        sesionNumber: 0,
        pacientId: route.params.pacientId,
        stateURL: '/sesion0'
      })
    },
    {
      path: 'sesion1',
      component: () =>
        import('src/pages/sesiones/SesionPresentationManager.vue'),
      props: (route) => ({
        sesionNumber: 1,
        pacientId: route.params.pacientId,
        stateURL: '/sesion1',
        components: [
          {
            component: defineAsyncComponent(
              () =>
                import('src/components/sesiones/sesion1/PorqueComunicarnos.vue')
            ),
            steps: 5
          },
          {
            component: defineAsyncComponent(
              () =>
                import(
                  'src/components/sesiones/sesion1/EncontrarDiferencias.vue'
                )
            ),
            steps: 9
          },
          {
            component: defineAsyncComponent(
              () =>
                import('src/components/sesiones/sesion1/ProponerSoluciones.vue')
            ),
            steps: 6
          },
          {
            component: defineAsyncComponent(
              () => import('src/components/sesiones/sesion1/ExpresarSiento.vue')
            ),
            steps: 7
          },
          {
            component: defineAsyncComponent(
              () => import('src/components/sesiones/sesion1/HacerHalago.vue')
            ),
            steps: 6
          },

          {
            component: defineAsyncComponent(
              () =>
                import('src/components/sesiones/sesion1/RecordarProblema.vue')
            ),
            steps: 1,
            exampleData: [
              {
                question: '¿Cuál fue esa situación?',
                answer:
                  'No pedí permiso para ir a una fiesta y mis papás se enojaron conmigo'
              },
              {
                question: '¿Qué hice?',
                answer: 'Fui a la fiesta sin permiso y apagué mi celular'
              },
              {
                question: '¿Cómo me sentí?',
                answer: 'Feliz, preocupado y con miedo '
              },
              {
                question: '¿Qué consecuencias tuve?',
                answer: 'Mis papás me regañaron y me castigaron 1 semana '
              },
              {
                question:
                  'De las habilidades vistas... ¿Cuáles pudiste haber utilizado para resolver este problema?',
                answer:
                  'Pude haber expresado que ir a esa fiesta era importante para mí, así como proponerle soluciones a mis papás para que me dejaran ir o bien, para disminuir el castigo que me asignaron. '
              }
            ],
            disableSlideNextGesture: true
          },
          {
            component: defineAsyncComponent(
              () =>
                import(
                  'src/components/sesiones/sesion1/VentajasDesventajas.vue'
                )
            ),
            steps: 1,
            disableSlideNextGesture: true,
            exampleData: [
              {
                question: 'Ventajas',
                answer:
                  '• Podría expresarme mejor y con más facilidad. • Podría pedir las cosas que quiero o necesito. • Me llevaría mejor con mi familia y amigos '
              },
              {
                question: 'Desventajas ',
                answer: '• Al principio me sería difícil '
              },
              {
                question:
                  '¿Cómo afectaría tu consumo si mejoras tu comunicación?',
                answer:
                  'Creo que me sería más fácil decirle que no a mis amigos cuando me inviten a consumir e incluso podría pedirle ayuda a otros amigos para que me ayuden a cumplir mi meta de consumo. '
              }
            ]
          },
          {
            component: defineAsyncComponent(
              () =>
                import('src/components/sesiones/sesion1/ReflexionSesion.vue')
            ),
            steps: 4,
            disableSlideNextGesture: true,
            extraProps: {
              reflexionTitle: '¡Perfecto! 🎉',
              reflexionSubtitle:
                '"Sigamos pensando en esas cosas que podemos mejorar y que hacen que nos sintamos mejor con nuestro alrededor."',
              reflexionAnimation: defineAsyncComponent(
                () =>
                  import(
                    'src/components/sesiones/sesion1/animations/SittingAnimation.vue'
                  )
              )
            }
          }
        ]
      })
    },
    {
      path: 'sesion2',
      component: () =>
        import('src/pages/sesiones/SesionPresentationManager.vue'),
      props: (route) => ({
        sesionNumber: 2,
        pacientId: route.params.pacientId,
        stateURL: '/sesion2',
        components: [
          {
            component: defineAsyncComponent(
              () => import('src/components/sesiones/sesion2/ListaCosas.vue')
            ),
            steps: 4,
            disableSlideNextGesture: true,
            shouldScrollBotton: false
          },
          {
            component: defineAsyncComponent(
              () => import('src/components/sesiones/sesion2/PasarCambio.vue')
            ),
            steps: 5,
            disableSlideNextGesture: true,
            shouldScrollBotton: false
          },
          {
            component: defineAsyncComponent(
              () =>
                import('src/components/sesiones/sesion2/EstableciendoMeta.vue')
            ),
            steps: 3,
            disableSlideNextGesture: true
          },
          {
            component: defineAsyncComponent(
              () => import('src/components/sesiones/sesion2/ImportanteMeta.vue')
            ),
            steps: 3,
            disableSlideNextGesture: true
          },
          {
            component: defineAsyncComponent(
              () => import('src/components/sesiones/sesion2/ConfianzaMeta.vue')
            ),
            steps: 4,
            disableSlideNextGesture: true
          },
          {
            component: defineAsyncComponent(
              () =>
                import('src/components/sesiones/sesion1/ReflexionSesion.vue')
            ),
            steps: 4,
            disableSlideNextGesture: true,
            extraProps: {
              reflexionTitle: '¡Eso es! 🤜🤛',
              reflexionSubtitle:
                '"El primer paso ya lo diste, ahora sigue tener la determinación y ser constantes para poder lograr las metas y objetivos que deseamos."',
              reflexionAnimation: defineAsyncComponent(
                () =>
                  import('src/components/sesiones/sesion2/TargetAnimation.vue')
              )
            }
          }
        ]
      })
    },
    {
      path: 'sesion3',
      component: () =>
        import('src/pages/sesiones/SesionPresentationManager.vue'),
      props: (route) => ({
        sesionNumber: 3,
        pacientId: route.params.pacientId,
        stateURL: '/sesion3',
        components: [
          {
            component: defineAsyncComponent(
              () => import('src/components/sesiones/sesion3/DondeEstas.vue')
            ),
            steps: 4
          },
          {
            component: defineAsyncComponent(
              () =>
                import(
                  'src/components/sesiones/sesion3/SituacionesDeConsumo.vue'
                )
            ),
            steps: 17
          },
          {
            component: defineAsyncComponent(
              () =>
                import(
                  'src/components/sesiones/sesion3/RecuerdaSituacionesConsumo.vue'
                )
            ),
            steps: 3
          },
          {
            component: defineAsyncComponent(
              () =>
                import(
                  'src/components/sesiones/sesion3/SituacionDeConsumoForm.vue'
                )
            ),
            steps: 3,
            extraProps: {
              situacionKey: 'PrimeraSituacion',
              situacionTitle: 'Primera Situación'
            },
            exampleData: exampleSituacionesConsumo,
            disableSlideNextGesture: true
          },
          {
            component: defineAsyncComponent(
              () =>
                import(
                  'src/components/sesiones/sesion3/SituacionDeConsumoForm.vue'
                )
            ),
            steps: 3,
            extraProps: {
              situacionKey: 'SegundaSituacion',
              situacionTitle: 'Segunda Situación'
            },
            exampleData: exampleSituacionesConsumo,
            disableSlideNextGesture: true
          },
          {
            component: defineAsyncComponent(
              () =>
                import(
                  'src/components/sesiones/sesion3/SituacionDeConsumoForm.vue'
                )
            ),
            steps: 3,
            extraProps: {
              situacionKey: 'TerceraSituacion',
              situacionTitle: 'Tercera Situación'
            },
            exampleData: exampleSituacionesConsumo,
            disableSlideNextGesture: true
          },
          {
            component: defineAsyncComponent(
              () =>
                import('src/components/sesiones/sesion1/ReflexionSesion.vue')
            ),
            steps: 4,
            disableSlideNextGesture: true,
            extraProps: {
              reflexionTitle: '¡Listo! 😎',
              reflexionSubtitle:
                '"Ver hacia atrás, reconocer, aprender y enfocarse en el futuro."',
              reflexionAnimation: defineAsyncComponent(
                () =>
                  import(
                    'src/components/sesiones/sesion3/ThinkingAnimation.vue'
                  )
              )
            }
          }
        ]
      })
    },
    {
      path: 'sesion4',
      component: () =>
        import('src/pages/sesiones/SesionPresentationManager.vue'),
      props: (route) => ({
        sesionNumber: 4,
        pacientId: route.params.pacientId,
        stateURL: '/sesion4',
        components: [
          {
            component: defineAsyncComponent(
              () =>
                import('src/components/sesiones/sesion4/QueEsPlanAccion.vue')
            ),
            steps: 3
          },
          {
            component: defineAsyncComponent(
              () =>
                import(
                  'src/components/sesiones/sesion4/RecuerdaIdentificasteSituacionesConsumo.vue'
                )
            ),
            steps: 4
          },
          {
            component: defineAsyncComponent(
              () =>
                import('src/components/sesiones/sesion4/SituacionPlanForm.vue')
            ),
            steps: 4,
            disableSlideNextGesture: true,
            extraProps: {
              situacionKey: 'PrimeraSituacion',
              situacionTitle: 'Situación de Riesgo 1',
              planAKey: 'PlanPrimeraSituacion',
              planBKey: 'PlanPrimeraSituacionB'
            }
          },
          {
            component: defineAsyncComponent(
              () =>
                import('src/components/sesiones/sesion4/SituacionPlanForm.vue')
            ),
            steps: 4,
            disableSlideNextGesture: true,
            extraProps: {
              situacionKey: 'SegundaSituacion',
              situacionTitle: 'Situación de Riesgo 2',
              planAKey: 'PlanSegundaSituacion',
              planBKey: 'PlanSegundaSituacionB'
            }
          },
          {
            component: defineAsyncComponent(
              () =>
                import('src/components/sesiones/sesion4/SituacionPlanForm.vue')
            ),
            steps: 4,
            disableSlideNextGesture: true,
            extraProps: {
              situacionKey: 'TerceraSituacion',
              situacionTitle: 'Situación de Riesgo 3',
              planAKey: 'PlanTerceraSituacion',
              planBKey: 'PlanTerceraSituacionB'
            }
          },
          {
            component: defineAsyncComponent(
              () =>
                import('src/components/sesiones/sesion1/ReflexionSesion.vue')
            ),
            steps: 4,
            disableSlideNextGesture: true,
            extraProps: {
              reflexionTitle: '¡Excelente! 👏',
              reflexionSubtitle: '¡Estamos seguros que lograrás tus metas!',
              reflexionAnimation: defineAsyncComponent(
                () =>
                  import(
                    'src/components/sesiones/sesion4/CelebratingAnimation.vue'
                  )
              )
            }
          }
        ]
      })
    },
    {
      path: 'sesion5',
      component: () =>
        import('src/pages/sesiones/SesionPresentationManager.vue'),
      props: (route) => ({
        sesionNumber: 5,
        pacientId: route.params.pacientId,
        stateURL: '/sesion5',
        components: [
          {
            component: defineAsyncComponent(
              () => import('src/components/sesiones/sesion5/TusMetas.vue')
            ),
            shouldScrollBotton: false,
            disableSlideNextGesture: true,
            steps: 2
          },
          {
            component: defineAsyncComponent(
              () => import('src/components/sesiones/sesion5/PorqueLograr.vue')
            ),
            shouldScrollBotton: false,
            disableSlideNextGesture: true,
            steps: 2
          },
          {
            component: defineAsyncComponent(
              () => import('src/components/sesiones/sesion5/EligeMetas.vue')
            ),
            disableSlideNextGesture: true,
            steps: 3
          },
          {
            component: defineAsyncComponent(
              () => import('src/components/sesiones/sesion5/QuePasaria.vue')
            ),
            disableSlideNextGesture: true,
            steps: 3
          },
          {
            component: defineAsyncComponent(
              () => import('src/components/sesiones/sesion5/MetaForm.vue')
            ),
            disableSlideNextGesture: true,
            steps: 3,
            exampleData: exampleMetas,
            extraProps: {
              metaKey: 'PrimeraMeta',
              metaTitle: 'Primera Meta'
            }
          },
          {
            component: defineAsyncComponent(
              () => import('src/components/sesiones/sesion5/MetaForm.vue')
            ),
            steps: 3,
            disableSlideNextGesture: true,
            exampleData: exampleMetas,
            extraProps: {
              metaKey: 'SegundaMeta',
              metaTitle: 'Segunda Meta'
            }
          },
          {
            component: defineAsyncComponent(
              () => import('src/components/sesiones/sesion5/MetaForm.vue')
            ),
            steps: 3,
            disableSlideNextGesture: true,
            exampleData: exampleMetas,
            extraProps: {
              metaKey: 'TerceraMeta',
              metaTitle: 'Tercera Meta'
            }
          },
          {
            component: defineAsyncComponent(
              () => import('src/components/sesiones/sesion5/RecuerdaQue.vue')
            ),
            steps: 7
          },
          {
            component: defineAsyncComponent(
              () =>
                import('src/components/sesiones/sesion1/ReflexionSesion.vue')
            ),
            steps: 4,
            disableSlideNextGesture: true,
            extraProps: {
              reflexionTitle: '¡Listo! 😎',
              reflexionSubtitle:
                ' "Si te cansas, aprende a descansar, no a renunciar."',
              reflexionAnimation: defineAsyncComponent(
                () =>
                  import('src/components/sesiones/sesion5/BaloonsAnimation.vue')
              )
            }
          }
        ]
      })
    },
    {
      path: 'sesion6',
      component: () =>
        import('src/pages/sesiones/SesionPresentationManager.vue'),
      props: (route) => ({
        sesionNumber: 6,
        pacientId: route.params.pacientId,
        stateURL: '/sesion6',
        endOfSession: async ({ db }: EndOfSessionArgs) => {
          const flowFollowUp = await useFlowSessionFollowUp(db);
          const dateThreeMonths = date.addToDate(new Date(), { months: 3 });
          console.log('Date of followup = ', dateThreeMonths);
          const event = await flowFollowUp.scheduleEvent({
            sesionNumber: 1,
            dateSelected: dateThreeMonths
          });
          console.log(
            event,
            dateThreeMonths,
            'The sesion fininhed and called the end function',
            db,
            'Here we will schedule the follow up session'
          );
        },
        components: [
          {
            component: defineAsyncComponent(
              () =>
                import(
                  'src/components/sesiones/sesion6/SesionesAnterioresAprendiste.vue'
                )
            ),
            disableSlideNextGesture: true,
            steps: 10
          },
          {
            component: defineAsyncComponent(
              () =>
                import(
                  'src/components/sesiones/sesion6/ObservaCambioConsumo.vue'
                )
            ),

            steps: 3
          },
          {
            component: defineAsyncComponent(
              () => import('src/components/sesiones/sesion6/RecuerdasMeta.vue')
            ),
            shouldScrollBotton: false,
            disableSlideNextGesture: true,
            steps: 4
          },
          {
            component: defineAsyncComponent(
              () =>
                import(
                  'src/components/sesiones/sesion6/RestableciendeMetaImportancia.vue'
                )
            ),
            disableSlideNextGesture: true,
            steps: 3
          },
          {
            component: defineAsyncComponent(
              () =>
                import(
                  'src/components/sesiones/sesion6/RestableciendoMetaConfianza.vue'
                )
            ),
            steps: 3,
            disableSlideNextGesture: true
            // exampleData: exampleMetas,
            // extraProps: {
            //   metaKey: 'PrimeraMeta',
            //   metaTitle: 'Primera Meta'
            // }
          },
          {
            component: defineAsyncComponent(
              () =>
                import(
                  'src/components/sesiones/sesion6/RestableciendoMetaSeguridad.vue'
                )
            ),
            steps: 3,
            shouldScrollBotton: false,
            disableSlideNextGesture: true
            // exampleData: exampleMetas,
            // extraProps: {
            //   metaKey: 'SegundaMeta',
            //   metaTitle: 'Segunda Meta'
            // }
          },
          {
            component: defineAsyncComponent(
              () =>
                import('src/components/sesiones/sesion6/OpinionPrograma.vue')
            ),
            steps: 3,
            shouldScrollBotton: false,
            disableSlideNextGesture: true
          },
          {
            component: defineAsyncComponent(
              () =>
                import('src/components/sesiones/sesion1/ReflexionSesion.vue')
            ),
            steps: 4,
            disableSlideNextGesture: true,
            extraProps: {
              reflexionTitle: 'Lo has logrado. 💪',
              reflexionSubtitle:
                '"Estamos muy orgullosos de ti, esperamos que hayas aprendido mucho con nosotros."',
              reflexionAnimation: defineAsyncComponent(
                () =>
                  import('src/components/sesiones/sesion6/HearthAnimation.vue')
              ),
              reflexionQuestion:
                'Dinos en qué ha cambiado tu forma de ser y tu alrededor a partir del día que decidiste entrar al PIBA.'
            }
          },
          {
            component: defineAsyncComponent(
              () => import('src/components/consejo-breve/ContactoFinSesion.vue')
            ),

            disableSlideNextGesture: true
          }
        ]
      })
    }
  ]
};
export const PacientSesionesExplanationRoutes: RouteRecordRaw = {
  path: '/',
  component: () => import('src/layouts/LayoutFullScreenNoSuspense.vue'),
  children: [
    {
      path: 'portada/:sesionNumber',
      component: () => import('components/sesiones/PortadaSesion.vue'),
      props: (route) => ({
        sesionNumber: Number(route.params.sesionNumber),
        pacientId: route.params.pacientId
      })
    },
    {
      path: 'hownav/:sesionNumber',
      component: () => import('components/sesiones/HowNav.vue'),
      props: (route) => ({
        sesionNumber: Number(route.params.sesionNumber),
        pacientId: route.params.pacientId
      })
    }
  ]
};
export const PacienteDashboardPagesRoutes: RouteRecordRaw[] = [
  {
    path: 'calendario',
    component: () => import('src/pages/pacient/PacienteEventosCalendario.vue'),
    props: (route) => ({
      pacientId: route.params.pacientId
    })
  },
  {
    path: 'sesiones',
    component: () => import('src/pages/pacient/PacienteSesiones.vue'),
    props: (route) => ({
      pacientId: route.params.pacientId
    })
  },
  {
    path: 'practicas',
    component: () => import('src/pages/pacient/PacientePracticas.vue'),
    props: (route) => ({
      pacientId: route.params.pacientId
    })
  },
  {
    path: 'sesion:sesionNumber/practica:practicaNumber',
    component: () => import('src/pages/pacient/PacientePracticaSesion.vue'),
    props: (route) => ({
      pacientId: route.params.pacientId,
      sesionNumber: route.params.sesionNumber
        ? Number(route.params.sesionNumber)
        : undefined,
      practicaNumber: route.params.practicaNumber
        ? Number(route.params.practicaNumber)
        : undefined
    })
  },
  {
    path: RoutesMeta.RESULTS.key,
    component: () => import('src/pages/ResultsEvaluation.vue'),
    props: (route) => ({
      stateURL: InitialEvaluationRoutes.RESULTS.key,
      pacientId: route.params.pacientId,
      mode: 'raw'
    }),
    meta: {
      title: InitialEvaluationRoutes.RESULTS.title,
      titleInBreadcrumb: true,
      titleIcon: InitialEvaluationRoutes.RESULTS.icon
    }
  },
  {
    path: 'seguimiento',
    component: () => import('src/pages/pacient/PacienteSeguimiento.vue'),
    props: (route) => ({
      pacientId: route.params.pacientId
    })
  }
];
export const PacienteRoutes: RouteRecordRaw[] = [
  PacientSesionesRoutes,
  PacientSesionesExplanationRoutes,
  {
    path: '/',
    component: () => import('src/layouts/PacientSistemaLayout.vue'),
    children: [
      ...PacienteDashboardPagesRoutes,
      {
        path: 'perfil',
        component: () => import('src/pages/profile/PerfilPaciente.vue'),
        props: (route) => ({
          pacientId: route.params.pacientId
        })
      }
    ]
  },
  FollowUpRoutesGuarded
];
