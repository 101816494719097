import {
  createMemoryHistory,
  createRouter,
  createWebHashHistory,
  createWebHistory
} from 'vue-router';

import { route } from 'quasar/wrappers';
import routes from './routes';

/*
 * If not building with SSR mode, you can
 * directly export the Router instantiation;
 *
 * The function below can be async too; either use
 * async/await or return a Promise which resolves
 * with the Router instance.
 */

export default route(function (/* { store, ssrContext } */) {
  const createHistory = process.env.SERVER
    ? createMemoryHistory
    : process.env.VUE_ROUTER_MODE === 'history'
    ? createWebHistory
    : createWebHashHistory;

  const Router = createRouter({
    scrollBehavior: (to, from, savedPosition) => {
      // Extracted from: https://github.com/vuejs/vue-router/blob/dev/examples/scroll-behavior/app.js
      // https://router.vuejs.org/guide/advanced/scroll-behavior.html#scroll-behavior
      if (savedPosition) {
        // savedPosition is only available for popstate navigations.
        return savedPosition;
      }

      // scroll to anchor by returning the selector
      if (to.hash) {
        return {
          el: to.hash,
          behavior: 'smooth'
        };
      }
      return { top: 0, behavior: 'smooth' };
    },
    routes,

    // Leave this as is and make changes in quasar.conf.js instead!
    // quasar.conf.js -> build -> vueRouterMode
    // quasar.conf.js -> build -> publicPath
    history: createHistory(
      process.env.MODE === 'ssr' ? void 0 : process.env.VUE_ROUTER_BASE
    ),
  });

  return Router;
});
