import { UserAuth } from 'src/types/user/main';
import { superloginAuthResponse } from 'src/types/sl-nx/auth';

export type RegisterFn = (userAuth: UserAuth) => Promise<void>;

export type RegisterProviderFn = (
  authResponse: superloginAuthResponse
) => Promise<void>;

export enum RegisterState {
  OK,
  PROCEED_TO_LOGIN,
  FAIL
}
