import { InitialEvaluationMetaTitleClasses } from 'src/meta/initial-evaluation/InitialEvaluationMeta';
import { lang } from 'src/components/blitzForm/lang';

import { Icontext } from 'src/types/blitzar';
import { PositData } from 'src/types/initial-evaluation/evaluacion-personal';
import { SchemaField } from 'blitzar';
import { FIELDS, options } from 'src/meta/initial-evaluation/PositMeta';

const optionsQoptionWrapper = Object.entries(options).map(([key, option]) => {
  return {
    label: option.label,
    value: option.value
  };
});

type ctx = Icontext<PositData>;
export const PositSchemaColumns = <SchemaField[]>[
  {
    component: 'p',
    slot: FIELDS['title'].label,
    componentClasses: InitialEvaluationMetaTitleClasses
  },
  {
    component: 'p',
    slot: 'El propósito de estas preguntas es ayudarnos a conocer la forma en que mejor podemos ayudarte. Por esto, trata de contestar las preguntas con sinceridad.',
    componentClasses: 'text-weight-bold subtitle text-darkD subtitle',
    dynamicProps: ['showCondition'],
    showCondition: (val: string, context: ctx) => context.mode !== 'raw'
  },
  {
    component: 'p',
    slot: 'Este no es un examen, no hay respuestas correctas o incorrectas, pero por favor responde con cuidado. Nadie podrá leer las respuestas que tú nos des.',
    componentClasses: 'text-weight-bold subtitle text-darkD subtitle',
    dynamicProps: ['showCondition'],
    showCondition: (val: string, context: ctx) => context.mode !== 'raw'
  },
  {
    component: 'p',
    slot: 'Contesta todas las preguntas. Escoge la respuesta que más se acerque a la verdad en tu caso. Al responder piensa en lo que te ha ocurrido durante los últimos seis meses.',
    componentClasses: ' subtitle text-darkD subtitle',
    dynamicProps: ['showCondition'],
    showCondition: (val: string, context: ctx) => context.mode !== 'raw'
  },
  {
    id: FIELDS['has-tenido-problemas-porque-consumes-drogas-o-bebidas-alcoholi']
      .key,
    label:
      FIELDS['has-tenido-problemas-porque-consumes-drogas-o-bebidas-alcoholi']
        .label,
    required:
      FIELDS['has-tenido-problemas-porque-consumes-drogas-o-bebidas-alcoholi']
        .required,
    component: 'QOptionWrapper',
    type: 'radio',
    options: optionsQoptionWrapper
  },
  {
    id: FIELDS['te-has-hecho-dano-o-has-hecho-dano-otra-persona-accidentalment']
      .key,
    label:
      FIELDS['te-has-hecho-dano-o-has-hecho-dano-otra-persona-accidentalment']
        .label,
    required:
      FIELDS['te-has-hecho-dano-o-has-hecho-dano-otra-persona-accidentalment']
        .required,
    component: 'QOptionWrapper',
    type: 'radio',
    options: optionsQoptionWrapper
  },
  {
    id: FIELDS['sueles-perderte-actividades-o-acontecimientos-porque-has-gasta']
      .key,
    label:
      FIELDS['sueles-perderte-actividades-o-acontecimientos-porque-has-gasta']
        .label,
    required:
      FIELDS['sueles-perderte-actividades-o-acontecimientos-porque-has-gasta']
        .required,
    component: 'QOptionWrapper',
    type: 'radio',
    options: optionsQoptionWrapper
  },
  {
    id: FIELDS['has-sentido-eres-adicto-al-alcohol-o-drogas'].key,
    label: FIELDS['has-sentido-eres-adicto-al-alcohol-o-drogas'].label,
    required: FIELDS['has-sentido-eres-adicto-al-alcohol-o-drogas'].required,
    component: 'QOptionWrapper',
    type: 'radio',
    options: optionsQoptionWrapper
  },
  {
    id: FIELDS['has-comenzado-consumir-mayores-cantidades-drogas-o-alcohol-obt']
      .key,
    label:
      FIELDS['has-comenzado-consumir-mayores-cantidades-drogas-o-alcohol-obt']
        .label,
    required:
      FIELDS['has-comenzado-consumir-mayores-cantidades-drogas-o-alcohol-obt']
        .required,
    component: 'QOptionWrapper',
    type: 'radio',
    options: optionsQoptionWrapper
  },
  {
    id: FIELDS['te-vas-fiestas-porque-no-hay-bebidas-alcoholicas-o-drogas'].key,
    label:
      FIELDS['te-vas-fiestas-porque-no-hay-bebidas-alcoholicas-o-drogas'].label,
    required:
      FIELDS['te-vas-fiestas-porque-no-hay-bebidas-alcoholicas-o-drogas']
        .required,
    component: 'QOptionWrapper',
    type: 'radio',
    options: optionsQoptionWrapper
  },
  {
    id: FIELDS['sientes-un-deseo-constante-consumir-bebidas-alcoholicas-o-drog']
      .key,
    label:
      FIELDS['sientes-un-deseo-constante-consumir-bebidas-alcoholicas-o-drog']
        .label,
    required:
      FIELDS['sientes-un-deseo-constante-consumir-bebidas-alcoholicas-o-drog']
        .required,
    component: 'QOptionWrapper',
    type: 'radio',
    options: optionsQoptionWrapper
  },
  {
    id: FIELDS['has-tenido-un-accidente-automovilistico-estando-efecto-del-alc']
      .key,
    label:
      FIELDS['has-tenido-un-accidente-automovilistico-estando-efecto-del-alc']
        .label,
    required:
      FIELDS['has-tenido-un-accidente-automovilistico-estando-efecto-del-alc']
        .required,
    component: 'QOptionWrapper',
    type: 'radio',
    options: optionsQoptionWrapper
  },
  {
    id: FIELDS['olvidas-lo-haces-cuando-bebes-o-te-drogas'].key,
    label: FIELDS['olvidas-lo-haces-cuando-bebes-o-te-drogas'].label,
    required: FIELDS['olvidas-lo-haces-cuando-bebes-o-te-drogas'].required,
    component: 'QOptionWrapper',
    type: 'radio',
    options: optionsQoptionWrapper
  },
  {
    id: FIELDS['mes-pasado-manejaste-un-automovil-estando-borracho-o-drogado']
      .key,
    label:
      FIELDS['mes-pasado-manejaste-un-automovil-estando-borracho-o-drogado']
        .label,
    required:
      FIELDS['mes-pasado-manejaste-un-automovil-estando-borracho-o-drogado']
        .required,
    component: 'QOptionWrapper',
    type: 'radio',
    options: optionsQoptionWrapper
  },
  {
    id: FIELDS['-uso-del-alcohol-o-drogas-te-produce-cambios-repentinos-humor-']
      .key,
    label:
      FIELDS['-uso-del-alcohol-o-drogas-te-produce-cambios-repentinos-humor-']
        .label,
    required:
      FIELDS['-uso-del-alcohol-o-drogas-te-produce-cambios-repentinos-humor-']
        .required,
    component: 'QOptionWrapper',
    type: 'radio',
    options: optionsQoptionWrapper
  },
  {
    id: FIELDS['pierdes-dias-clase-o-llegas-tarde-escuela-o-al-trabajo-haber-c']
      .key,
    label:
      FIELDS['pierdes-dias-clase-o-llegas-tarde-escuela-o-al-trabajo-haber-c']
        .label,
    required:
      FIELDS['pierdes-dias-clase-o-llegas-tarde-escuela-o-al-trabajo-haber-c']
        .required,
    component: 'QOptionWrapper',
    type: 'radio',
    options: optionsQoptionWrapper
  },
  {
    id: FIELDS['te-han-dicho-alguna-vez-tus-familiares-o-amigos-debes-bajarle-']
      .key,
    label:
      FIELDS['te-han-dicho-alguna-vez-tus-familiares-o-amigos-debes-bajarle-']
        .label,
    required:
      FIELDS['te-han-dicho-alguna-vez-tus-familiares-o-amigos-debes-bajarle-']
        .required,
    component: 'QOptionWrapper',
    type: 'radio',
    options: optionsQoptionWrapper
  },
  {
    id: FIELDS['discutes-seriamente-tus-amigos-o-familiares-consumo-haces-bebi']
      .key,
    label:
      FIELDS['discutes-seriamente-tus-amigos-o-familiares-consumo-haces-bebi']
        .label,
    required:
      FIELDS['discutes-seriamente-tus-amigos-o-familiares-consumo-haces-bebi']
        .required,
    component: 'QOptionWrapper',
    type: 'radio',
    options: optionsQoptionWrapper
  },
  {
    id: FIELDS['-bebidas-alcoholicas-o-drogas-te-han-llevado-hacer-algo-normal']
      .key,
    label:
      FIELDS['-bebidas-alcoholicas-o-drogas-te-han-llevado-hacer-algo-normal']
        .label,
    required:
      FIELDS['-bebidas-alcoholicas-o-drogas-te-han-llevado-hacer-algo-normal']
        .required,
    component: 'QOptionWrapper',
    type: 'radio',
    options: optionsQoptionWrapper
  },
  {
    id: FIELDS['tienes-dificultades-alguno-tus-amigos-debido-bebidas-alcoholic']
      .key,
    label:
      FIELDS['tienes-dificultades-alguno-tus-amigos-debido-bebidas-alcoholic']
        .label,
    required:
      FIELDS['tienes-dificultades-alguno-tus-amigos-debido-bebidas-alcoholic']
        .required,
    component: 'QOptionWrapper',
    type: 'radio',
    options: optionsQoptionWrapper
  },
  {
    id: FIELDS['has-sentido-no-puedes-controlar-deseo-consumir-bebidas-alcohol']
      .key,
    label:
      FIELDS['has-sentido-no-puedes-controlar-deseo-consumir-bebidas-alcohol']
        .label,
    required:
      FIELDS['has-sentido-no-puedes-controlar-deseo-consumir-bebidas-alcohol']
        .required,
    component: 'QOptionWrapper',
    type: 'radio',
    options: optionsQoptionWrapper
  }
];
