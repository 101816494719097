import * as Sentry from '@sentry/vue';

import { Integrations } from '@sentry/tracing';
import { boot } from 'quasar/wrappers';

export default boot(({ app, router }) => {
  // debugger;
  // app.config.devtools = true;
  Sentry.init({
    app,
    dsn: 'https://fd98a7e34290499689d659aa6e45b805@o516355.ingest.sentry.io/4504154307297280',
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router)
      }),
      new Sentry.Replay()
    ],
    tracesSampleRate: Number(process.env.VUE_APP_TRACES_SAMPLE_RATE) || 1,
    logErrors: true,
    release: process.env.VUE_APP_RELEASE_TAG,
    ignoreErrors: [
      'ResizeObserver loop limit exceeded',
      'ResizeObserver loop completed with undelivered notifications'
    ],
    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 1.0,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,

    // integrations: [new Sentry.Replay()],
  });
});
