import { FlowState } from 'src/composables/FlowState';

export const TerapeutaRegisterRoutes = {
  START: { key: '/' },
  PERSONAL_INFO: { key: '/terapeuta/registro' },
  WELCOME: { key: '/terapeuta/bienvenida' },
  WELCOME_VIDEO: { key: '/terapeuta/bienvenida-video' },
  INTRODUCTION: { key: '/terapeuta/introduccion' },
  DASHBOARD: { key: '/dashboard' }
};

export const TerapeutaRegisterURLs = (
  Object.keys(TerapeutaRegisterRoutes) as Array<
    keyof typeof TerapeutaRegisterRoutes
  >
).map((key) => TerapeutaRegisterRoutes[key].key);

export class FlowStateTerapeuta extends FlowState {
  constructor(db: PouchDB.Database) {
    super('flowStateRegisterTerapeuta', TerapeutaRegisterURLs, db);
  }
}

export async function useFlowStateTerapeuta(db: PouchDB.Database) {
  const flowStateTerapeuta = new FlowStateTerapeuta(db);
  await flowStateTerapeuta.load_current_state();
  console.log('Terapeuta state', flowStateTerapeuta.state);
  return flowStateTerapeuta;
}
