import { AreaConductaAntisocialData } from 'src/types/initial-evaluation/evaluacion-personal';
import { FIELDS } from 'src/meta/initial-evaluation/AreaConductaAntisocialMeta';
import { Icontext } from 'src/types/blitzar';
import { InitialEvaluationMetaTitleClasses } from 'src/meta/initial-evaluation/InitialEvaluationMeta';

type ctx = Icontext<Partial<AreaConductaAntisocialData>>;
export const AreaConductaAntisocialSchema = [
  {
    component: 'p',
    slot: FIELDS.title.label,
    componentClasses: InitialEvaluationMetaTitleClasses
  },
  {
    id: FIELDS['has-tomado-un-auto-sin-permiso'].key,
    label: FIELDS['has-tomado-un-auto-sin-permiso'].label,
    required: FIELDS['has-tomado-un-auto-sin-permiso'].required,
    options: Object.values(FIELDS['has-tomado-un-auto-sin-permiso'].options),
    component: 'QOptionWrapper',
    type: 'radio'
  },
  {
    id: FIELDS[
      'has-tomado-dinero-o-cosas-con-valor-de-$50-o-menos-que-no-te-pertenecen'
    ].key,
    label:
      FIELDS[
        'has-tomado-dinero-o-cosas-con-valor-de-$50-o-menos-que-no-te-pertenecen'
      ].label,
    required:
      FIELDS[
        'has-tomado-dinero-o-cosas-con-valor-de-$50-o-menos-que-no-te-pertenecen'
      ].required,
    options: Object.values(
      FIELDS[
        'has-tomado-dinero-o-cosas-con-valor-de-$50-o-menos-que-no-te-pertenecen'
      ].options
    ),
    component: 'QOptionWrapper',
    type: 'radio'
  },
  {
    id: FIELDS[
      'has-tomado-dinero-o-cosas-con-valor-de-$500-o-mas-que-no-te-pertenecen'
    ].key,
    label:
      FIELDS[
        'has-tomado-dinero-o-cosas-con-valor-de-$500-o-mas-que-no-te-pertenecen'
      ].label,
    required:
      FIELDS[
        'has-tomado-dinero-o-cosas-con-valor-de-$500-o-mas-que-no-te-pertenecen'
      ].required,
    options: Object.values(
      FIELDS[
        'has-tomado-dinero-o-cosas-con-valor-de-$500-o-mas-que-no-te-pertenecen'
      ].options
    ),
    component: 'QOptionWrapper',
    type: 'radio'
  },
  {
    id: FIELDS[
      'has-forzado-cerraduras-para-entrar-algun-lugar-que-no-sea-tu-casa'
    ].key,
    label:
      FIELDS[
        'has-forzado-cerraduras-para-entrar-algun-lugar-que-no-sea-tu-casa'
      ].label,
    required:
      FIELDS[
        'has-forzado-cerraduras-para-entrar-algun-lugar-que-no-sea-tu-casa'
      ].required,
    options: Object.values(
      FIELDS[
        'has-forzado-cerraduras-para-entrar-algun-lugar-que-no-sea-tu-casa'
      ].options
    ),
    component: 'QOptionWrapper',
    type: 'radio'
  },
  {
    id: FIELDS['has-tomado-mercancia-de-una-tienda-sin-pagarla'].key,
    label: FIELDS['has-tomado-mercancia-de-una-tienda-sin-pagarla'].label,
    required: FIELDS['has-tomado-mercancia-de-una-tienda-sin-pagarla'].required,
    options: Object.values(
      FIELDS['has-tomado-mercancia-de-una-tienda-sin-pagarla'].options
    ),
    component: 'QOptionWrapper',
    type: 'radio'
  },
  {
    id: FIELDS['has-golpeado-o-dañado-algo-que-no-te-pertenece'].key,
    label: FIELDS['has-golpeado-o-dañado-algo-que-no-te-pertenece'].label,
    required: FIELDS['has-golpeado-o-dañado-algo-que-no-te-pertenece'].required,
    options: Object.values(
      FIELDS['has-golpeado-o-dañado-algo-que-no-te-pertenece'].options
    ),
    component: 'QOptionWrapper',
    type: 'radio'
  },
  {
    id: FIELDS['has-atacado-a-alguien-usando-un-objeto-como-arma'].key,
    label: FIELDS['has-atacado-a-alguien-usando-un-objeto-como-arma'].label,
    required:
      FIELDS['has-atacado-a-alguien-usando-un-objeto-como-arma'].required,
    options: Object.values(
      FIELDS['has-atacado-a-alguien-usando-un-objeto-como-arma'].options
    ),
    component: 'QOptionWrapper',
    type: 'radio'
  },
  {
    id: FIELDS['has-vendido-drogas-marihuana-cocaina-etc'].key,
    label: FIELDS['has-vendido-drogas-marihuana-cocaina-etc'].label,
    required: FIELDS['has-vendido-drogas-marihuana-cocaina-etc'].required,
    options: Object.values(
      FIELDS['has-vendido-drogas-marihuana-cocaina-etc'].options
    ),
    component: 'QOptionWrapper',
    type: 'radio'
  },
  {
    id: FIELDS[
      'has-golpeado-herido-a-proposito-a-alguien-sin-considerar-a-tus-hermanos'
    ].key,
    label:
      FIELDS[
        'has-golpeado-herido-a-proposito-a-alguien-sin-considerar-a-tus-hermanos'
      ].label,
    required:
      FIELDS[
        'has-golpeado-herido-a-proposito-a-alguien-sin-considerar-a-tus-hermanos'
      ].required,
    options: Object.values(
      FIELDS[
        'has-golpeado-herido-a-proposito-a-alguien-sin-considerar-a-tus-hermanos'
      ].options
    ),
    component: 'QOptionWrapper',
    type: 'radio'
  },
  {
    id: FIELDS['has-formado-parte-de-riñas-o-peleas'].key,
    label: FIELDS['has-formado-parte-de-riñas-o-peleas'].label,
    required: FIELDS['has-formado-parte-de-riñas-o-peleas'].required,
    options: Object.values(
      FIELDS['has-formado-parte-de-riñas-o-peleas'].options
    ),
    component: 'QOptionWrapper',
    type: 'radio'
  },
  {
    id: FIELDS['has-encendido-fuego-a-proposito-a-objetos-que-no-te-pertenecen']
      .key,
    label:
      FIELDS['has-encendido-fuego-a-proposito-a-objetos-que-no-te-pertenecen']
        .label,
    required:
      FIELDS['has-encendido-fuego-a-proposito-a-objetos-que-no-te-pertenecen']
        .required,
    options: Object.values(
      FIELDS['has-encendido-fuego-a-proposito-a-objetos-que-no-te-pertenecen']
        .options
    ),
    component: 'QOptionWrapper',
    type: 'radio'
  },
  {
    id: FIELDS[
      'has-usado-un-cuchillo-o-pistola-para-obtener-algun-objeto-de-otra-persona'
    ].key,
    label:
      FIELDS[
        'has-usado-un-cuchillo-o-pistola-para-obtener-algun-objeto-de-otra-persona'
      ].label,
    required:
      FIELDS[
        'has-usado-un-cuchillo-o-pistola-para-obtener-algun-objeto-de-otra-persona'
      ].required,
    options: Object.values(
      FIELDS[
        'has-usado-un-cuchillo-o-pistola-para-obtener-algun-objeto-de-otra-persona'
      ].options
    ),
    component: 'QOptionWrapper',
    type: 'radio'
  },
  {
    id: FIELDS['edad-de-inicio-primera-vez-que-realizaste-estas-actividades']
      .key,
    label:
      FIELDS['edad-de-inicio-primera-vez-que-realizaste-estas-actividades']
        .label,
    component: 'q-input',
    type: 'number',
    min: 0,
    max: 150,
    dynamicProps: ['showCondition', 'required'],
    required: (val: string, { formData }: ctx) =>
      Object.keys(formData).some(
        (key) =>
          /\bhas-/.exec(key) &&
          formData[key as keyof AreaConductaAntisocialData] === 'si'
      ),
    showCondition: (val: string, { formData }: ctx) =>
      Object.keys(formData).some(
        (key) =>
          /\bhas-/.exec(key) &&
          formData[key as keyof AreaConductaAntisocialData] === 'si'
      )
  },
  {
    id: FIELDS['tus-amigos-han-realizado-alguna-de-las-activadades-anteriores']
      .key,
    label:
      FIELDS['tus-amigos-han-realizado-alguna-de-las-activadades-anteriores']
        .label,
    required:
      FIELDS['tus-amigos-han-realizado-alguna-de-las-activadades-anteriores']
        .required,
    options: Object.values(
      FIELDS['tus-amigos-han-realizado-alguna-de-las-activadades-anteriores']
        .options
    ),
    component: 'QOptionWrapper',
    type: 'radio'
  },
  {
    id: FIELDS['cuales-actividades-han-realizado-tus-amigos'].key,
    label: FIELDS['cuales-actividades-han-realizado-tus-amigos'].label,
    component: 'q-input',
    type: 'text',
    fieldClasses: 'reactive-field',
    dynamicProps: ['showCondition', 'required'],
    required: (val: string, { formData }: ctx) =>
      formData[
        'tus-amigos-han-realizado-alguna-de-las-activadades-anteriores'
      ] === 'si',
    showCondition: (val: string, { formData }: ctx) =>
      formData[
        'tus-amigos-han-realizado-alguna-de-las-activadades-anteriores'
      ] === 'si'
  }
];
