import { InitialEvaluationRoutes } from 'src/meta/initial-evaluation/InitialEvaluationMeta';
export const FIELDS = {
  title: {
    label: InitialEvaluationRoutes.FAMILY_INFO.title
  },
  'familiares-o-amigos-te-presionan-para-consumir': {
    key: 'familiares-o-amigos-te-presionan-para-consumir',
    label:
      'Cuando estás con tus amigos o familiares, ¿éstos te presionan a consumir alcohol o drogas?',
    options: {
      no: {
        label: 'No',
        value: 'no'
      },
      'si-amigos': {
        label: 'Solo mis amigos',
        value: 'si-amigos'
      },
      'si-familiares': {
        label: 'Solo mis familiares',
        value: 'si-familiares'
      },
      'si-ambos': {
        label: 'Si, tanto mis amigos como familiares',
        value: 'si-ambos'
      }
    },
    required: true
  },
  'quienes-te-ayudarian-a-cambiar-tu-consumo': {
    key: 'quienes-te-ayudarian-a-cambiar-tu-consumo',
    label:
      'De tus amigos o familiares, ¿quiénes te ayudarían a cambiar tu consumo de alcohol o drogas?',
    subLabel: 'Selecciona uno, varios o ninguno',
    options: {
      padres: {
        label: 'Padres',
        value: 'padres'
      },
      hermanos: {
        label: 'Hermanos',
        value: 'hermanos'
      },
      abuelos: {
        label: 'Abuelos',
        value: 'abuelos'
      },
      tios: {
        label: 'Tíos',
        value: 'tios'
      },
      primos: {
        label: 'Primos',
        value: 'primos'
      },
      amigos: {
        label: 'Amigos',
        value: 'amigos'
      },
      pareja: {
        label: 'Pareja',
        value: 'pareja'
      }
    },
    required: true
  },
  'has-tenido-algun-conflicto-familiar-grave': {
    key: 'has-tenido-algun-conflicto-familiar-grave',
    label:
      'En los últimos 12 meses ¿Has tenido algún conflicto familiar grave?',
    options: {
      si: {
        label: 'Sí',
        value: 'si'
      },
      no: {
        label: 'No',
        value: 'no'
      }
    },
    required: true
  },
  'es-comun-que-peleen-padres-o-adultos-en-tu-casa': {
    key: 'es-comun-que-peleen-padres-o-adultos-en-tu-casa',
    label:
      '¿Es común que se peleen tus papás y/u otros adultos que viven en tu casa?',
    options: {
      si: {
        label: 'Sí',
        value: 'si'
      },
      no: {
        label: 'No',
        value: 'no'
      }
    },
    required: true
  },
  'cuando-han-peleado-adultos-alguno-habia-bebido-alcohol': {
    key: 'cuando-han-peleado-adultos-alguno-habia-bebido-alcohol',
    label:
      'Cuando se han peleado los adultos de tu casa ¿Alguno de ellos ha bebido alcohol?',
    options: {
      si: {
        label: 'Sí',
        value: 'si'
      },
      no: {
        label: 'No',
        value: 'no'
      },
      'no-pelean': {
        label: 'No se han peleado',
        value: 'no-pelean'
      }
    },
    required: true
  },
  'como-describes-la-relacion-con-tus-padres': {
    key: 'como-describes-la-relacion-con-tus-padres',
    label: '¿Cómo describes la relación con tus padres?',
    options: {
      'muy-buena': {
        label: 'Muy buena',
        value: 'muy-buena'
      },
      buena: {
        label: 'Buena',
        value: 'buena'
      },
      regular: {
        label: 'Regular',
        value: 'regular'
      },
      mala: {
        label: 'Mala',
        value: 'mala'
      },
      'muy-mala': {
        label: 'Muy Mala',
        value: 'muy-mala'
      }
    },
    required: true
  },
  'como-describes-la-relacion-con-tus-padres-hermanos': {
    key: 'como-describes-la-relacion-con-tus-padres-hermanos',
    label: '¿Cómo describes la relación con tus hermanos(as)?',
    options: {
      'muy-buena': {
        label: 'Muy buena',
        value: 'muy-buena'
      },
      buena: {
        label: 'Buena',
        value: 'buena'
      },
      regular: {
        label: 'Regular',
        value: 'regular'
      },
      mala: {
        label: 'Mala',
        value: 'mala'
      },
      'muy-mala': {
        label: 'Muy Mala',
        value: 'muy-mala'
      }
    },
    required: true
  },
  'se-niegan-tus-padres-o-tutores-a-hablarte-cuando-se-enfadan-contigo': {
    key: 'se-niegan-tus-padres-o-tutores-a-hablarte-cuando-se-enfadan-contigo',
    label:
      '¿Se niegan tus padres o quien los sustituye a hablarte cuando se enfadan contigo?',
    options: {
      si: {
        label: 'Sí',
        value: 'si'
      },
      no: {
        label: 'No',
        value: 'no'
      }
    },
    required: true
  },
  'tus-padres-o-tutore-saben-donde-estas-y-lo-que-estas-haciendo': {
    key: 'tus-padres-o-tutore-saben-donde-estas-y-lo-que-estas-haciendo',
    label:
      '¿La mayoría de veces saben tus padres o quien los sustituyen donde estás y lo que estás haciendo?',
    options: {
      si: {
        label: 'Sí',
        value: 'si'
      },
      no: {
        label: 'No',
        value: 'no'
      }
    },
    required: true
  },
  'participas-en-muchas-actividades-en-compañia-de-tus-padre-o-tutores': {
    key: 'participas-en-muchas-actividades-en-compañia-de-tus-padre-o-tutores',
    label:
      '¿Participas en muchas actividades en compañia de tus padres o quien te cuida?',
    options: {
      si: {
        label: 'Sí',
        value: 'si'
      },
      no: {
        label: 'No',
        value: 'no'
      }
    },
    required: true
  },
  'la-mayoria-de-las-veces-te-prestan-atencion-tus-padres-o-tutores': {
    key: 'la-mayoria-de-las-veces-te-prestan-atencion-tus-padres-o-tutores',
    label:
      '¿La mayoría de veces te prestan atención tus padres o quien te cuida cuando les hablas?',
    options: {
      si: {
        label: 'Sí',
        value: 'si'
      },
      no: {
        label: 'No',
        value: 'no'
      }
    },
    required: true
  },
  'saben-tus-padres-o-tutores-como-piensas-o-te-sientes': {
    key: 'saben-tus-padres-o-tutores-como-piensas-o-te-sientes',
    label: '¿Saben tus padres o tutores cómo realmente piensas o te sientes?',
    options: {
      si: {
        label: 'Sí',
        value: 'si'
      },
      no: {
        label: 'No',
        value: 'no'
      }
    },
    required: true
  },
  'discutes-gritando-o-levantando-la-voz-frecuente': {
    key: 'discutes-gritando-o-levantando-la-voz-frecuente',
    label:
      '¿Discutes frecuentemente levantando la voz y gritando con tus padres o quien te cuida?',
    options: {
      si: {
        label: 'Sí',
        value: 'si'
      },
      no: {
        label: 'No',
        value: 'no'
      }
    },
    required: true
  },
  'le-gusta-a-tus-padre-o-tutore-estar-y-hablar-contigo': {
    key: 'le-gusta-a-tus-padre-o-tutore-estar-y-hablar-contigo',
    label: '¿Les gusta a tus padres o quien te cuida estar y hablar contigo?',
    options: {
      si: {
        label: 'Sí',
        value: 'si'
      },
      no: {
        label: 'No',
        value: 'no'
      }
    },
    required: true
  },
  'tinen-tus-padres-o-tutores-una-idea-de-lo-que-te-interesa': {
    key: 'tinen-tus-padres-o-tutores-una-idea-de-lo-que-te-interesa',
    label:
      '¿Tienen tus padres o quien te cuida una idea relativamente buena de lo que te interesa?',
    options: {
      si: {
        label: 'Sí',
        value: 'si'
      },
      no: {
        label: 'No',
        value: 'no'
      }
    },
    required: true
  },
  'tus-padres-estan-de-acuerdo-en-la-forma-que-te-educan': {
    key: 'tus-padres-estan-de-acuerdo-en-la-forma-que-te-educan',
    label:
      '¿Tus padres están de acuerdo en la forma en la que ellos te educan?',
    options: {
      si: {
        label: 'Sí',
        value: 'si'
      },
      no: {
        label: 'No',
        value: 'no'
      }
    },
    required: true
  },
  'tus-padre-o-tutores-te-asignan-una-cantidad-fija-de-dinero': {
    key: 'tus-padre-o-tutores-te-asignan-una-cantidad-fija-de-dinero',
    label:
      '¿Tus padres o quien te cuida te asignan una cantidad fija de dinero?',
    options: {
      si: {
        label: 'Sí',
        value: 'si'
      },
      no: {
        label: 'No',
        value: 'no'
      }
    },
    required: true
  },
  'cuanto-dinero-te-asignan': {
    key: 'cuanto-dinero-te-asignan',
    label: '¿Cuánto?',
    required: true
  },
  'cuanto-dinero-utilizas-a-la-semana-en-alcohol-o-drogas': {
    key: 'cuanto-dinero-utilizas-a-la-semana-en-alcohol-o-drogas',
    label: '¿Cuánto dinero utilizas a la semana en alcohol o drogas?',
    required: true
  },
  'tus-padres-o-tutores-saben-que-tomas-alcohol-o-consumes-drogas': {
    key: 'tus-padres-o-tutores-saben-que-tomas-alcohol-o-consumes-drogas',
    label:
      '¿Tus padres o quien te cuida saben que tomas alcohol o consumes drogas?',
    options: {
      si: {
        label: 'Sí',
        value: 'si'
      },
      no: {
        label: 'No',
        value: 'no'
      }
    },
    required: true
  },
  'te-han-puesto-reglas-muy-claras-sobre-el-consumo-de-alcohol-o-drogas': {
    key: 'te-han-puesto-reglas-muy-claras-sobre-el-consumo-de-alcohol-o-drogas',
    label:
      '¿Te han puesto reglas muy claras sobre el consumo de alcohol o drogas?',
    options: {
      si: {
        label: 'Sí',
        value: 'si'
      },
      no: {
        label: 'No',
        value: 'no'
      }
    },
    required: true
  },
  'es-importante-por-ti-cumplir-esas-reglas': {
    key: 'es-importante-por-ti-cumplir-esas-reglas',
    label: '¿Es importante para ti cumplir esas reglas o normas?',
    options: {
      si: {
        label: 'Sí',
        value: 'si'
      },
      no: {
        label: 'No',
        value: 'no'
      }
    },
    required: true
  }
};
