import { FollowUpRoutes } from 'src/meta/follow-up/FollowUpMeta';
import { RouteRecordRaw } from 'vue-router';
import { RoutesMeta } from './routesMeta';
import { defineAsyncComponent } from 'vue';
export const FollowUpRoutesExplanationsFullscreen: RouteRecordRaw = {
  path: '/',
  component: () => import('src/layouts/LayoutFullScreen.vue'),
  children: [
    {
      path: FollowUpRoutes.WELCOME_FOLLOWUP.key,
      component: () => import('pages/PresentationManager.vue'),
      props: {
        stateURL: FollowUpRoutes.WELCOME_FOLLOWUP.key,
        componentList: [
          {
            component: defineAsyncComponent(
              () => import('src/pages/seguimiento/BienvenidoSeguimiento.vue')
            ),
            mode: 'edit'
          }
        ]
      },
      meta: {
        title: FollowUpRoutes.WELCOME_FOLLOWUP.title,
        titleInBreadcrumb: true,
        titleIcon: FollowUpRoutes.WELCOME_FOLLOWUP.icon
      }
    },
    {
      path: FollowUpRoutes.END_FOLLOWUP.key,
      component: () => import('pages/PresentationManager.vue'),
      props: {
        stateURL: FollowUpRoutes.END_FOLLOWUP.key,
        componentList: [
          {
            component: defineAsyncComponent(
              () => import('src/pages/seguimiento/FinSeguimiento.vue')
            ),
            mode: 'edit'
          }
        ]
      },
      meta: {
        title: FollowUpRoutes.END_FOLLOWUP.title,
        titleInBreadcrumb: true,
        titleIcon: FollowUpRoutes.END_FOLLOWUP.icon
      }
    }
  ]
};

export const FollowUpRoutesGuarded: RouteRecordRaw = {
  path: '/',
  component: () => import('src/layouts/PacientSistemaLayout.vue'),
  children: [
    {
      path: RoutesMeta.THREE_MONTHS.key,
      component: () => import('src/pages/pacient/PacienteSeguimiento.vue'),
      props: (route) => ({
        pacientId: route.params.pacientId
      }),
      meta: {
        title: RoutesMeta.THREE_MONTHS.title,
        titleInBreadcrumb: true,
        titleIcon: RoutesMeta.THREE_MONTHS.icon,
        requiresFollowUpCheck: true,
        followUpEventNumber: 1
      }
    },
    {
      path: RoutesMeta.SIX_MONTHS.key,
      component: () => import('src/pages/pacient/PacienteSeguimiento.vue'),
      props: (route) => ({
        pacientId: route.params.pacientId
      }),
      meta: {
        title: RoutesMeta.SIX_MONTHS.title,
        titleInBreadcrumb: true,
        titleIcon: RoutesMeta.SIX_MONTHS.icon,
        requiresFollowUpCheck: true,
        followUpEventNumber: 2
      }
    },
    {
      path: RoutesMeta.TWELVE_MONTHS.key,
      component: () => import('src/pages/pacient/PacienteSeguimiento.vue'),
      props: (route) => ({
        pacientId: route.params.pacientId
      }),
      meta: {
        title: RoutesMeta.TWELVE_MONTHS.title,
        titleInBreadcrumb: true,
        titleIcon: RoutesMeta.TWELVE_MONTHS.icon,
        requiresFollowUpCheck: true,
        followUpEventNumber: 3
      }
    }
  ]
};
