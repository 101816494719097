import CodigoInvitacion from 'src/components/register/CodigoInvitacion.vue';
import ElectionTerapeuta from 'src/components/register/ElectionTerapeuta.vue';
import InformacionAcademica from 'src/components/register/InformacionAcademica.vue';
import InformacionDomiciliaria from 'src/components/register/InformacionDomiciliaria.vue';
import ProfilePic from 'src/components/register/ProfilePic.vue';
import QDatePicker from 'src/components/blitzForm/QDatePicker.vue';
import QOptionWrapper from 'src/components/blitzForm/QOptionWrapper.vue';
import QSliderWrapper from 'src/components/blitzForm/QSliderWrapper.vue';
import SelectOrComponent from 'src/components/form/SelectOrComponent.vue';
import SexPicker from 'src/components/register/SexPicker.vue';
import { boot } from 'quasar/wrappers';

// "async" is optional;
// more info on params: https://v2.quasar.dev/quasar-cli/boot-files
export default boot(({ app }) => {
  // something to do
  app.component('ProfilePic', ProfilePic);
  app.component('ElectionTerapeuta', ElectionTerapeuta);
  app.component('CodigoInvitacion', CodigoInvitacion);
  app.component('QDatePicker', QDatePicker);
  app.component('SexPicker', SexPicker);
  app.component('InformacionAcademica', InformacionAcademica);
  app.component('InformacionDomiciliaria', InformacionDomiciliaria);
  app.component('QOptionWrapper', QOptionWrapper);
  app.component('QSliderWrapper', QSliderWrapper);
  app.component('SelectOrComponent', SelectOrComponent);
});
