import { AreaSaludData } from 'src/types/initial-evaluation/evaluacion-personal';
import { FIELDS } from 'src/meta/initial-evaluation/AreaSaludMeta';
import { Icontext } from 'src/types/blitzar';
import { InitialEvaluationMetaTitleClasses } from 'src/meta/initial-evaluation/InitialEvaluationMeta';

type ctx = Icontext<Partial<AreaSaludData>>;

export const AreaSaludSchema = [
  {
    component: 'p',
    slot: FIELDS.title.label,
    componentClasses: InitialEvaluationMetaTitleClasses
  },
  {
    id: FIELDS['actualmente-estas-siendo-atendido-de-un-problema-de-salud'].key,
    label:
      FIELDS['actualmente-estas-siendo-atendido-de-un-problema-de-salud'].label,
    required:
      FIELDS['actualmente-estas-siendo-atendido-de-un-problema-de-salud']
        .required,
    options: Object.values(
      FIELDS['actualmente-estas-siendo-atendido-de-un-problema-de-salud']
        .options
    ),
    component: 'QOptionWrapper',
    type: 'radio',
    key: 'static'
  },
  {
    id: FIELDS[
      'estas-tomando-algun-medicamento-con-prescripcion-medica-o-auto-medicando'
    ].key,
    label:
      FIELDS[
        'estas-tomando-algun-medicamento-con-prescripcion-medica-o-auto-medicando'
      ].label,
    required:
      FIELDS[
        'estas-tomando-algun-medicamento-con-prescripcion-medica-o-auto-medicando'
      ].required,
    options: Object.values(
      FIELDS[
        'estas-tomando-algun-medicamento-con-prescripcion-medica-o-auto-medicando'
      ].options
    ),
    component: 'QOptionWrapper',
    type: 'radio',
    key: 'static2'
  },
  {
    id: FIELDS['cual-medicamento'].key,
    label: FIELDS['cual-medicamento'].label,
    component: 'q-input',
    key: 'dinamico',
    dynamicProps: ['showCondition', 'required'],
    required: (val: number, { formData }: ctx) =>
      formData[
        'estas-tomando-algun-medicamento-con-prescripcion-medica-o-auto-medicando'
      ] === 'si',
    showCondition: (val: number, { formData }: ctx) =>
      formData[
        'estas-tomando-algun-medicamento-con-prescripcion-medica-o-auto-medicando'
      ] === 'si'
  }
];
