import { InitialEvaluationRoutes } from 'src/meta/initial-evaluation/InitialEvaluationMeta';
export const FIELDS = {
  title: {
    label: InitialEvaluationRoutes.HEALTH_INFO.title
  },
  'actualmente-estas-siendo-atendido-de-un-problema-de-salud': {
    key: 'actualmente-estas-siendo-atendido-de-un-problema-de-salud',
    label:
      '¿Estás siendo atendido por algún problema de salud en la actualidad?',
    options: {
      si: {
        label: 'Sí',
        value: 'si'
      },
      no: {
        label: 'No',
        value: 'no'
      }
    },
    required: true
  },
  'estas-tomando-algun-medicamento-con-prescripcion-medica-o-auto-medicando': {
    key: 'estas-tomando-algun-medicamento-con-prescripcion-medica-o-auto-medicando',
    label:
      '¿Estás tomando algún medicamento por prescripción médica o te estás auto-medicamento?',
    options: {
      si: {
        label: 'Sí',
        value: 'si'
      },
      no: {
        label: 'No',
        value: 'no'
      }
    },
    required: true
  },
  'cual-medicamento': {
    key: 'cual-medicamento',
    label: '¿Cuál?',
    required: true
  }
};
