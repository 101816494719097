export const FollowUpEvaluationInfoMeta = {
  '/seguimiento': { url: '/seguimiento', name: 'En espera' },
  '/tres-meses': { url: '/tres-meses', name: 'Mis primeros tres meses' },
  '/seis-meses': { url: '/seis-meses', name: 'Seis meses después' },
  '/doce-meses': { url: '/doce-meses', name: 'A un año del cambio' },
  '/dashboard': { url: '/dashboard', name: 'Fin sesiones' }
};

export const FollowUpSessionURLs = Object.values(
  FollowUpEvaluationInfoMeta
).map((sessionMeta) => sessionMeta.url);
