/**
 * This class will be a wrapper that handles accessing the next and previous States
 */
export class State {
  flowStateURLs: string[] = [];
  _stateIndex: number;
  get next(): State {
    // The next state were the user needs to continue
    return new State({
      currentIndex: this._stateIndex + 1,
      flowStateURLs: this.flowStateURLs
    });
  }
  get previous(): State {
    // The previous state in case the user wants to go back and check some answers
    return new State({
      currentIndex: this._stateIndex - 1,
      flowStateURLs: this.flowStateURLs
    });
  }
  get url(): string {
    // The url of the current state
    return this.flowStateURLs[this._stateIndex];
  }
  get value(): number {
    // The index value of the current state
    return this._stateIndex;
  }
  can_proceed(toState: State) {
    return this._stateIndex >= toState._stateIndex;
  }
  constructor({
    currentURL,
    currentIndex,
    flowStateURLs
  }: {
    currentURL?: string;
    currentIndex?: number;
    flowStateURLs: string[];
  }) {
    this.flowStateURLs = flowStateURLs;
    // We need to make this class PacientId aware so it can ignore it when its building the
    // states and when it redirects to the next one
    if (currentURL) {
      if (currentURL[0] !== '/') {
        // Due changes in the Route Meta objects and to facilitate re-use between
        // pacient and therapist as pacient routes we removed the absolute route path
        // i.e. before `/evaluacion/personal` after `evaluacion/personal`
        // So to keep everything working with the data that is already in the database
        // We are just prepending the docId with a foward slash
        currentURL = `/${currentURL}`;
      }
      const index = this.flowStateURLs.indexOf(currentURL);
      if (index === -1) {
        this._stateIndex = 1;
      }
      this._stateIndex = index;
    } else if (currentIndex) {
      if (currentIndex <= 0) {
        this._stateIndex = 1;
      } else {
        this._stateIndex = currentIndex;
      }
    } else {
      throw Error(
        'State requires either a `currentURL` or `currentIndex` to be intantiated'
      );
    }
  }
}
