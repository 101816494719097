import { EvaluationRoutes } from 'src/meta/evaluation/EvaluationMeta';
import { FollowUpRoutes } from 'src/meta/follow-up/FollowUpMeta';

export const FollowUpThreeMonthsMeta = {
  key: 'tres-meses/',
  title: 'Seguimiento: 3 meses',
  icon: 'troubleshoot'
};

/**
 * Using a poor man dictionary comprehension we prepend to every key `/evaluacion`
 */
export const FollowUpThreeMonthsRoutesUnordered: typeof EvaluationRoutes = {
  ...Object.fromEntries(
    Object.entries(EvaluationRoutes).map(([key, route]) => {
      return [
        key,
        {
          ...route,
          key: FollowUpThreeMonthsMeta.key + route.key
        }
      ];
    })
  ),
  ...Object.fromEntries(
    Object.entries(FollowUpRoutes).map(([key, route]) => {
      return [
        key,
        {
          ...route,
          key: FollowUpThreeMonthsMeta.key + route.key
        }
      ];
    })
  )
};

export const FollowUpThreeMonthsRoutes = Object.fromEntries(
  Object.entries(FollowUpThreeMonthsRoutesUnordered).sort(
    ([, route], [, route2]) => {
      return route.index - route2.index;
    }
  )
);

export const FollowUpThreeMonthURLs = [
  ...(
    Object.keys(FollowUpThreeMonthsRoutes) as Array<
      keyof typeof FollowUpThreeMonthsRoutes
    >
  ).map((key) => `/${FollowUpThreeMonthsRoutes[key].key}`),
  '/dashboard',
  '/tres-meses'
];
