import FollowUpSixMonthsRoutes from 'src/router/FollowUpSixMonthsRoutes';
import FollowUpThreeMonthsRoutes from 'src/router/FollowUpThreeMonthsRoutes';
import FollowUpTwelveMonthsRoutes from 'src/router/FollowUpTwelveMonthsRoutes';
import InitialInterviewRoutes from 'src/router/InitialInterviewRoutes';
import { PacienteRoutes } from 'src/router/PacienteRoutes';
import { RouteRecordRaw } from 'vue-router';
import TerapeutaRoutes from 'src/router/TerapeutaRoutes';

const routes: RouteRecordRaw[] = [
  // Paciente routes
  {
    path: '/',
    component: () => import('layouts/PacienteLayout.vue'),
    children: [
      {
        path: '',
        component: () => import('pages/landing/LandingPaciente.vue')
      },
      {
        path: '/login',
        component: () => import('pages/auth/LoginPaciente.vue'),
        meta: { guest: true }
      },
      {
        path: '/register',
        component: () => import('src/pages/register/RegisterWrapper.vue'),
        meta: { guest: true }
      },
      {
        path: '/privacy-and-terms',
        component: () => import('pages/landing/PrivacyTerms.vue')
      },
      {
        path: '/auth/confirmar-correo/:token',
        component: () => import('pages/auth/ConfirmarCorreo.vue'),
        props: (route) => ({
          token: route.params.token
        })
      },
      {
        path: '/auth/solicitar-restablecer-cuenta/',
        component: () => import('pages/auth/SolicitarRestablecerCuenta.vue')
      },
      {
        path: '/auth/restablecer-cuenta/:token?',
        component: () => import('pages/auth/RestablecerCuenta.vue'),
        props: (route) => ({
          token: route.params.token
        })
      }
    ]
  },

  {
    path: '/',
    component: () => import('src/layouts/LayoutFullScreen.vue'),
    children: [
      {
        path: 'dashboard',
        component: () => import('src/pages/dashboard/DashboardBase.vue'),
        //  suspenseHack(
        //   defineAsyncComponent(
        //     () => import('src/pages/dashboard/DashboardBase.vue')
        //   )
        // ),
        meta: {
          requiresAuth: true,
          requiresInitialEvaluation: true,
          requiresInitialRegistration: true
        }
      }
      // {
      //   path: '',
      //   component: () => import('src/layouts/WrapperSuspense.vue'),
      //   children: [
      //   ]
      // }
    ]
  },

  {
    path: '/terapeutas',
    component: () => import('layouts/TerapeutaLayout.vue'),
    children: [
      {
        path: '',
        components: {
          default: () => import('pages/landing/LandingTerapeuta.vue')
        }
      },
      {
        path: 'login',
        component: () => import('pages/auth/LoginTerapeuta.vue'),
        meta: { guest: true }
      }
      // onlyForTerapeuta
      // { path: 'login', component: () => import('pages/login/LoginTerapeuta.vue'), meta: { guest: true } },
      // { path: 'register', component: () => import('pages/register/RegisterTerapeuta.vue'), meta: { guest: true } },
      // {
      //   path: '/terapeuta/registro',
      //   component: () => import('pages/register/RegisterTerapeutaPersonal.vue'),
      //   props: { flowState: flowStateModules.registerTerapeuta, mode: 'edit' }
      // }
    ]
  },
  {
    path: '/logout',
    component: () => import('pages/auth/LogoutUser.vue')
  },
  ...InitialInterviewRoutes,
  ...FollowUpThreeMonthsRoutes,
  ...FollowUpSixMonthsRoutes,
  ...FollowUpTwelveMonthsRoutes,
  ...PacienteRoutes,
  // Terapeuta routes
  ...TerapeutaRoutes,
  // Always leave this as last one,
  // but you can also remove it
  {
    path: '/:catchAll(.*)*',
    component: () => import('pages/ErrorNotFound.vue')
  }
];

export default routes;
