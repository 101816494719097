import {
  InstitucionViewKeyName,
  InstitucionesData
} from 'src/types/Instituciones';
import { Ref, ref } from 'vue';

import PouchDB from 'pouchdb';
import { useDbStore } from 'stores/db';

export function useInstituciones(institutionsKeys?: string[]) {
  const dbStore = useDbStore();
  const db: Ref<PouchDB.Database | void> = ref(undefined);
  const institucionesKeyName = ref<InstitucionViewKeyName[]>([
    {
      id: 'Sin_institucion',
      value: 'No pertenezco a una institucion',
      key: 'Sin_institucion'
    },
    {
      id: 'No_registrada',
      value: 'Mi institución no está registrada',
      key: 'No_registrada'
    }
  ]);
  // const Instituciones = reactive<{rows: InstitucionViewKeyName[]}>({ rows: [] });
  dbStore
    .getSyncDBsAuth(
      [
        process.env.VUE_APP_COUCHDB_PROTOCOL,
        `${process.env.VUE_APP_COUCHDB_ADDRESS}instituciones`
      ],
      'instituciones'
    )
    .then(async (dbInstituciones) => {
      db.value = dbInstituciones;
      const res = await db.value.query<InstitucionesData>('list/key-name', {
        keys: institutionsKeys
      });
      institucionesKeyName.value = [...institucionesKeyName.value, ...res.rows];
      console.log(res);
      console.log(institucionesKeyName.value);
    })
    .catch(console.error.bind(console));

  return {
    // ...toRefs(Instituciones),
    institucionesKeyName
  };
}
