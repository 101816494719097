export const actionButtonDefaults = {
  save: {
    component: 'q-btn',
    color: 'secondary',
    id: 'saveBtn',
    unelevated: true,
    rounded: true,
    iconRight: 'chevron_right',
    fieldClasses: '',
    internalLabels: true
  }
};
