import { ISesion } from 'src/types/PIBA/Calendario';
import { CouchDbResponse } from './couchdb-response';

export interface Cita {
  done: boolean,
  sesionNumber: number,
}

export type Citas = CouchDbResponse<Cita, number[]>

/**
 * Extracts the userId from the eventID
 *
 * @export
 * @param {string} eventoID
 * - example `7e843dc167b0478a86d6be834d1378d9_E_2021-10-12T09:05:00.000Z_5.96`
 * @returns {string} userId
 */
export function getUserIdFromEventID(eventoID: string) {
  return eventoID.split('_')[0];
}
/**
 * Extracts the date string from the eventID
 *
 * @export
 * @param {string} eventoID
 * - example `7e843dc167b0478a86d6be834d1378d9_E_2021-10-12T09:05:00.000Z_5.96`
 * @returns {string} eventDate
 */
export function getDateFromEventID(eventoID: string) {
  return eventoID.split('_')[2];
}

export type CitasTerapeuta = CouchDbResponse<
Pick<ISesion, '_id' | 'sesionNumber' | 'done' | 'eventType'>,
[terapeutaKey: string, year: number, month: number, day: number]
>;
