import { boot } from 'quasar/wrappers';

export default boot(({ router }) => {
  router.beforeEach((to, from, next) => {
    // Handles setting the route.meta.title as the page title
    // Note that this could be overriden in child components to give greater
    // context to the user

    if (to.meta.title) {
      // If the route has a defined title in the meta properties use that
      document.title = to.meta.title as string;
    } else {
      // Default to the site name
      document.title = process.env.VUE_APP_SITE_TITLE;
    }
    next();
  });
});
