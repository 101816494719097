import { AreaEscolarLaboralData } from 'src/types/initial-evaluation/evaluacion-personal';
import { FIELDS } from 'src/meta/initial-evaluation/AreaEscolarLaboralMeta';
import { Icontext } from 'src/types/blitzar';
import { InitialEvaluationMetaTitleClasses } from 'src/meta/initial-evaluation/InitialEvaluationMeta';
import { options } from 'src/meta/initial-evaluation/PositMeta';

type ctx = Icontext<Partial<AreaEscolarLaboralData>>;
export const AreaEscolarLaboralSchema = [
  {
    component: 'p',
    slot: FIELDS.title.label,
    componentClasses: InitialEvaluationMetaTitleClasses
  },
  {
    id: FIELDS['ocupacion-principal'].key,
    label: FIELDS['ocupacion-principal'].label,
    required: FIELDS['ocupacion-principal'].required,
    options: Object.values(FIELDS['ocupacion-principal'].options),
    component: 'QOptionWrapper',
    type: 'radio'
  },
  {
    id: FIELDS['fuiste-estudiante-el-año-pasado'].key,
    label: FIELDS['fuiste-estudiante-el-año-pasado'].label,
    options: Object.values(FIELDS['fuiste-estudiante-el-año-pasado'].options),
    component: 'QOptionWrapper',
    type: 'radio',
    dynamicProps: ['required', 'showCondition'],
    required: (
      val: string,
      { formData }: { formData: AreaEscolarLaboralData }
    ) =>
      formData['ocupacion-principal'] === 'Estudiar' ||
      formData['ocupacion-principal'] === 'Estudiar y trabajar',
    showCondition: (
      val: string,
      { formData }: { formData: AreaEscolarLaboralData }
    ) =>
      formData['ocupacion-principal'] === 'Estudiar' ||
      formData['ocupacion-principal'] === 'Estudiar y trabajar'
  },
  {
    id: FIELDS['asistes-a-la-escuela-de-manera-regular'].key,
    label: FIELDS['asistes-a-la-escuela-de-manera-regular'].label,
    options: Object.values(
      FIELDS['asistes-a-la-escuela-de-manera-regular'].options
    ),
    component: 'QOptionWrapper',
    dynamicProps: ['required', 'showCondition'],
    required: (
      val: string,
      { formData }: { formData: AreaEscolarLaboralData }
    ) =>
      formData['ocupacion-principal'] === 'Estudiar' ||
      formData['ocupacion-principal'] === 'Estudiar y trabajar',
    showCondition: (
      val: string,
      { formData }: { formData: AreaEscolarLaboralData }
    ) =>
      formData['ocupacion-principal'] === 'Estudiar' ||
      formData['ocupacion-principal'] === 'Estudiar y trabajar'
  },
  {
    id: FIELDS['promedio-escolar'].key,
    label: FIELDS['promedio-escolar'].label,
    component: 'q-input',
    type: 'number',
    dynamicProps: ['required', 'showCondition'],
    required: (
      val: string,
      { formData }: { formData: AreaEscolarLaboralData }
    ) =>
      formData['ocupacion-principal'] === 'Estudiar' ||
      formData['ocupacion-principal'] === 'Estudiar y trabajar',
    showCondition: (
      val: string,
      { formData }: { formData: AreaEscolarLaboralData }
    ) =>
      formData['ocupacion-principal'] === 'Estudiar' ||
      formData['ocupacion-principal'] === 'Estudiar y trabajar'
  },
  {
    id: FIELDS['consideras-tu-desempeño-escolar'].key,
    label: FIELDS['consideras-tu-desempeño-escolar'].label,
    options: Object.values(FIELDS['consideras-tu-desempeño-escolar'].options),
    component: 'QOptionWrapper',
    dynamicProps: ['required', 'showCondition'],
    required: (
      val: string,
      { formData }: { formData: AreaEscolarLaboralData }
    ) =>
      formData['ocupacion-principal'] === 'Estudiar' ||
      formData['ocupacion-principal'] === 'Estudiar y trabajar',
    showCondition: (
      val: string,
      { formData }: { formData: AreaEscolarLaboralData }
    ) =>
      formData['ocupacion-principal'] === 'Estudiar' ||
      formData['ocupacion-principal'] === 'Estudiar y trabajar'
  },
  {
    id: FIELDS['has-dejado-tus-estudios-durante-seis-meses'].key,
    label: FIELDS['has-dejado-tus-estudios-durante-seis-meses'].label,
    options: Object.values(
      FIELDS['has-dejado-tus-estudios-durante-seis-meses'].options
    ),
    component: 'QOptionWrapper',
    dynamicProps: ['required', 'showCondition'],
    required: (
      val: string,
      { formData }: { formData: AreaEscolarLaboralData }
    ) =>
      formData['ocupacion-principal'] === 'Estudiar' ||
      formData['ocupacion-principal'] === 'Estudiar y trabajar',
    showCondition: (
      val: string,
      { formData }: { formData: AreaEscolarLaboralData }
    ) =>
      formData['ocupacion-principal'] === 'Estudiar' ||
      formData['ocupacion-principal'] === 'Estudiar y trabajar'
  },
  {
    id: FIELDS['en-que-trabajas'].key,
    label: FIELDS['en-que-trabajas'].label,
    component: 'q-input',
    dynamicProps: ['required', 'showCondition'],
    required: (
      val: string,
      { formData }: { formData: AreaEscolarLaboralData }
    ) =>
      formData['ocupacion-principal'] === 'Trabajar' ||
      formData['ocupacion-principal'] === 'Estudiar y trabajar',
    showCondition: (
      val: string,
      { formData }: { formData: AreaEscolarLaboralData }
    ) =>
      formData['ocupacion-principal'] === 'Trabajar' ||
      formData['ocupacion-principal'] === 'Estudiar y trabajar'
  },
  {
    id: FIELDS['cuantas-horas-diarias-trabajas'].key,
    label: FIELDS['cuantas-horas-diarias-trabajas'].label,
    options: Object.values(FIELDS['cuantas-horas-diarias-trabajas'].options),
    component: 'QOptionWrapper',
    dynamicProps: ['required', 'showCondition'],
    required: (
      val: string,
      { formData }: { formData: AreaEscolarLaboralData }
    ) =>
      formData['ocupacion-principal'] === 'Trabajar' ||
      formData['ocupacion-principal'] === 'Estudiar y trabajar',
    showCondition: (
      val: string,
      { formData }: { formData: AreaEscolarLaboralData }
    ) =>
      formData['ocupacion-principal'] === 'Trabajar' ||
      formData['ocupacion-principal'] === 'Estudiar y trabajar'
  },
  {
    id: FIELDS['desde-cuando-trabajas'].key,
    label: FIELDS['desde-cuando-trabajas'].label,
    options: FIELDS['desde-cuando-trabajas'].options,
    component: 'SelectOrComponent',
    otherComponent: 'q-input',
    defaultValueForOther: '',
    type: 'date',
    dynamicProps: ['required', 'showCondition'],
    required: (
      val: string,
      { formData }: { formData: AreaEscolarLaboralData }
    ) =>
      formData['ocupacion-principal'] === 'Trabajar' ||
      formData['ocupacion-principal'] === 'Estudiar y trabajar',
    showCondition: (
      val: string,
      { formData }: { formData: AreaEscolarLaboralData }
    ) =>
      formData['ocupacion-principal'] === 'Trabajar' ||
      formData['ocupacion-principal'] === 'Estudiar y trabajar'
  },
  {
    id: FIELDS['cuanto-ganas-mes'].key,
    label: FIELDS['cuanto-ganas-mes'].label,
    component: 'q-input',
    type: 'number',
    dynamicProps: ['required', 'showCondition'],
    required: (
      val: string,
      { formData }: { formData: AreaEscolarLaboralData }
    ) =>
      formData['ocupacion-principal'] === 'Trabajar' ||
      formData['ocupacion-principal'] === 'Estudiar y trabajar',
    showCondition: (
      val: string,
      { formData }: { formData: AreaEscolarLaboralData }
    ) =>
      formData['ocupacion-principal'] === 'Trabajar' ||
      formData['ocupacion-principal'] === 'Estudiar y trabajar'
  }
];
