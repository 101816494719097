import { EvaluationRoutes } from 'src/meta/evaluation/EvaluationMeta';
import { FollowUpRoutes } from 'src/meta/follow-up/FollowUpMeta';

export const FollowUpTwelveMonthsMeta = {
  key: 'doce-meses/',
  title: 'Seguimiento: 12 meses',
  icon: 'troubleshoot'
};

/**
 * Using a poor man dictionary comprehension we prepend to every key `/evaluacion`
 */
export const FollowUpTwelveMonthsRoutesUnordered: typeof EvaluationRoutes = {
  ...Object.fromEntries(
    Object.entries(EvaluationRoutes).map(([key, route]) => {
      return [
        key,
        {
          ...route,
          key: FollowUpTwelveMonthsMeta.key + route.key
        }
      ];
    })
  ),
  ...Object.fromEntries(
    Object.entries(FollowUpRoutes).map(([key, route]) => {
      return [
        key,
        {
          ...route,
          key: FollowUpTwelveMonthsMeta.key + route.key
        }
      ];
    })
  )
};

export const FollowUpTwelveMonthsRoutes = Object.fromEntries(
  Object.entries(FollowUpTwelveMonthsRoutesUnordered).sort(
    ([, route], [, route2]) => {
      return route.index - route2.index;
    }
  )
);

export const FollowUpTwelveMonthURLs = [
  ...(
    Object.keys(FollowUpTwelveMonthsRoutes) as Array<
      keyof typeof FollowUpTwelveMonthsRoutes
    >
  ).map((key) => `/${FollowUpTwelveMonthsRoutes[key].key}`),
  '/doce-meses'
];
