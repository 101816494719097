import { InitialEvaluationRoutes } from 'src/meta/initial-evaluation/InitialEvaluationMeta';
export const FIELDS = {
  title: {
    label: InitialEvaluationRoutes.PERSONAL_SATISFACTION.title
  },
  'que-tan-satisfecho-estas-con-tu-forma-estilo-de-vida-en-este-momento': {
    key: 'que-tan-satisfecho-estas-con-tu-forma-estilo-de-vida-en-este-momento',
    label:
      '¿Qué tan satisfecho estás con tu forma (estilo) de vida en este momento?',
    options: {
      'muy-satisfecho': {
        label: 'Muy satisfecho',
        value: 'muy-satisfecho'
      },
      satisfecho: {
        label: 'Satisfecho',
        value: 'satisfecho'
      },
      inseguro: {
        label: 'Inseguro',
        value: 'inseguro'
      },
      insatisfecho: {
        label: 'Insatisfecho',
        value: 'insatisfecho'
      },
      'muy-insatisfecho': {
        label: 'Muy insatisfecho',
        value: 'muy-insatisfecho'
      }
    },
    required: true
  },
  'cuales-son-tus-metas-a-futuro': {
    key: 'cuales-son-tus-metas-a-futuro',
    label: '¿Cuáles son tus metas para el futuro?',
    required: true
  }
};
