import { RouteLocationNormalized, RouteRecordRaw } from 'vue-router';

import { Breadcrumb } from 'src/types/breadcrumb';
import { EvaluationRoutesExplanationsFullscreen } from './EvaluationRoutes';
import { EvaluationRoutesForms } from 'src/router/EvaluationRoutes';
import { FollowUpRoutesExplanationsFullscreen } from './FollowUpRoutes';
import { FollowUpTwelveMonthsMeta } from 'src/meta/follow-up/twelve-months';

const FollowUpTwelveMonthsBreadcrumb: Breadcrumb = {
  label: FollowUpTwelveMonthsMeta.title,
  icon: FollowUpTwelveMonthsMeta.icon
};

export const FollowUpTwelveMonthsRoutesForms: RouteRecordRaw = {
  path: '/',
  component: () => import('layouts/PacienteLayout.vue'),
  children: [
    ...(EvaluationRoutesForms.children?.map((routeRecord) => {
      const newProps = (route: RouteLocationNormalized) => ({
        // @ts-expect-error We know better than Typescript and know those routes will have the required properties
        ...routeRecord.props,
        pacientId: route.params.pacientId,
        flow: 'follow-up-12',
        breadcrumbs: [FollowUpTwelveMonthsBreadcrumb],
        // @ts-expect-error We know better than Typescript and know those routes will have the required properties
        stateURL: FollowUpTwelveMonthsMeta.key + routeRecord.props.stateURL
      });
      return {
        ...routeRecord,
        path: FollowUpTwelveMonthsMeta.key + routeRecord.path,
        props: newProps
      } as RouteRecordRaw;
    }) ?? [])
  ],
  meta: {
    requiresAuth: true,
    // requiresInitialEvaluation: true,
    onlyForPaciente: true
  }
};

export const FollowUpTwelveMonthsRoutesExplanationsFullscreen: RouteRecordRaw =
  {
    path: '/',
    component: () => import('src/layouts/LayoutFullScreen.vue'),
    children: [
      ...(EvaluationRoutesExplanationsFullscreen.children?.map(
        (routeRecord) => {
          const newProps = (route: RouteLocationNormalized) => ({
            // @ts-expect-error We know better than Typescript and know those routes will have the required properties
            ...routeRecord.props,
            pacientId: route.params.pacientId,
            flow: 'follow-up-12',
            breadcrumbs: [FollowUpTwelveMonthsBreadcrumb],
            // @ts-expect-error We know better than Typescript and know those routes will have the required properties
            stateURL: FollowUpTwelveMonthsMeta.key + routeRecord.props.stateURL
          });
          return {
            ...routeRecord,
            path: FollowUpTwelveMonthsMeta.key + routeRecord.path,
            props: newProps
          } as RouteRecordRaw;
        }
      ) ?? []),
      ...(FollowUpRoutesExplanationsFullscreen.children?.map((routeRecord) => {
        const newProps = (route: RouteLocationNormalized) => ({
          // @ts-expect-error We know better than Typescript and know those routes will have the required properties
          ...routeRecord.props,
          pacientId: route.params.pacientId,
          flow: 'follow-up-12',
          breadcrumbs: [FollowUpTwelveMonthsBreadcrumb],
          // @ts-expect-error We know better than Typescript and know those routes will have the required properties
          stateURL: FollowUpTwelveMonthsMeta.key + routeRecord.props.stateURL
        });

        return {
          ...routeRecord,
          path: FollowUpTwelveMonthsMeta.key + routeRecord.path,
          props: newProps
        } as RouteRecordRaw;
      }) ?? [])
    ]
  };

const routes: RouteRecordRaw[] = [
  FollowUpTwelveMonthsRoutesForms,
  FollowUpTwelveMonthsRoutesExplanationsFullscreen
];
export default routes;
