export const FIELDS = {
  names: {
    key: 'names',
    label: 'Nombre/s',
    placeholder: 'Nombre/s',
    required: true
  },
  lastNames: {
    key: 'lastNames',
    label: 'Apellido/s',
    placeholder: 'Apellido/s',
    required: true
  },
  'attachments.profile-pics@profile_picture': {
    key: 'attachments.profile-pics@profile_picture',
    label: 'Foto de perfil',
    required: true
  },
  institucion: {
    key: 'institucion',
    label: 'Institución',
    placeholder: 'Institución',
    required: true
  },
  puesto: {
    key: 'puesto',
    label: 'Puesto',
    placeholder: 'Puesto',
    required: true
  },
  codigoInvitacion: {
    key: 'codigoInvitacion',
    label: 'Código de invitación',
    required: true
  }
};
