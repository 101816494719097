/* eslint-disable max-classes-per-file */

export interface attachmentType {
  _id: string;
  data: unknown;
  type: string;
}

export interface DocWithFileAttachments {
  attachments?: {
    [key: string]: File;
  };
}
export interface _attachmentsFiles {
  [key: string]: File;
}
interface attachmentsInternalPouch {
  content_type: string;
  data: unknown;
}
export interface attachmentsPouch {
  [key: string]: attachmentsInternalPouch;
}

export class PouchDBInstancesError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'PouchDB instances are undefined';
  }
}

export class PouchDBFailedLoginError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'Error logging in to remoteDB';
  }
}

export class PouchDBFlowStateError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'Error modifying flowStateDoc';
  }
}

export type PouchDoc<T> = T & PouchDB.Core.GetMeta & PouchDB.Core.IdMeta;
