import { Ref, unref } from 'vue';
import axios, { AxiosError, AxiosInstance, AxiosResponse } from 'axios';

import { boot } from 'quasar/wrappers';
import { superloginAuthResponse } from 'src/types/sl-nx/auth';
import to from 'await-to-js';
import { useUserStore } from 'stores/user';

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $axios: AxiosInstance;
  }
}

// Be careful when using SSR for cross-request state pollution
// due to creating a Singleton instance here;
// If any client changes this (global) instance, it might be a
// good idea to move this instance creation inside of the
// "export default () => {}" function below (which runs individually
// for each client)
const api = axios.create({ baseURL: process.env.VUE_APP_AUTH_SERVER });

export function updateAuthHeaders(auth: superloginAuthResponse) {
  api.defaults.headers.common.Authorization = `Bearer ${auth.token}:${auth.password}`;
}

export function removeAuthHeaders() {
  api.defaults.headers.common.Authorization = '';
  delete api.defaults.headers.common.Authorization;
}

export default boot(async ({ app, router }) => {
  // for use inside Vue files (Options API) through this.$axios and this.$api
  const userStore = useUserStore();
  if (userStore.auth) {
    try {
      updateAuthHeaders(userStore.auth);
      await userStore.loginCouch();
      await userStore.getProfilePicture().catch(console.error.bind(console));
      await userStore.shouldRefreshSession();
    } catch (e) {
      console.error(e);
      userStore.logout();
      router.push('/login');
    }
  }

  app.config.globalProperties.$axios = axios;
  // ^ ^ ^ this will allow you to use this.$axios (for Vue Options API form)
  //       so you won't necessarily have to import axios in each vue file

  app.config.globalProperties.$api = api;
  // ^ ^ ^ this will allow you to use this.$api (for Vue Options API form)
  //       so you can easily perform requests against your app's API
});

function logAxiosErrors(error: AxiosError) {
  console.log.bind(console);
  if ('response' in error && error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    console.error(error.response.data);
    console.error(error.response.status);
    console.error(error.response.headers);
  } else if ('request' in error && error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    console.error(error.request);
  } else {
    // Something happened in setting up the request that triggered an Error
    console.error(error);
  }
  console.log(error.toJSON());
}

export async function hasConnection() {
  const [err] = await to<AxiosResponse | undefined, AxiosError>(
    axios.head(
      `${process.env.VUE_APP_COUCHDB_PROTOCOL}${process.env.VUE_APP_COUCHDB_ADDRESS}`
    )
  );
  if (err) {
    if (err.message === 'Network Error') {
      // console.error('------------------------ OFFLINE MODE ---------------------');
      return false;
    }
  }
  // console.info('+++++++++++++++++++++++ ONNLINE MODE ++++++++++++++++++++++');
  return true;
}

export { api, axios, logAxiosErrors };
export type { AxiosError, AxiosResponse };

interface fetcherOptions {
  key: string;
  paramsGet?: Record<string, Ref<unknown> | unknown>;
  paramsPost?: Record<string, Ref<unknown> | unknown>;
}
/**
 * Maps through an object properties and aplies the mapFn in each property value
 * and assigns it to the property.
 * Finally it returns the new object.
 * @param object
 * @param mapFn
 * @see https://stackoverflow.com/a/14810722
 */
function objectMap<T, U>(object: Record<string, T>, mapFn: (value: T) => U) {
  return Object.keys(object).reduce<Record<string, U>>((result, key) => {
    result[key] = mapFn(object[key]);
    return result;
  }, {});
}

export async function fetcher<Data>(options: fetcherOptions) {
  // console.log('fetching with options: ', options);
  // const key = isFunction(options.key) ? options.key() : options.key;
  // console.log(key);
  // if (!key) {
  //   console.debug('Key is falsy', key);
  //   throw Error('Key is falsy');
  // }
  // if (Array.isArray(key)) {
  //   console.debug('Key is an array', key);
  //   throw Error('Key is an array');
  // }
  let response: AxiosResponse<Data>;
  if (options.paramsPost) {
    const paramsUnrefed = objectMap(options.paramsPost, (value) =>
      unref(value)
    );
    response = await api.post<Data>(options.key, paramsUnrefed);
  } else if (options.paramsGet) {
    const paramsUnrefed = objectMap(options.paramsGet, (value) => unref(value));
    response = await api.get<Data>(options.key, { params: paramsUnrefed });
  } else {
    response = await api.get<Data>(options.key);
  }
  console.log(response);
  return response.data;
}
