import PouchDB from 'pouchdb';
import PouchWorker from 'worker-pouch';
import { Ref } from 'vue';
import { defineStore } from 'pinia';
import { hasConnection } from 'src/boot/axios';
import to from 'await-to-js';
import { useUserStore } from 'stores/user';


// @ts-expect-error: Property 'adapter' does not exist on type 'Static<{}>'
PouchDB.adapter('worker', PouchWorker);

export interface InstanciatedDatabases {
  local?: PouchDB.Database;
  remote?: PouchDB.Database;
  sync?: PouchDB.Replication.Sync<Record<string, unknown>>;
  synced?: boolean;
}

export const useDbStore = defineStore('db', {
  state: () => ({
    counter: 0,
    instances: {} as { [key: string]: InstanciatedDatabases }
  }),

  getters: {
    doubleCount(state) {
      return state.counter * 2;
    }
  },

  actions: {
    increment() {
      this.counter++;
    },
    generateRemoteDbURL(remoteDbURL: string[] | string) {
      let remoteDbUrl: string;
      if (Array.isArray(remoteDbURL)) {
        remoteDbUrl = `${remoteDbURL[0]}${remoteDbURL[1]}`;
      } else {
        remoteDbUrl = remoteDbURL;
      }
      return remoteDbUrl;
    },

    async getLocalDB(localDbName: string) {
      const WithOutAdapter = () =>
        new PouchDB(localDbName, { auto_compaction: true });
      const [errSupportedBrowser, isSupported] = await to(
        PouchWorker.isSupportedBrowser()
      );
      if (errSupportedBrowser) {
        console.error(errSupportedBrowser);
        return WithOutAdapter();
      }
      if (isSupported) {
        try {
          return new PouchDB(localDbName, {
            auto_compaction: true,
            adapter: 'worker'
          });
        } catch (error) {
          console.error(
            'Could not create database using worker adapter',
            error
          );
          return WithOutAdapter();
        }
      }
      return WithOutAdapter();
    },

    async getRemoteDB(remoteDbUrl: string) {
      const { token, password } = await this.getUserAuthToken();
      // Split the URL into two parts at the `://` characters
      const [prefix, suffix] = remoteDbUrl.split('://');

      // Create a new string that combines the prefix, the `user:pass`, and the suffix
      const newUrl = `${prefix}://${token}:${password}@${suffix}`;
      const remote = new PouchDB(newUrl, {
        skip_setup: true,
        fetch: function (url, opts) {
          return PouchDB.fetch(url, { ...opts, credentials: 'include' });
        }
      });
      return remote;
    },

    getPacientDB(pacientId: string) {
      return this.getRemoteDB(
        `${process.env.VUE_APP_COUCHDB_PROTOCOL}${process.env.VUE_APP_COUCHDB_ADDRESS}piba_${pacientId}`
      );
    },

    async returnAppropiateDB(dbs: InstanciatedDatabases) {
      const { local, remote, synced } = dbs;
      if (local && synced) {
        console.log('Using local pouchdb');
        return local;
      }
      if (!(await hasConnection()) && local) {
        console.log('Using local pouchdb');
        return local;
      }
      if (remote) {
        console.log('Using Remote pouchdb');
        return remote;
      }
      throw new Error('No hay ninguna db disponible');
    },

    async getInstanciatedDB(key: string) {
      if (this.instances[key] == null) {
        return null;
      }
      return this.returnAppropiateDB(this.instances[key]);
    },

    setInstaciatedDb(key: string, dbs: InstanciatedDatabases) {
      this.instances[key] = dbs;
    },

    async syncDBs(
      localDbName: string,
      remoteDbURL: string[] | string,
      token: string,
      password: string,
      key: string
    ) {
      // debugger;
      const db = await this.getInstanciatedDB(key);
      if (db) {
        return db;
      }
      const remoteDbUrl = this.generateRemoteDbURL(remoteDbURL);
      const local = await this.getLocalDB(localDbName);
      const remote = await this.getRemoteDB(remoteDbUrl);
      try {
        local.replicate.from(remote).catch(console.error.bind(console));
        const sync = local
          .sync(remote, {
            live: true,
            retry: true
          })
          .on('change', (info) => {
            console.info('PouchDB: change on db');
            console.info(info);
            // handle change
          })
          .on('paused', (err) => {
            // replication paused (e.g. replication up to date, user went offline)
            if (err) {
              console.info('PouchDB: Paused sync');
              console.info(err);
            }
          })
          .on('active', () => {
            // replicate resumed (e.g. new changes replicating, user went back online)
          })
          .on('denied', (err) => {
            // a document failed to replicate (e.g. due to permissions)
            console.error('PouchDB: denied to replicate');
            console.error(err);
          })
          .on('complete', (info) => {
            // handle complete
            this.setInstaciatedDb(key, {
              local,
              remote,
              sync,
              synced: true
            });
            console.info('PouchDB: sync complete');
            console.info(info);
            // console.info('--------------------------------------------------');
          })
          .on('error', (err) => {
            // handle error
            console.error('PouchDB: error');
            console.error(err);
          });
        const dbs: InstanciatedDatabases = {
          local,
          remote,
          sync,
          synced: false
        };
        this.setInstaciatedDb(key, dbs);
        return this.returnAppropiateDB(dbs);
      } catch (e) {
        console.error(e);
      }
      const dbs: InstanciatedDatabases = {
        local,
        remote,
        sync: undefined,
        synced: false
      };
      this.setInstaciatedDb(key, dbs);
      return this.returnAppropiateDB(dbs);
    },

    async getUserAuthToken() {
      const userStore = useUserStore();
      if (!userStore.auth) {
        throw Error('User not logged in');
      }
      // await userStore.loginCouch();
      return {
        token: userStore.auth.token,
        password: userStore.auth.password,
        userId: userStore.auth.user_id,
        pibaRemoteURL: `${process.env.VUE_APP_COUCHDB_PROTOCOL}${process.env.VUE_APP_COUCHDB_ADDRESS}${userStore.personalDbName}`
      };
    },

    async getPersonalDB() {
      const { token, password, userId, pibaRemoteURL } =
        await this.getUserAuthToken();
      const key = 'personal';
      return this.syncDBs(userId, pibaRemoteURL, token, password, key);
    },

    async getPatientDBOrLocal(pacientId?: string) {
      if (pacientId) return await this.getPacientDB(pacientId);
      return await this.getPersonalDB();
    },

    async getPersonalDBToRef(dbRef: Ref<PouchDB.Database | undefined>) {
      dbRef.value = await this.getPersonalDB();
      return dbRef;
    },

    async getRemoteDbAuth(remoteDbURL: string[] | string) {
      await this.getUserAuthToken();
      const remoteUrl = this.generateRemoteDbURL(remoteDbURL);
      const remote = await this.getInstanciatedDB(remoteUrl);
      if (remote == null) {
        const dbs: InstanciatedDatabases = {
          remote: await this.getRemoteDB(remoteUrl)
        };
        this.setInstaciatedDb(remoteUrl, dbs);
        return dbs.remote;
      }
      return remote;
    },

    async getSyncDBsAuth(remoteDbURL: string[] | string, localDbName: string) {
      const { token, password } = await this.getUserAuthToken();
      const remoteDbUrl = this.generateRemoteDbURL(remoteDbURL);
      return this.syncDBs(
        localDbName || remoteDbUrl,
        remoteDbUrl,
        token,
        password,
        localDbName || remoteDbUrl
      );
    },

    async getPersonalDb() {
      return this.getPersonalDB();
    },

    closeDatabases() {
      Object.keys(this.instances).forEach((dbKey) => {
        this.instances[dbKey].local?.close();
        this.instances[dbKey].remote?.close();
      });
      this.instances = {};
    }
  },
  persist: false
});
