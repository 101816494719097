import { ConfianzaSituacionalData } from 'src/types/initial-evaluation/evaluacion-personal';
import { FIELDS } from 'src/meta/ConfianzaSituacionalMeta';
import { Icontext } from 'src/types/blitzar';
import { InitialEvaluationMetaTitleClasses } from 'src/meta/initial-evaluation/InitialEvaluationMeta';

type ctx = Icontext<Partial<ConfianzaSituacionalData>>;

export const ConfianzaSitSchema = [
  {
    component: 'p',
    slot: FIELDS.title.label,
    componentClasses: InitialEvaluationMetaTitleClasses
  },
  {
    component: 'div',
    class: 'text-weight-semibold subtitle text-gray97 textContainer',
    slot: [
      {
        component: 'p',
        slot: [
          {
            component: 'span',
            slot: 'Imagínate que estás en cada una de las siguientes situaciones, indícanos con un'
          },
          {
            component: 'span',
            class: 'text-darkD text-weight-bolder',
            slot: ' 0% '
          },
          {
            component: 'span',
            class: 'text-accent text-weight-extrabold text-uppercase',
            slot: 'NO ESTÁS SEGURO DE CONTROLARTE '
          },
          {
            component: 'span',
            slot: 'o con un '
          },
          {
            component: 'span',
            class: 'text-darkD text-weight-bolder',
            slot: ' 100% '
          },
          {
            component: 'span',
            class: 'text-darkD text-weight-bolder',
            slot: ' si '
          },
          {
            component: 'span',
            class: 'text-primary text-weight-extrabold text-uppercase',
            slot: 'DEFINITIVAMENTE estás SEGURO DE CONTROLAR TU CONSUMO'
          }
        ]
      }
    ]
  },
  {
    id: FIELDS['CS_1'].key,
    label: FIELDS['CS_1'].label,
    required: FIELDS['CS_1'].required,
    subLabel: FIELDS['CS_1'].subLabel,
    component: 'QSliderWrapper',
    min: 0,
    max: 100
  },
  {
    id: FIELDS['CS_2'].key,
    label: FIELDS['CS_2'].label,
    required: FIELDS['CS_2'].required,
    subLabel: FIELDS['CS_2'].subLabel,
    component: 'QSliderWrapper',
    min: 0,
    max: 100
  },
  {
    id: FIELDS['CS_3'].key,
    label: FIELDS['CS_3'].label,
    required: FIELDS['CS_3'].required,
    subLabel: FIELDS['CS_3'].subLabel,
    component: 'QSliderWrapper',
    min: 0,
    max: 100
  },
  {
    id: FIELDS['CS_4'].key,
    label: FIELDS['CS_4'].label,
    required: FIELDS['CS_4'].required,
    subLabel: FIELDS['CS_4'].subLabel,
    component: 'QSliderWrapper',
    min: 0,
    max: 100
  },
  {
    id: FIELDS['CS_5'].key,
    label: FIELDS['CS_5'].label,
    required: FIELDS['CS_5'].required,
    subLabel: FIELDS['CS_5'].subLabel,
    component: 'QSliderWrapper',
    min: 0,
    max: 100
  },
  {
    id: FIELDS['CS_6'].key,
    label: FIELDS['CS_6'].label,
    required: FIELDS['CS_6'].required,
    subLabel: FIELDS['CS_6'].subLabel,
    component: 'QSliderWrapper',
    min: 0,
    max: 100
  },
  {
    id: FIELDS['CS_7'].key,
    label: FIELDS['CS_7'].label,
    required: FIELDS['CS_7'].required,
    subLabel: FIELDS['CS_7'].subLabel,
    component: 'QSliderWrapper',
    min: 0,
    max: 100
  },
  {
    id: FIELDS['CS_8'].key,
    label: FIELDS['CS_8'].label,
    required: FIELDS['CS_8'].required,
    subLabel: FIELDS['CS_8'].subLabel,
    component: 'QSliderWrapper',
    min: 0,
    max: 100
  }
];
