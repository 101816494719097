import { InitialEvaluationRoutes } from 'src/meta/initial-evaluation/InitialEvaluationMeta';
export const FIELDS = {
  title: {
    label: InitialEvaluationRoutes.GENERAL_INFO.title
  },
  'con-quien-vives': {
    key: 'con-quien-vives',
    label: '¿Con quién vives?',
    options: {
      'papa-sustituto': {
        label: 'Papá/Sustituto',
        value: 'papa-sustituto'
      },
      'mama-sustituta': {
        label: 'Mamá/Sutituta',
        value: 'mama-sustituta'
      },
      hermanos: {
        label: 'Hermanos',
        value: 'hermanos'
      },
      'otros-familiares': {
        label: 'Otros familiares',
        value: 'otros-familiares'
      },
      amigos: {
        label: 'Amigos',
        value: 'amigos'
      },
      otros: {
        label: 'Otros',
        value: 'otros'
      }
    },
    required: true
  },
  'con-quien-vives-otro': {
    key: 'con-quien-vives-otro',
    required: true
  },
  'tienes-papa': {
    key: 'tienes-papa',
    label: '¿Tienes papá?',
    options: {
      'tengo-papa': {
        label: 'Si, tengo papá',
        value: 'tengo-papa'
      },
      'sustituye-familiar': {
        label: 'No, lo sustituye un familiar',
        value: 'sustituye-familiar'
      },
      otro: {
        label: 'Otro',
        value: 'otro'
      },
      no: {
        label: 'No',
        value: 'no'
      }
    },
    required: true
  },
  'tienes-papa-otro': {
    key: 'tienes-papa-otro',
    label: '¿Quién lo sustituye?',
    required: true
  },
  'edad-papa': {
    key: 'edad-papa',
    label: '¿Qué edad tiene tu papá?',
    required: true,
    subLabel: '(o de quién te cuida)'
  },
  'estudios-papa': {
    key: 'estudios-papa',
    label: '¿Cuál es el último grado de estudios de tu papá?',
    subLabel: '(o de quién te cuida)',
    options: {
      ninguno: {
        label: 'Ninguno(a)',
        value: 'ninguno'
      },
      primaria: {
        label: 'Primaria',
        value: 'primaria'
      },
      secundadaria: {
        label: 'Secundaria',
        value: 'secundadaria'
      },
      preparatoria: {
        label: 'Preparatoria',
        value: 'preparatoria'
      },
      licenciatura: {
        label: 'Licenciatura',
        value: 'licenciatura'
      },
      posgrado: {
        label: 'Posgrado',
        value: 'posgrado'
      }
    },
    required: true
  },
  'a-que-se-dedica-tu-papa': {
    key: 'a-que-se-dedica-tu-papa',
    label: '¿A qué se dedica tu papá?',
    subLabel: '(o de quién te cuida)',
    required: true
  },
  'tienes-mama': {
    key: 'tienes-mama',
    label: '¿Tienes mamá?',
    options: {
      'tengo-mama': {
        label: 'Si, tengo mamá',
        value: 'tengo-mama'
      },
      'sustituye-familiar': {
        label: 'No, la sustituye un familiar',
        value: 'sustituye-familiar'
      },
      otro: {
        label: 'Otro',
        value: 'otro'
      },
      no: {
        label: 'No',
        value: 'no'
      }
    },
    required: true
  },
  'tienes-mama-otro': {
    key: 'tienes-mama-otro',
    label: '¿Quién la sustituye?',
    required: true
  },
  'edad-mama': {
    key: 'edad-mama',
    label: '¿Qué edad tiene tu mamá?',
    subLabel: '(o de quién te cuida)',
    required: true
  },
  'estudios-mama': {
    key: 'estudios-mama',
    label: '¿Cuál es el último grado de estudios de tu mamá?',
    subLabel: '(o de quién te cuida)',
    options: {
      ninguno: {
        label: 'Ninguno(a)',
        value: 'ninguno'
      },
      primaria: {
        label: 'Primaria',
        value: 'primaria'
      },
      secundadaria: {
        label: 'Secundaria',
        value: 'secundadaria'
      },
      preparatoria: {
        label: 'Preparatoria',
        value: 'preparatoria'
      },
      licenciatura: {
        label: 'Licenciatura',
        value: 'licenciatura'
      },
      posgrado: {
        label: 'Posgrado',
        value: 'posgrado'
      }
    },
    required: true
  },
  'a-que-se-dedica-tu-mama': {
    key: 'a-que-se-dedica-tu-mama',
    label: '¿A qué se dedica tu mamá?',
    subLabel: '(o de quién te cuida)',
    required: true
  },
  'cantidad-de-hermanos': {
    key: 'cantidad-de-hermanos',
    label: '¿Cuántos hermanos tienes?',
    required: true
  },
  'cantidad-de-hermanas': {
    key: 'cantidad-de-hermanas',
    label: '¿Cuántas hermanas tienes?',
    required: true
  },
  'que-lugar-ocupas-entre-tus-hermanos': {
    key: 'que-lugar-ocupas-entre-tus-hermanos',
    label: '¿Qué lugar ocupas entre tus hermanos?',
    required: true
  },
  'cuanto-crees-que-ganan-los-adultos-de-tu-familia': {
    key: 'cuanto-crees-que-ganan-los-adultos-de-tu-familia',
    label: '¿Cuánto crees que ganan los adultos de tu familia?',
    options: {
      'mucho-dinero': {
        label: 'Ganan mucho dinero',
        value: 'mucho-dinero'
      },
      'suficiente-dinero': {
        label: 'Ganan lo suficiente para vivir',
        value: 'suficiente-dinero'
      },
      'insuficente-dinero': {
        label: 'No ganan suficiente',
        value: 'insuficente-dinero'
      }
    },
    required: true
  }
};
