import { FlowState } from 'src/composables/FlowState';
import { FollowUpThreeMonthURLs } from './../meta/follow-up/three-months';

export class FlowStateFollowUpThreeMonths extends FlowState {
  constructor(db: PouchDB.Database) {
    super('flowStateFollowUpThreeMonths', FollowUpThreeMonthURLs, db);
  }
}

export async function useFlowStateFollowUpThreeMonths(db: PouchDB.Database) {
  const flowStatePaciente = new FlowStateFollowUpThreeMonths(db);
  await flowStatePaciente.load_current_state();
  return flowStatePaciente;
}
